<template>
  <div class="card">
    <div class="card-header border-transparent">
        <h3 class="card-title">Suporte técnico</h3>

        <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                <i class="fas fa-minus"></i>
            </button>
            <button type="button" class="btn btn-tool" data-card-widget="remove">
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="card-body p-0">
        <div class="table-responsive">
            <div class="box-body text-center">
                <h3><i class="fa fa-phone"></i> (48) 3438 4888</h3>
                <h3><span class="text-success"><i class="fab fa-whatsapp"></i></span> (48) 9 9134 5850</h3>
                <h3><i class="fa fa-envelope"></i> <a href="mailto:contato@mybelt.com.br">contato@mybelt.com.br</a></h3>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "Atendimento",
}
</script>

<style>

</style>
