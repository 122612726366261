<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12">

                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Alunos confirmados na aula.</h3>
                            <div class="card-tools">
                                <router-link to="/agendas" class="btn btn-sm btn-warning">
                                    <i class="fas fa-backward"></i>
                                    Voltar
                                </router-link>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col m-4">
                                <div class="form-group">
                                    <label for="data_aula">Data:</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                          <span class="input-group-text">
                                            <i class="far fa-calendar-alt"></i>
                                          </span>
                                        </div>
                                        <input type="date" class="form-control float-right" id="data_aula" v-model="data_aula">
                                        <span class="input-group-btn">
                                            <button type="button" class="btn btn-info btn-flat" @click="buscar">Buscar!</button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Faixa</th>
                                    <th>Idade</th>
                                    <th>Status</th>
                                    <th>Ação</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="aluno in alunos.data" :key="aluno.id">
                                    <td>{{ aluno.nome }}</td>
                                    <td>
                                        <div v-html="aluno.faixa"></div>
                                    </td>
                                    <td>{{ aluno.idade }}</td>
                                    <td>
                                        <span :value="aluno.status" @click="mudaStatus(aluno.id)"
                                              class="badge cursor-pointer"
                                              v-bind:class="aluno.status ? 'badge-success' : 'badge-danger'">
                                            {{ aluno.status ? 'Confirmado' : 'Não Confirmado' }}
                                        </span>
                                    </td>
                                    <td>
                                        <a href="#"
                                           @click="deletePresenca(aluno.id)"
                                           class="btn btn-sm btn-danger"
                                            title="excluir">
                                            <i class="fa fa-trash"></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <no-records v-if="alunos.data.length <= 0"></no-records>
                            <pagination :data="alunos" @pagination-change-page="getResults"></pagination>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </section>
</template>

<script>

export default {
    components: {
    },
    data() {
        return {
            alunos: {
                data: []
            },
            data_aula: null,
            autocompleteItems: [],
        }
    },
    methods: {
        getResults(page = 1) {
            this.$Progress.start();
            axios.get(`/api/agendaAlunos/${this.$route.params.id}?page=${page}&d=${this.data_aula}`).then(({data}) => (this.alunos = data.data));
            this.$Progress.finish();
        },
        loadAlunos() {
            axios.get(`/api/agendaAlunos/${this.$route.params.id}?d=${this.data_aula}`).then(({data}) => (this.alunos = data.data));
        },
        handleFileUpload() {
            this.form.image = this.$refs.file.files[0];
        },
        deletePresenca(id) {
            Swal.fire({
                title: 'Tem certeza?',
                text: "Você não poderá restaurar depois!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Sim, excluir!'
            }).then((result) => {
                if (result.value) {
                    axios.delete(`/api/agendaDeleteAluno/${id}`).then(() => {
                        Toast.fire(
                            'Excluído!',
                            'A presença foi excluída.',
                            'success'
                        );
                        this.loadAlunos();
                    }).catch((data) => {
                        Swal.fire("Erro!", data.message, "warning");
                    });
                }
            })
        },
        mudaStatus(id) {
            this.$Progress.start();

            axios.put(`/api/agendaPresencaStatus/${id}`)
                .then((data) => {
                    if (data.data.success) {
                        Toast.fire({
                            icon: 'success',
                            title: data.data.message
                        });
                        this.$Progress.finish();
                        this.loadAlunos();
                    } else {
                        Toast.fire({
                            icon: 'error',
                            title: 'Ocorreu um erro! Por favor, tente novamente.'
                        });
                        this.$Progress.failed();
                    }
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
        buscar(){
            this.$Progress.start();
            this.getResults(1);
            this.$Progress.finish();
        }
    },
    mounted() {
    },
    created() {
        this.$Progress.start();
        this.loadAlunos();
        this.$Progress.finish();
    },
}
</script>
