<template>
    <div class="preloader flex-column justify-content-center align-items-center" style="height: 0px;">
        <img class="animation__shake" src="images/logo.png" alt="Logo MyBelt" height="60" width="60" style="display: none;">
    </div>
</template>

<script>
export default {
    name: "PreloaderFullScreen"
}
</script>

<style scoped>

</style>
