export default class User {
    constructor(id= '',
                type= '',
                name= '',
                email= '',
                password= '',
                email_verified_at= '',
                cpf_cnpj= '',
                roles= '',
                language= '',
                status= '',
                telefone= '',
                cep= '',
                endereco= '',
                numero= '',
                bairro= '',
                cidade= '',
                uf= '',
                complemento= ''
    ) {
        this.id = id;
        this.type = type;
        this.name = name;
        this.email = email;
        this.password = password;
        this.email_verified_at = email_verified_at;
        this.cpf_cnpj = cpf_cnpj;
        this.roles = roles;
        this.language = language;
        this.status = status;
        this.telefone = telefone;
        this.cep = cep;
        this.endereco = endereco;
        this.numero = numero;
        this.bairro = bairro;
        this.cidade = cidade;
        this.uf = uf;
        this.complemento = complemento;
    }
}
