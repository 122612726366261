export default class Presenca {
    constructor(id_aluno= '',
                id_arte= '',
                table= '',
                faixa = '',
                grau = '',
                linha_faixa = '',
                cor_grau = '',
                graduation = '',
                type_graduation = '',
                date_graduate = '',
                comments = '',
    ) {
        this.id_aluno = id_aluno;
        this.id_arte = id_arte;
        this.table = table;
        this.faixa = faixa;
        this.grau = grau;
        this.linha_faixa = linha_faixa;
        this.cor_grau = cor_grau;
        this.graduation = graduation;
        this.type_graduation = type_graduation;
        this.date_graduate = date_graduate;
        this.comments = comments;
    }
}
