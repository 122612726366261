<template>
    <div class="row">
        <div class="col-md-4 col-sm-6 col-12">
            <div class="card card-outline card-warning">
                <div class="card-header">
                    <h3 class="card-title">
                        Adultos
                    </h3>
                    <div class="card-tools">
                        <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
                <div class="card-body"><div class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand"><div class=""></div></div><div class="chartjs-size-monitor-shrink"><div class=""></div></div></div>
                    <PieGrafico :chart-data="adultosColletion" :height="250"></PieGrafico>
                </div>
            </div>
        </div>

        <div class="col-md-4 col-sm-6 col-12">
            <div class="card card-outline card-warning">
                <div class="card-header">
                    <h3 class="card-title">
                        Crianças e Adolescente
                    </h3>
                    <div class="card-tools">
                        <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
                <div class="card-body"><div class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand"><div class=""></div></div><div class="chartjs-size-monitor-shrink"><div class=""></div></div></div>
                    <PieGrafico :chart-data="menoresColletion" :height="250"></PieGrafico>
                </div>
            </div>
        </div>

        <div class="col-md-4 col-sm-6 col-12">
            <div class="card card-outline card-warning">
                <div class="card-header">
                    <h3 class="card-title">Resultados da Enquete</h3>

                    <div class="card-tools">
                        <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
                    </div>
                </div>
                <div class="card-body"><div class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand"><div class=""></div></div><div class="chartjs-size-monitor-shrink"><div class=""></div></div></div>
                    <!-- {!! $graficoEnquete->container() !!} -->
                    <PieGrafico :chart-data="resultsEnquetes" :height="250"></PieGrafico>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import PieGrafico from "./PieGraficos";
export default {
    components: {
        PieGrafico
    },
    name: "Faixas",
    data(){
        return{
            menoresColletion: {},
            adultosColletion: {},
            resultsEnquetes: {},
        }
    },
    methods:{
        loadGrafico(){
            var route = '/api/academiaGraficosFaixas';
            if(this.$route.params.id){
                route = `/api/academiaGraficosFaixas/${this.$route.params.id}`;
            }
            this.$Progress.start();
                axios.get(route).then(({ data }) => {
                    this.fillDataFaixas(data.data);
                });
            this.$Progress.finish();
        },
        loadEnquete(){
            var route = '/api/academiagraficoEnquete';
            if(this.$route.params.id){
                route = `/api/academiagraficoEnquete/${this.$route.params.id}`;
            }
            this.$Progress.start();
                axios.get(route).then(({ data }) => {
                    this.fillDataEnquete(data.data);
                });
            this.$Progress.finish();
        },
        fillDataFaixas (dados) {
            this.menoresColletion = {
                labels: dados.infanto.labels,
                datasets: [{
                    backgroundColor: dados.infanto.backgrounds,
                    data: dados.infanto.data,
                }]
            };

            this.adultosColletion = {
                labels: dados.adulto.labels,
                datasets: [{
                    backgroundColor: dados.adulto.backgrounds,
                    data: dados.adulto.data,
                }]
            }
        },
        fillDataEnquete(data){
            var backgroundColor = this.geraCores(data.labels);

            this.resultsEnquetes = {
                labels: data.labels,
                datasets: [{
                    backgroundColor: backgroundColor,
                    data: data.data,
                }]
            };
        },
        geraCores(array){
            var cores = [];

                for (let i = 0; i < array.length; i++) {
                    const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);
                    console.log(randomNum);
                    cores.push(`rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`);
                }
            console.log(cores);
            return cores;
        }
    },
    mounted(){
        console.log('Faixas componente mounted');
    },
    created(){
        this.loadGrafico();
        this.loadEnquete();
        console.log('Faixas componente created');
    },

}
</script>

<style>

</style>
