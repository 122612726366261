<template>
  <section class="content">
    <div class="container-fluid">

        <div class="row">
            <div class="col-12">
                <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Lista de Alunos Deletados</h3>
                    <div class="card-tools">
                        <router-link to="/alunos" class="btn btn-sm btn-warning">
                            <i class="fas fa-backward"></i>
                            Voltar
                        </router-link>
                    </div>
                </div>

                <div class="card-body table-responsive p-0">
                    <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Idade</th>
                            <th>Graduação</th>
                            <th>Quant Aulas</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="aluno in alunos.data" :key="aluno.id">
                            <td>{{aluno.full_name}}</td>
                            <td>{{aluno.idade}}</td>
                            <td>
                                <span v-for="arte in aluno.artes_marciais" :key="arte.id">
                                    {{ arte.presencas_graduacao }} <br>
                                </span>
                            </td>
                            <td>
                                <span v-for="arte in aluno.artes_marciais" :key="arte.id">
                                    {{ arte.data_ultima_presenca | moment("D/MM/YYYY") }} <br>
                                </span>
                            </td>
                            <td>
                                <button type="button" @click="restaurarAluno(aluno.id)" class="btn btn-sm btn-primary" title="editar">
                                    Restaurar
                                </button>
                                <button type="button" @click="deleteAluno(aluno.id)" class="btn btn-sm btn-danger" title="excluir">
                                    Excluir
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                <div class="card-footer">
                    <pagination :data="alunos" @pagination-change-page="getResults"></pagination>
                </div>
                </div>
            </div>
        </div>

    </div>
  </section>
</template>

<script>

    export default {
        data () {
            return {
                alunos: {
                    data: []
                },
            }
        },
        methods: {
            getResults(page = 1) {
                this.$Progress.start();
                axios.get(`/api/aluno?filter=excluidos&page=${page}`).then(({ data }) => {this.alunos = data.data});
                this.$Progress.finish();
            },
            restaurarAluno(id){
                Swal.fire({
                    title: 'Tem certeza?',
                    text: "O aluno será restaurado!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Sim, restaurar!'
                    }).then((result) => {
                        axios.put(`/api/aluno/${id}`, {
                            deleted_at: 0
                        }).then(()=> {
                            Toast.fire({
                                icon: 'success',
                                title: 'O aluno foi restaurado!'
                            });
                            this.getResults();
                        }).catch((data)=> {
                          Swal.fire("Failed!", data.message, "warning");
                      });
                    })
            },
            deleteAluno(id){
                Swal.fire({
                    title: 'Tem certeza?',
                    text: "Você não poderá restaurar depois!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Sim, excluir!'
                    }).then((result) => {
                        axios.delete(`/api/alunoDelete/${id}`).then(()=>{
                            Toast.fire({
                                icon: 'success',
                                title: 'O aluno foi excluído!'
                            });
                            this.getResults();
                        }).catch((data)=> {
                          Swal.fire("Falha!", data.message, "warning");
                      });
                    })
            },
        },
        mounted() {
            console.log('Alunos Excluídos Component mounted.')
        },
        created() {
            this.$Progress.start();
            this.getResults();
            this.$Progress.finish();
        }
    }
</script>
