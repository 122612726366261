<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Contas Bancárias</h3>
                            <div class="card-tools">
                                <button type="button" class="btn btn-sm btn-primary" @click="newModal" title="cadastrar nova">
                                    <i class="fa fa-plus-square"></i>
                                    NOVA CONTA
                                </button>
                            </div>
                        </div>
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>Descrição</th>
                                    <th>Agência</th>
                                    <th>Conta</th>
                                    <th>Saldo</th>
                                    <th>Ação</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="conta in contas.data" :key="conta.id">
                                    <td>{{ conta.descricao }}</td>
                                    <td>{{ conta.agencia }} {{ conta.agencia_digito }}</td>
                                    <td>{{ conta.conta }} {{ conta.conta_digito }}</td>
                                    <td>{{ conta.saldo_atual | currency  }}</td>
                                    <td>
                                        <a href="#" @click="editModal(conta)" class="btn btn-sm btn-primary" title="editar">
                                            <i class="fa fa-edit"></i>
                                        </a>
                                        <a href="#" @click="deleteBanco(conta.id)" class="btn btn-sm btn-danger" title="excluir">
                                            <i class="fa fa-trash"></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <no-records v-if="contas.data.length <= 0"></no-records>
                            <pagination :data="contas" @pagination-change-page="getResults"></pagination>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" v-show="!editmode">Criar Nova Conta Bancária</h5>
                            <h5 class="modal-title" v-show="editmode">Atualizar Informações da Conta Bancária</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <form @submit.prevent="editmode ? updateBanco() : createBanco()">

                            <div class="modal-body">

                                <div class="form-group">
                                    <label for="banco_id">Selecione o Banco</label>
                                    <select name="banco_id"
                                            v-model="form.banco_id"
                                            id="banco_id"
                                            class="form-control"
                                            :class="{ 'is-invalid': form.errors.has('banco_id') }"
                                    >
                                        <option value="">Seleciona uma opção</option>
                                        <option
                                            :value="banco_.id"
                                            v-for="banco_ in bancos"
                                            :selected="banco_.id == form.banco_id"
                                        >
                                            {{ banco_.descricao }}
                                        </option>
                                    </select>
                                    <has-error :form="form" field="banco_id"></has-error>
                                </div>

                                <div class="form-group">
                                    <label for="descricao">Nome</label>
                                    <input v-model="form.descricao"
                                           type="text"
                                           name="descricao"
                                           id="descricao"
                                           class="form-control"
                                           :class="{ 'is-invalid': form.errors.has('descricao') }"
                                    >
                                    <has-error :form="form" field="descricao"></has-error>
                                </div>

                                <div class="form-group">
                                    <label for="saldo_inicial">Saldo Inicial</label>
                                    <input v-model="form.saldo_inicial"
                                           type="tel"
                                           name="saldo_inicial"
                                           id="saldo_inicial"
                                           class="form-control"
                                           :class="{ 'is-invalid': form.errors.has('saldo_inicial') }"
                                    >
                                    <has-error :form="form" field="saldo_inicial"></has-error>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="agencia">Agência</label>
                                            <input v-model="form.agencia"
                                                   type="text"
                                                   name="agencia"
                                                   id="agencia"
                                                   class="form-control"
                                                   :class="{ 'is-invalid': form.errors.has('agencia') }"
                                            >
                                            <has-error :form="form" field="agencia"></has-error>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="agencia_digito">Agência Dígito</label>
                                            <input v-model="form.agencia_digito"
                                                   type="tel"
                                                   name="agencia_digito"
                                                   id="agencia_digito"
                                                   class="form-control"
                                                   :class="{ 'is-invalid': form.errors.has('agencia_digito') }"
                                            >
                                            <has-error :form="form" field="agencia_digito"></has-error>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="conta">Conta</label>
                                            <input v-model="form.conta"
                                                   type="tel"
                                                   name="conta"
                                                   id="conta"
                                                   class="form-control"
                                                   :class="{ 'is-invalid': form.errors.has('conta') }"
                                            >
                                            <has-error :form="form" field="conta"></has-error>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="conta_digito">Conta Dígito</label>
                                            <input v-model="form.conta_digito"
                                                   type="text"
                                                   name="conta_digito"
                                                   id="conta_digito"
                                                   class="form-control"
                                                   :class="{ 'is-invalid': form.errors.has('conta_digito') }"
                                            >
                                            <has-error :form="form" field="conta_digito"></has-error>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                <button type="submit" class="btn btn-success">Salvar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import Banco from "../../../domain/financeiro/Banco";

export default {
    components: {
    },
    data() {
        return {
            editmode: false,
            contas: {
                data: []
            },
            bancos: {},
            form: new Form(
                new Banco()
            ),
        }
    },
    methods: {
        loadBancosDisponiveis() {
            this.$Progress.start();
            axios.get(`/api/financeiro/bancos`).then(({data}) => (this.bancos = data.data));
            this.$Progress.finish();
        },
        loadBancos() {
            this.$Progress.start();
            axios.get(`/api/financeiro/banco`).then(({data}) => (this.contas = data.data));
            this.$Progress.finish();
        },
        getResults(page = 1) {
            this.$Progress.start();
            axios.get(`/api/financeiro/banco?page=${page}`).then(({data}) => (this.contas = data.data));
            this.$Progress.finish();
        },
        createBanco() {
            this.form.post('/api/financeiro/banco')
                .then((response) => {
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();
                    this.loadBancos();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
        updateBanco() {
            this.$Progress.start();
            this.form.put(`/api/financeiro/banco/${this.form.id}`)
                .then((response) => {
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();
                    this.loadBancos();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
        },
        editModal(conta) {
            this.editmode = true;
            this.form.reset();
            $('#addNew').modal('show');
            this.form.fill(conta);
        },
        newModal() {
            this.editmode = false;
            this.form.reset();
            $('#addNew').modal('show');
        },
        deleteBanco(id) {
            Swal.fire({
                title: 'Tem certeza?',
                text: "Você não poderá restaurar depois!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Sim, excluir!'
            }).then((result) => {
                if (result.value) {
                    this.form.delete(`/api/financeiro/banco/${id}`).then(() => {
                        Toast.fire(
                            'Excluído!',
                            'O banco foi excluído.',
                            'success'
                        );
                        this.loadBancos();
                    }).catch((data) => {
                        Swal.fire("Erro!", data.message, "error");
                    });
                }
            })
        },

    },
    mounted() {

    },
    created() {
        this.$Progress.start();
        this.loadBancos();
        this.loadBancosDisponiveis();
        this.$Progress.finish();
    }
}
</script>
