export default class Conta {
    constructor(id = '',
        usuario_id = '',
        usuario_nome = '',
        table = '',
        categoria_id = '',
        banco_id = '',
        venda_id = '',
        forma_pagamento = '',
        valor = '',
        desconto = '',
        acrescimo = '',
        valor_pago = '',
        detalhes = '',
        data_competencia = '',
        data_vencimento = '',
        data_pagamento = '',
        status = '',
        repetir = 1,
    ) {
        this.id = id;
        this.usuario_id = usuario_id;
        this.usuario_nome = usuario_nome;
        this.table = table;
        this.categoria_id = categoria_id;
        this.banco_id = banco_id;
        this.venda_id = venda_id;
        this.forma_pagamento = forma_pagamento;
        this.valor = valor;
        this.desconto = desconto;
        this.acrescimo = acrescimo;
        this.valor_pago = valor_pago;
        this.detalhes = detalhes;
        this.data_competencia = data_competencia;
        this.data_vencimento = data_vencimento;
        this.data_pagamento = data_pagamento;
        this.status = status;
        this.repetir = repetir;
    }
}
