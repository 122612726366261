<template>
    <div>
      <div class="card-info mt-3">
            <div class="card-header ui-sortable-handle" >
                <h3 class="card-title">Stripe</h3>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <form @submit.prevent="save()">
                        <div class="form-group">
                            <label>Ativar pagamento com Stripe?</label>
                            <select v-model="form.stripe_status" name="stripe_status" class="form-control">
                                <option value="0">Não</option>
                                <option value="1">Sim</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Operação de Teste ou Produção?</label>
                            <select v-model="form.stripe_operacao" name="stripe_operacao" class="form-control">
                                <option value="production">Producao</option>
                                <option value="test">Teste</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>API KEY Secreta de Teste</label>
                            <input  v-model="form.stripe_secret_api_key_test" type="text" name="stripe_secret_api_key_test" class="form-control">
                        </div>
                        <div class="form-group">
                            <label>API KEY Pública de Teste</label>
                            <input  v-model="form.stripe_public_api_key_test" type="text" name="stripe_public_api_key_test" class="form-control">
                        </div>
                        <div class="form-group">
                            <label>API KEY Secreta de Produção</label>
                            <input  v-model="form.stripe_secret_api_key_live" type="text" name="stripe_secret_api_key_live" class="form-control">
                        </div>
                        <div class="form-group">
                            <label>API KEY Pública de Produção</label>
                            <input  v-model="form.stripe_public_api_key_live" type="text" name="stripe_public_api_key_live" class="form-control">
                        </div>
                        <button type="submit" class="btn btn-success float-right">
                            Salvar
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
     data(){
        return{
            form: new Form({
                stripe_status: '',
                stripe_operacao: '',
                stripe_secret_api_key_test: '',
                stripe_public_api_key_test: '',
                stripe_secret_api_key_live: '',
                stripe_public_api_key_live: '',
            }),
        }
    },
    methods:{
        loadGateway(){
            this.$Progress.start();
                axios.get("/api/configuracao/stripe").then(({data}) => {
                    this.form.fill(data.data);
                });
            this.$Progress.finish();
        },
        save(){
            this.$Progress.start();
            this.form.post('/api/configuracao/save')
                .then((data) => {
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    this.$Progress.finish();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
    },
    created(){
        this.loadGateway();
        console.log('Componente paghiper created')
    },
    mounted(){
        console.log('Componente paghiper mounte')
    },

}
</script>

<style>

</style>
