<template>
    <div>
       <div class="card-body table-responsive p-0">
            <table class="table table-hover">
                <thead>
                <tr>
                    <th>Nome</th>
                    <th>Hora Inicio/Final</th>
                    <th>Dias</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="agenda in agendas.data" :key="agenda.id_agenda">
                    <td>{{ agenda.evento }}</td>
                    <td>
                        {{ agenda.hora_inicio }} - {{ agenda.hora_final }}
                    </td>
                    <td>
                        <label
                            v-show="agenda.dia_semana.split('*').sort().indexOf(dia[0]) != -1"
                            v-for="dia in dias_semana" :key="dia[0]">
                                {{ dia[1].substring(0,3) }}-
                        </label>
                    </td>
                    <td>
                        <span @click="mudaStatus(agenda.id_agenda)"
                            :value="agenda.status"
                            class="badge cursor-pointer"
                            :class="agenda.status ? 'badge-success' : 'badge-danger'">
                            {{ agenda.status ? 'Ativo' : 'Inativo' }}
                        </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="card-footer">
            <no-records v-if="agendas.data.length <= 0"></no-records>
            <pagination :data="agendas" @pagination-change-page="getResults"></pagination>
        </div>
    </div>
</template>

<script>
export default {
    name: "Agenda",
    data(){
        return{
            agendas: {
                data: []
            },
            dias_semana: [
                ['0', 'Segunda'],
                ['1', 'Terça'],
                ['2', 'Quarta'],
                ['3', 'Quinta'],
                ['4', 'Sexta'],
                ['5', 'Sabado'],
                ['6', 'Domingo'],
            ],
        }
    },
    methods:{
        getResults(page = 1) {
            this.$Progress.start();
            axios.get(`/api/agenda/${this.$route.params.id}/academia?page=${page}`).then(({data}) => (this.agendas = data.data));
            this.$Progress.finish();
        },
        loadAgendas() {
            axios.get(`/api/agenda/${this.$route.params.id}/academia`).then(({data}) => {
                this.agendas = data.data;
                console.log('agendas',this.agendas)
            });
        },
        diaSemanas(dias) {
            return dias.split("*").sort();
        },
        mudaStatus(id){
            this.$Progress.start();

            axios.put(`/api/agendaStatus/${id}/${this.$route.params.id}`)
                .then((data) => {
                    if (!data.data.success) {
                        Toast.fire({
                            icon: 'error',
                            title: 'Ocorreu um erro! Por favor, tente novamente.'
                        });
                        this.$Progress.failed();
                        return;
                    }
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    this.$Progress.finish();
                    this.loadAgendas();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        }
    },
    mounted() {
    },
    created() {
        this.$Progress.start();

        this.loadAgendas();

        this.$Progress.finish();
    },
}
</script>

<style scoped>

</style>
