export default class Parcela {
    constructor(
        vencimento = '',
        total = '',
        obs = '',
    ) {
        this.vencimento = vencimento;
        this.valor = total;
        this.observacao = obs;
    }
}
