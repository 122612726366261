<template>
    <section class="content">
        <div class="container-fluid">

            <CardsListaAlunos
                v-bind:alunos="alunos"
                v-bind:planos="plano"
            />

            <div class="row">
                <div class="col-12">

                    <b-alert
                        variant="warning"
                        show
                        dismissible
                        fade
                        v-if="!statusRegistros">
                        Você atingiou o limite de alunos!
                    </b-alert>

                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Lista de Alunos</h3>
                            <div class="card-tools">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-primary"
                                    @click="newModal"
                                    title="cadastrar"
                                    v-if="statusRegistros"
                                >
                                    <i class="fa fa-plus-square"></i>
                                    NOVO ALUNO
                                </button>
                            </div>
                        </div>

                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Idade</th>
                                        <th>Graduação</th>
                                        <th>Quant Aulas</th>
                                        <th>Ultima aula</th>
                                        <th>Status</th>
                                        <th>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="aluno in alunos.data" :key="aluno.id">
                                        <td>
                                            <router-link :to="`/aluno/${aluno.id}/perfil`" >
                                                {{aluno.full_name}}
                                            </router-link>
                                        </td>
                                        <td> {{aluno.idade}} </td>
                                        <td>
                                            <span v-for="arte in aluno.artes_marciais" :key="arte.id">
                                                {{ arte.graduacao }}
                                            </span>
                                        </td>
                                        <td>
                                            <span v-for="arte in aluno.artes_marciais" :key="arte.id">
                                                {{ arte.presencas_graduacao }} <br>
                                            </span>
                                        </td>
                                        <td>
                                            <span v-for="arte in aluno.artes_marciais" :key="arte.id">
                                                {{ arte.data_ultima_presenca | moment("D/MM/YYYY") }} <br>
                                            </span>
                                        </td>
                                        <td>
                                            <span
                                                :value="aluno.status"
                                                @click="mudaStatus(aluno.id)"
                                                class="badge "
                                                v-bind:class="aluno.status == 'Y' ? 'badge-success' : 'badge-danger'"
                                            >
                                                {{ aluno.status == 'Y' ? 'Active' : 'Inactive' }}
                                            </span>
                                        </td>

                                        <td>
                                            <a href="#" @click="editModal(aluno)" class="btn btn-sm btn-primary" title="editar">
                                                <i class="fa fa-edit"></i>
                                            </a>
                                            <a href="#" @click="deleteAluno(aluno.id)" class="btn btn-sm btn-danger" title="excluir">
                                                <i class="fa fa-trash"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="card-footer">
                            <pagination :data="alunos" @pagination-change-page="getResults"></pagination>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" v-show="!editmode">Cadastrar Aluno</h5>
                            <h5 class="modal-title" v-show="editmode">Atualizar Aluno</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <form @submit.prevent="editmode ? updateAluno() : createAluno()">
                        <div class="modal-body">
                            <div class="form-group">
                                <label>Academias</label>
                                <select
                                    name="id_academia"
                                    v-model="form.id_academia"
                                    id="id_academia"
                                    class="form-control"
                                    :class="{ 'is-invalid': form.errors.has('id_academia') }"
                                >
                                    <option
                                        v-for="academia in academias.data"
                                        :key="academia.id_academia"
                                        :value="academia.id_academia"
                                    >
                                        {{ academia.nome }}
                                    </option>
                                </select>
                                <has-error :form="form" field="id_academia"></has-error>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                    <label>Nome</label>
                                    <input
                                        v-model="form.nome"
                                        type="text"
                                        name="nome"
                                        class="form-control"
                                        :class="{ 'is-invalid': form.errors.has('nome') }"
                                    >
                                    <has-error :form="form" field="nome"></has-error>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                    <label> Sobrenome </label>
                                    <input
                                        v-model="form.sobrenome"
                                        type="text"
                                        name="sobrenome"
                                        class="form-control"
                                        :class="{ 'is-invalid': form.errors.has('sobrenome') }"
                                    >
                                    <has-error :form="form" field="sobrenome"></has-error>
                                    </div>
                                </div>
                            </div>

                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label>CPF</label>
                                    <input
                                        v-model="form.cpf"
                                        type="text"
                                        name="cpf"
                                        v-mask="'###.###.###-##'"
                                        class="form-control"
                                        :class="{ 'is-invalid': form.errors.has('cpf') }"
                                    >
                                    <has-error :form="form" field="cpf"></has-error>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>RG</label>
                                    <input
                                        v-model="form.rg"
                                        type="text" name="rg"
                                        class="form-control"
                                        :class="{ 'is-invalid': form.errors.has('rg') }"
                                    >
                                    <has-error :form="form" field="rg"></has-error>
                                </div>
                            </div>
                        </div>

                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="basic-tab" data-toggle="tab" href="#basic" role="tab" aria-controls="basic" aria-selected="true">
                                Basico
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="address-tab" data-toggle="tab" href="#address" role="tab" aria-controls="address" aria-selected="false">
                                Endereço
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content">

                            <div class="tab-pane fade  show active" id="basic" role="tabpanel" aria-labelledby="basic-tab">

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label> Email </label>
                                            <input
                                                v-model="form.email"
                                                type="email"
                                                name="email"
                                                class="form-control"
                                                :class="{ 'is-invalid': form.errors.has('email') }"
                                            >
                                            <has-error :form="form" field="email"></has-error>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                        <label>Senha</label>
                                            <input
                                                v-model="form.senha"
                                                type="password"
                                                name="senha"
                                                class="form-control"
                                                :class="{ 'is-invalid': form.errors.has('senha') }"
                                            >
                                            <has-error :form="form" field="senha"></has-error>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Data de Nascimento</label>
                                            <b-form-datepicker
                                                id="data_nascimento"
                                                v-model="form.data_nascimento"
                                                :class="{ 'is-invalid': form.errors.has('data_nascimento') }"
                                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                            />
                                            <!-- <input v-model="form.data_nascimento" type="date" name="data_nascimento"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('data_nascimento') }"> -->

                                            <has-error :form="form" field="data_nascimento"></has-error>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Sexo</label>
                                            <select
                                                name="sexo"
                                                v-model="form.sexo"
                                                id="sexo"
                                                class="form-control"
                                                :class="{ 'is-invalid': form.errors.has('sexo') }"
                                            >
                                                <option value="F">Feminino</option>
                                                <option value="M">Masculino</option>
                                            </select>
                                            <has-error :form="form" field="sexo"></has-error>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Telefone</label>
                                            <phone-mask-input
                                                v-model="form.telefone"
                                                autoDetectCountry
                                                wrapperClass="wrraper-example"
                                                inputClass="form-control"
                                                :class="{ 'is-invalid': form.errors.has('telefone') }"
                                                flagClass="flag-example"
                                            />
                                            <has-error :form="form" field="telefone"></has-error>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <SelectPessoa
                                                :class="{ 'is-invalid': form.errors.has('usuario_id') }"
                                                :selectValue="{name: form.responsavel}"
                                                title="Recebedores"
                                                v-on:selected="form.responsavel = $event.name"
                                            />
                                            <has-error :form="form" field="responsavel"></has-error>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Status</label>
                                            <select
                                                name="status"
                                                v-model="form.status"
                                                id="status"
                                                class="form-control"
                                                :class="{ 'is-invalid': form.errors.has('status') }"
                                            >
                                                <option value="Y">Ativo</option>
                                                <option value="N">Inativo</option>
                                            </select>
                                            <has-error :form="form" field="status"></has-error>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <label>User Foto</label>
                                        <input
                                            type="file"
                                            name="foto"
                                            class="form-control"
                                            :class="{ 'is-invalid': form.errors.has('foto') }"
                                            @change="updateAluno"
                                        >
                                        <has-error :form="form" field="foto"></has-error>
                                    </div>
                                </div>

                                </div>
                                <div class="tab-pane fade" id="address" role="tabpanel" aria-labelledby="address-tab">
                                    <Endereco
                                        :selectValue="form.endereco"
                                        v-on:selected="form.endereco = $event;"
                                    />
                                </div>

                                <SelectGraduacao
                                    :aluno_martial_arts="martial_arts"
                                    v-on:selected="
                                        form.martial_arts = $event.martial_arts;
                                        form.graduacao = $event.graduacao;
                                        form.presencas = $event.presencas;
                                        "
                                />
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                            <button type="submit" class="btn btn-primary"> Salvar </button>
                        </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
/**
* TODO : adicionad exlcução de alunos na mesma pagina () remover pagina separa para alunos deletedos;
*/
import SelectPessoa from "../financeiro/common/SelectPessoa";
import Multiselect from 'vue-multiselect';
import SelectGraduacao from "./common/SelectGraduacao.vue";
import Endereco from "../common/form/Endereco.vue";
import {mask} from 'vue-the-mask'
import CardsListaAlunos from "./common/CardsListaAlunos";
import Aluno from "../../domain/user/Aluno";

export default {
    components:{
        CardsListaAlunos,
        Endereco,
        Multiselect,
        SelectGraduacao,
        SelectPessoa,
    },
    directives: {mask},
    data () {
        return {
            plano: {},
            spinner: true,
            artes: [],
            editmode: false,
            alunos : {},
            academias: [],
            responsaveis: [],
            martial_arts: [],
            dados: {
                excluidos: 10, // alunos na lixeira
                total: 100, // total de alunos
                plano: 16, // quantidade contrata pelo plano
                utilizados: 500, // quantidade utlizado do plano
            },
            modalidades: [{graduacao: '', presencas: ''}],
            form: new Form(
                new Aluno()
            ),
        }
    },
    methods: {
        getResults(page = 1) {
            this.$Progress.start();
            axios.get('api/aluno?page=' + page).then((data) => {
                this.alunos = data.data.data;
            });
            this.$Progress.finish();
        },
        updateAluno(){
            this.$Progress.start();
            this.form.put('api/aluno/'+this.form.id)
            .then((response) => {
                $('#addNew').modal('hide');
                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                });
                this.$Progress.finish();
                this.loadAlunos();
            })
            .catch(() => {
                this.$Progress.fail();
            });
        },
        editModal(user){
            this.editmode = true;
            this.form.reset();
            this.martial_arts = [];
            this.martial_arts = user.martial_arts;
            $('#addNew').modal('show');
            this.form.fill(user);
        },
        newModal(){
            this.editmode = false;
            this.form.reset();
            $('#addNew').modal('show');
        },
        deleteAluno(id){
            Swal.fire({
                title: 'Tem certeza?',
                text: "Você poderá restaurar o aluno na lixeira",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Sim, adicionar a lixeira!'
                }).then((result) => {
                    if (result.value) {
                        this.form.delete('api/aluno/'+id).then(()=>{
                            Toast.fire({
                                icon: 'success',
                                title: 'O aluno foi aidiconado na lixeira!.'
                            });
                            this.loadAlunos();
                        }).catch((data)=> {
                            Swal.fire("Failed!", data.message, "warning");
                        });
                    }
                })
        },
        loadAlunos(){
            this.$Progress.start();
            axios.get("api/aluno").then(({ data }) => {this.alunos = data.data});
            this.$Progress.finish();
        },
        loadAcademias(){
            this.$Progress.start();
            axios.get("api/academia").then(({ data }) => {this.academias = data.data});
            this.$Progress.finish();
        },
        createAluno(){
            this.form.post('api/aluno')
            .then((response)=>{
                $('#addNew').modal('hide');

                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                });

                this.$Progress.finish();
                this.loadAlunos();
            })
            .catch(()=>{
                Toast.fire({
                    icon: 'error',
                    title: 'Ocorreu um erro! Por favor, tente novamente.'
                });
            })
        },
        mudaStatus(id) {
            this.$Progress.start();

            this.form.put(`api/alunoStatus/${id}`)
            .then((data) => {
                if (!data.data.success) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                    this.$Progress.failed();
                    return;
                }
                Toast.fire({
                    icon: 'success',
                    title: data.data.message
                });
                this.$Progress.finish();
                this.loadAlunos();
            })
            .catch(() => {
                Toast.fire({
                    icon: 'error',
                    title: 'Ocorreu um erro! Por favor, tente novamente.'
                });
            })
        },
        loadAlunosInfo(){
            this.$Progress.start();
            if(this.$gate.isAdmin()){
                axios.get("api/alunos/dados").then(({ data }) => {
                    this.dados.fill(data.data);
                    this.spinner = false;
                });
            }
            this.$Progress.finish();
        },
    },
    mounted() {
        console.log('User Component mounted.')
    },
    created() {
        this.$Progress.start();
        this.loadAlunos();
        this.loadAcademias();
        PLANO.then((result) => {
            this.plano = result.data;
        })
        this.$Progress.finish();
    },
    computed: {
        statusRegistros() {
            return (this.plano.alunos >= this.alunos.total + 1);
        }
    }
}
</script>
