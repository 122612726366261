<template>
<section class="content">
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="pagseguro-tab" data-toggle="tab" href="#pagseguro" role="tab" aria-controls="basic" aria-selected="true">
                                <strong>PagSeguro</strong>
                        </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="paghiper-tab" data-toggle="tab" href="#paghiper" role="tab" aria-controls="address" aria-selected="false">
                                <strong>PagHiper</strong>
                            </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="stripe-tab" data-toggle="tab" href="#stripe" role="tab" aria-controls="address" aria-selected="false">
                            <strong>Stripe</strong>
                            </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="pagarme-tab" data-toggle="tab" href="#pagarme" role="tab" aria-controls="address" aria-selected="false">
                                <strong>Pagar.me</strong>
                            </a>
                        </li>
                    </ul>

                    <div class="tab-content">
                        <div class="tab-pane fade  show active" id="pagseguro" role="tabpanel" aria-labelledby="pagseguro-tab">
                            <Pagseguro />
                        </div>
                        <div class="tab-pane fade" id="paghiper" role="tabpanel" aria-labelledby="paghiper-tab">
                            <Paghiper />
                        </div>
                        <div class="tab-pane fade" id="stripe" role="tabpanel" aria-labelledby="stripe-tab">
                            <Stripe />
                        </div>
                        <div class="tab-pane fade" id="pagarme" role="tabpanel" aria-labelledby="pagarme-tab">
                            <Pagarme />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import Pagseguro from "./Gateways/Pagseguro.vue";
import Paghiper from "./Gateways/Paghiper.vue";
import Pagarme from "./Gateways/Pagarme.vue";
import Stripe from "./Gateways/Stripe.vue";
export default {
    components:{
        Pagseguro,
        Paghiper,
        Pagarme,
        Stripe
    },
}
</script>

<style>

</style>
