export default class Academia {
    constructor(id= '',
                id_academia= '',
                type= '',
                name= '',
                email= '',
                password= '',
                email_verified_at= '',
                cnpj= '',
                roles= '',
                language= '',
                status= '',
                telefone= '',
                cep= '',
                endereco= {},
                numero= '',
                bairro= '',
                cidade= '',
                image= '',
                uf= '',
                senha= '',
                matriz= '',
                site= '',
                nome= '',
                responsavel= '',
                descricao= '',
                complemento= ''

    ) {
        this.id = id;
        this.id_academia = id_academia;
        this.type = type;
        this.name = name;
        this.email = email;
        this.password = password;
        this.email_verified_at = email_verified_at;
        this.cnpj = cnpj;
        this.roles = roles;
        this.language = language;
        this.status = status;
        this.telefone = telefone;
        this.cep = cep;
        this.endereco = endereco;
        this.numero = numero;
        this.bairro = bairro;
        this.cidade = cidade;
        this.image = image;
        this.uf = uf;
        this.senha = senha;
        this.matriz = matriz;
        this.site = site;
        this.nome = nome;
        this.responsavel = responsavel;
        this.descricao = descricao;
        this.complemento = complemento;
    }
}
