<template>
    <div>
        <div>
            <label class="typo__label">Artes Marciais</label>
            <multiselect v-model="artes" tag-placeholder="Clique para adicionar"
                placeholder="Selecione as modalidades" label="label"
                track-by="code"
                :options="options"
                :multiple="true"
                :taggable="true"
                @select="add($event)"
                @remove="remove"
            ></multiselect>
        </div>

        <div v-for="arte in artes" :key="arte.code">

            <div v-if="arte.graduacoes">
                <div class="row">
                    <div class="col">
                        <label> {{ arte.label }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Graduação</label>
                            <select class="form-control"
                                :id="'graduacao.'+arte.code"
                                :name="'graduacao.'+arte.code"
                                v-model="form.graduacao[arte.code]"
                                :class="{ 'is-invalid': form.errors.has('graduacao.'+arte.code) }">
                                <option v-for="(graduacao, index) in arte.graduacoes" :key="index" :value="index">
                                    {{ graduacao }}
                                </option>
                            </select>
                            <has-error :form="form" :field="'graduacao.'+arte.id"></has-error>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label> Presencas </label>
                            <input type="text"
                                :id="'presencas.'+arte.code"
                                :name="'presencas.'+arte.code"
                                v-model="form.presencas[arte.code]"
                                class="form-control"
                                :class="{ 'is-invalid': form.errors.has('presencas.'+arte.code) }">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
export default {
    name: "SelectGraduacao",
    components:{
        Multiselect
    },
    props: {
        aluno_martial_arts: {type: Array}
    },
    data () {
        return{
            artes: [],
            form: new Form({
                graduacao: [],
                presencas: [],
                martial_arts: [],
            }),
            aluno_artes: [],
            options: [],
        }
    },
    methods: {
        add(arte){
            this.form.martial_arts.push(arte.code);
            arte.presencas = null;
            this.form.graduacao[arte.code] = null;
            this.form.presencas[arte.code] = null;

            this.$emit('selected', {
                    martial_arts: this.form.martial_arts,
                    graduacao: this.form.graduacao,
                    presencas: this.form.presencas
                });
        },
        remove(data){
            if(data.graduacoes == undefined){
                var confirm = window.confirm("Tem certeza que deseja excluir a modalidade do aluno ? Todas as presenças do aluno dessa modalidade serão excluidas permanentemente");
                if(!confirm){
                    this.artes.splice(this.artes.indexOf(data), 0, data);
                    return true;
                }
            }
            this.form.martial_arts.splice(this.form.martial_arts.indexOf(data.code), 1);
            this.form.graduacao.splice(data.code, 1);
            this.form.presencas.splice(data.code, 1);

            this.$emit('selected', {
                    martial_arts: this.form.martial_arts,
                    graduacao: this.form?.graduacao,
                    presencas: this.form?.presencas
                });
        },
        loadArtesMarciais(){
            this.$Progress.start();
                axios.get("/api/academiaModalidadesSelect").then(({ data }) => {
                    this.options = Object.values(JSON.parse(JSON.stringify(data.data)));
                });
            this.$Progress.finish();
        },
        setMartialArts(artes){
            axios.get("/api/academiaModalidadesSelect").then(({ data }) => {
                this.options = Object.values(JSON.parse(JSON.stringify(data.data)));
                artes.forEach(arte => {
                    var aluno_arte = this.options.find(item => item.code == arte.id);
                    delete aluno_arte.graduacoes;
                    delete aluno_arte.presencas;
                    this.form.martial_arts.push(aluno_arte.code);
                    this.artes.push(aluno_arte);
                });
            });

            this.$emit('selected', {
                    martial_arts: this.form.martial_arts
                });
        }
    },
    created(){
        this.loadArtesMarciais();
        console.log("SelectGraduacao component created");
    },
    watch: {
        aluno_martial_arts: function (newVal, oldVal) {
            if(newVal == undefined ||  newVal.length == 0){
                this.form.martial_arts = [];
                this.artes = [];
            }else{
                this.setMartialArts(newVal);
            }
        },
    }

}
</script>

<style scoped>

</style>
