<template>

<div class="card bg-light">
    <h5 class="card-header ">Adicionar medalhas</h5>
    <div class="card-body ">


 <form @submit.prevent="createMedalha()">
        <div class="row">
            <div class="col">
                <div class="form-group">

                    <label for="responsavel">Modalidaes</label>
                    <select class="form-control" v-model="form.modalidade" id="modaliades" name="modalidade">
                         <option
                            v-for="modalidade in modalidades" :key="modalidade.id"
                            :value="modalidade.id">
                            {{ modalidade.name }}
                        </option>
                    </select>
                    <has-error :form="form" field="modalidade"></has-error>
                </div>
            </div>
            <div class="col">
                <div class="form-group">

                    <label for="responsavel">Icones</label>
                    <select class="form-control" v-model="form.icone" id="responsavel" name="icone">
                        <option
                            v-for="icone in icones"
                            :value="icone">
                            {{ icone }}
                        </option>
                    </select>
                    <has-error :form="form" field="icone"></has-error>

                </div>
            </div>
            <div class="col">
                <div class="form-group">

                    <label>Data de Nascimento</label>
                    <input  type="date" name="data" v-model="form.date" class="form-control">
                    <has-error :form="form" field="data"></has-error>

                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="form-group">

                    <label>Descrição</label>
                    <input type="text" name="descricao" v-model="form.descricao" class="form-control">
                    <has-error :form="form" field="descricao"></has-error>

                </div>
            </div>
        </div>


        <div class="row">
            <div class="col">
                <button type="submit" class="btn btn-success float-right">
                   Salvar
                </button>
            </div>
        </div>
        </form>
    </div>
</div>

</template>
<script>
export default {
    name: 'FormMedalhas',
    data() {
        return {
            icones: [
                '\u{1F947}',
                "\u{1F948}",
                "\u{1F949}",
                "\u{1F3C6}",
                "\u{1F47F}",
                "\u{1F4A1}",
                "\u{2705}",
                "\u{1F921}",
                "\u{2620}",
                "\u{1F910}",
                "\u{1F47D}",
                "\u{1F648}",
                "\u{1F649}",
                "\u{1F64A}",
                "\u{1F468}\u{200D}\u{1F393}",
                "\u{1F607}",
                "\u{1F44A}",
                "\u{1F64F}",
                "\u{1F485}",
                "\u{1F381}",
                "\u{1F94B}",
            ],
            form: new Form({
                modalidade: '',
                icone: '',
                descricao: '',
                date: ''
            }),
            modalidades: [
                {id: 1, name: 'Jiu-Jitsu' },
                {id: 2, name: 'Judo' },
                {id: 3, name: 'Capoeira' }
            ],
        }
    },
    methods: {
        createMedalha(){
            this.form.post(`/api/aluno/add_medals/${this.$route.params.id}`)
            .then((response)=>{
                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                });
                this.$Progress.finish();
            })
            .catch(()=>{
                Toast.fire({
                    icon: 'error',
                    title: 'Ocorreu um erro! Por favor, tente novamente.'
                });
            })
        },
        loadArtesMarciais(){
            this.$Progress.start();
                axios.get("api/academiaModalidades").then(({ data }) => {
                    this.modalidades = Object.values(JSON.parse(JSON.stringify(data.data)));
                });

            this.$Progress.finish();
        },
    },
    mounted() {
        console.log('Form medalha component mounted')
    },
    created() {
        this.$Progress.start();
        // this.loadArtesMarciais();
        this.$Progress.finish();
    }
}
</script>
