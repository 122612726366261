export default [
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/users', component: require('./components/usuario/Users.vue').default },
    { path: '/enquetes', component: require('./components/enquete/Enquetes.vue').default },
    { path: '/enquete/:id/relatorio', component: require('./components/enquete/EnqueteRelatorio.vue').default },
    { path: '/agendas', component: require('./components/agenda/Agendas.vue').default },
    { path: '/agenda/:id/confirmados', component: require('./components/agenda/AgendaConfirmados.vue').default },

    { path: '/videos', component: require('./components/video/Videos.vue').default },
    { path: '/professor', component: require('./components/professor/Professor.vue').default },
    { path: '/alunos', component: require('./components/aluno/Alunos.vue').default },
    { path: '/aluno/:id/perfil', component: require('./components/aluno/perfil/Perfil.vue').default },
    { path: '/lixeira', component: require('./components/aluno/Lixeira.vue').default },
    { path: '/unidades', component: require('./components/unidades/Unidades.vue').default },
    { path: '/unidade/:id/detalhes', component: require('./components/unidades/Unidade.vue').default },
    { path: '/posts', component: require('./components/post/Posts.vue').default },
    { path: '/post/:id/detalhes', component: require('./components/post/Post.vue').default },
    { path: '/financeiro/categorias', component: require('./components/financeiro/categoria/Categorias.vue').default },
    { path: '/financeiro/produtos', component: require('./components/financeiro/produto/Produtos.vue').default },
    { path: '/financeiro/bancos', component: require('./components/financeiro/banco/Bancos.vue').default },
    { path: '/financeiro/pagar', component: require('./components/financeiro/pagar/Pagar.vue').default },
    { path: '/financeiro/receber', component: require('./components/financeiro/receber/Receber.vue').default },
    { path: '/vendas', component: require('./components/venda/Vendas.vue').default },
    { path: '/configuracao', component: require('./components/configuracao/Configuracao.vue').default },
    { path: '/configuracao/modalidades', component: require('./components/configuracao/Modalidades.vue').default },
    { path: '/configuracao/aplicativos', component: require('./components/configuracao/Aplicativos.vue').default },
    { path: '/configuracao/gateways', component: require('./components/configuracao/Gateways.vue').default },
    { path: '/configuracao/recibo', component: require('./components/configuracao/Recibo.vue').default },
    { path: '/configuracao/privacidade', component: require('./components/configuracao/Privacidade.vue').default },
    { path: '/configuracao/exclusao', component: require('./components/configuracao/Exclusao.vue').default },

    { path: '*', component: require('./components/NotFound.vue').default },


    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    // { path: '/product/tag', component: require('./components/product/Tag.vue').default },
    // { path: '/product/category', component: require('./components/product/Category.vue').default },
    // { path: '/products', component: require('./components/product/Products.vue').default },


];
