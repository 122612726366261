<template>
    <div class="form-group">
        <label for="responsavel">Responsavel</label>
        <select
            class="form-control"
            v-model="form.responsavel"
            id="responsavel"
            @change="select()"
        >
            <option
                v-for="aluno in alunos.data"
                :key="aluno.id"
                :value="aluno.id"
                :selected="aluno.full_name === responsavel">
                {{ aluno.full_name }}
            </option>
        </select>
        <has-error :form="form" field="responsavel"></has-error>
    </div>
</template>

<script>
export default {
    name: "SelectResponsavel",
    props:{
        responsavel:{
            type: String,
        }
    },
    data(){
        return{
            alunos: [],
            form: new Form({
                responsavel: '',
            }),
        }
    },
    methods:{
        loadAlunos(){
            this.$Progress.start();
            axios.get("/api/aluno").then(({ data }) => {
                this.alunos = data.data;
                console.log(data.data);
            });
            this.$Progress.finish();
        },
        select(){
            this.$emit('selected', this.form.responsavel);
        }
    },
    created(){
        this.loadAlunos();
        console.log("Componente Select Responsavel create");
    },
    mounted(){
        console.log("COmpoenente SelectResposanvel montado");
    },
    watch:{
        responsavel: function (valor) {
            this.responsavel = valor;
        },
    }

}
</script>

<style>

</style>
