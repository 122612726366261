<template>
    <div>
        <button class="btn btn-outline-warning" @click="newModal()">
            <i class="fas fa-trophy align-middle"></i> Graduar <slot></slot>
        </button>
    </div>

</template>

<script>
export default {
    name: "GraduarButton",
    props:{
        id_arte: { type: Number, require: false },
        id_aluno: { type: [Number, String], require: true },
        artes: {type: Array, required: false },
    },
    methods:{
        newModal(){
            this.$emit('open',{
                    status: 1,
                    id_arte: this.id_arte,
                    id_aluno: this.id_aluno,
                    artes: this.artes
                }
            );
        },
    },
    created(){ },
    mounted(){ },
}
</script>

<style>

</style>
