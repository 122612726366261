<template>
    <div>
        <label class="typo__label">Categoria</label>

        <multiselect
            v-model="value"
            track-by="nome"
            label="nome"
            placeholder="Selecione um"
            :options="options"
            :searchable="false"
            :allow-empty="false"
            @select="setSelected"
            :disabled="status"
        >
            <template slot="singleLabel" slot-scope="{ option }">
                <strong>{{ option.nome }}</strong>
            </template>
        </multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    components: {
        Multiselect
    },
    props: {
        selectValue: {
            type: [Object],
        },
        tipo: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            options: [
                {nome: 'Carregando...', id: null},
            ],
            value: [],
            a:false,
        }
    },
    methods: {
        getResults() {
            this.$Progress.start();
            axios.get(`/api/financeiro/categorias/${this.tipo}`).then(({data}) => (this.options = data.data));
            this.$Progress.finish();
        },
        setSelected(selectedOption) {
            this.$emit('selected', selectedOption)
        }
    },
    created() {
        this.getResults();
    },
    watch: {
        selectValue: function (newVal, oldVal) {
            this.a = (newVal.form.status > 0);

            let resultValue = null;
            this.options.map(function (data, key) {
                if (data.id === newVal.value) {
                    resultValue = data;
                }
            });

            this.value = resultValue;
        }
    },
    computed: {
        status: {
            get: function () {
                return this.a;
            },
            set: function (v) {
                this.a = v;
            }
        }
    }
}

</script>

<style scoped>

</style>
