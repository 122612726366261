<!--
TODO: Busca automática de endereço com Viacep
TODO: Adicionar busca
-->

<template>
    <section class="content">
        <b-container fluid>

            <div class="row">

                <div class="col-12">

                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Pessoas</h3>

                            <div class="card-tools">
                                <button type="button" class="btn btn-sm btn-primary" @click="newModal" title="cadastrar novo">
                                    <i class="fa fa-plus-square"></i>
                                    NOVA PESSOA
                                </button>
                            </div>
                        </div>
                        <div class="card-body table-responsive p-0">

                            <b-form-group class="m-3">
                                <b-input-group >
                                    <b-form-input
                                        id="filter-input"
                                        v-model="filter"
                                        type="search"
                                        placeholder="Busque por nome, email, telefone ou documento"
                                    ></b-form-input>

                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Limpar</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>


                            <b-table
                                :items="users.data"
                                :fields="fields"
                                :per-page="users.per_page"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :sort-direction="sortDirection"
                                stacked="md"
                                show-empty
                                small
                                @filtered="onFiltered"
                                :tbody-tr-class="rowClass"
                                striped
                                hover
                                responsive
                                :busy="isBusy"
                            >

                                <template #cell(actions)="row">
                                    <b-button variant="primary" size="sm" @click="editModal(row.item, row.index, $event.target)" class="mr-1">
                                        <i class="fa fa-edit"></i>
                                    </b-button>
                                    <b-button variant="danger" size="sm" @click="deleteUser(row.item.id)">
                                        <i class="fa fa-trash"></i>
                                    </b-button>
                                </template>

                                <template #cell(status)="row">
                                    <span :value="row.item.status"
                                          @click="mudaStatus(row.item.id)"
                                          class="badge cursor-pointer"
                                          v-bind:class="row.item.status === 'Y' ? 'badge-success' : 'badge-danger'"
                                    >
                                        {{ row.item.status === 'Y' ? 'Ativo' : 'Inativo' }}
                                    </span>
                                </template>

                                <template #row-details="row">
                                    <b-card>
                                        <ul>
                                            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                                        </ul>
                                    </b-card>
                                </template>
                            </b-table>
                        </div>
                        <div class="card-footer">
                            <b-pagination
                                :total-rows="users.total"
                                :per-page="users.per_page"
                                align="right"
                                @change="getResults"
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" v-show="!editmode">Criar Nova Pessoa</h5>
                            <h5 class="modal-title" v-show="editmode">Atualizar Informações da Pessoa</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <form @submit.prevent="editmode ? updateUser() : createUser()">

                            <div class="modal-body">

                                <div class="form-group">
                                    <label for="type">Tipo</label>
                                    <select name="type"
                                            v-model="form.type"
                                            id="type"
                                            class="form-control"
                                            :class="{ 'is-invalid': form.errors.has('type') }"
                                    >
                                        <option value="juridica">Jurídica</option>
                                        <option value="fisica">Física</option>
                                    </select>
                                    <has-error :form="form" field="type"></has-error>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="document">CPF/CNPJ</label>
                                            <the-mask :mask="['###.###.###-##', '##.###.###/####-##']"
                                                      v-model="form.cpf_cnpj" type="text" name="cpf_cnpj"
                                                      class="form-control"
                                                      :class="{ 'is-invalid': form.errors.has('cpf_cnpj') }"
                                                      id="document"
                                            />
                                            <has-error :form="form" field="cpf_cnpj"></has-error>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="name">Nome</label>
                                            <input v-model="form.name"
                                                   type="text"
                                                   name="name"
                                                   id="name"
                                                   class="form-control"
                                                   :class="{ 'is-invalid': form.errors.has('name') }"
                                            >
                                            <has-error :form="form" field="name"></has-error>
                                        </div>
                                    </div>
                                </div>

                                <ul class="nav nav-tabs" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link active" id="basic-tab" data-toggle="tab" href="#basic"
                                           role="tab" aria-controls="basic" aria-selected="true">
                                            Básico
                                        </a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link" id="address-tab" data-toggle="tab" href="#address"
                                           role="tab" aria-controls="address" aria-selected="false">
                                            Endereço
                                        </a>
                                    </li>
                                </ul>
                                <div class="tab-content">
                                    <div class="tab-pane fade  show active" id="basic" role="tabpanel"
                                         aria-labelledby="basic-tab">
                                        <div class="form-group">
                                            <label for="roles">Permissões</label>
                                            <select name="roles"
                                                    v-model="form.roles"
                                                    id="roles"
                                                    class="form-control"
                                                    :class="{ 'is-invalid': form.errors.has('roles') }"
                                            >
                                                <option value="responsavel">Responsável</option>
                                                <option value="professor">Professor</option>
                                                <option value="colaborador">Colaborador</option>
                                                <option value="fornecedor">Fornecedor</option>
                                            </select>
                                            <has-error :form="form" field="roles"></has-error>
                                        </div>

                                        <div class="row">
                                            <div class="col">
                                                <div class="form-group">
                                                    <label for="language">Idioma</label>
                                                    <select name="language"
                                                            v-model="form.language"
                                                            id="language"
                                                            class="form-control"
                                                            :class="{ 'is-invalid': form.errors.has('language') }"
                                                    >
                                                        <option value="br">Portugues</option>
                                                        <option value="en">Inglês</option>
                                                    </select>
                                                    <has-error :form="form" field="language"></has-error>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="form-group">
                                                    <label for="status">Status</label>
                                                    <select name="status"
                                                            v-model="form.status"
                                                            id="status"
                                                            class="form-control"
                                                            :class="{ 'is-invalid': form.errors.has('status') }"
                                                    >
                                                        <option value="Y">Ativo</option>
                                                        <option value="N">Inativo</option>
                                                    </select>
                                                    <has-error :form="form" field="status"></has-error>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col">
                                                <div class="form-group">
                                                    <label for="email">Email</label>
                                                    <input v-model="form.email"
                                                           type="text"
                                                           name="email"
                                                           class="form-control"
                                                           id="email"
                                                           :class="{ 'is-invalid': form.errors.has('email') }"
                                                    />
                                                    <has-error :form="form" field="email"></has-error>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="form-group">
                                                    <label for="password">Senha</label>
                                                    <input v-model="form.password"
                                                           type="password"
                                                           name="password"
                                                           class="form-control"
                                                           id="password"
                                                           :class="{ 'is-invalid': form.errors.has('password') }"
                                                    />
                                                    <has-error :form="form" field="password"></has-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="telefone">Telefone</label>
                                            <the-mask :mask="['(##) ####-####', '(##) #####-####']"
                                                      v-model="form.telefone" type="tel" name="telefone"
                                                      class="form-control"
                                                      :class="{ 'is-invalid': form.errors.has('telefone') }"
                                                      id="telefone"
                                            />
                                            <has-error :form="form" field="telefone"></has-error>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="address" role="tabpanel"
                                         aria-labelledby="address-tab">
                                        <div class="form-group">
                                            <label>CEP</label>
                                            <the-mask :mask="['#####-###']"
                                                      v-model="form.cep" type="tel" name="cep"
                                                      class="form-control"
                                                      :class="{ 'is-invalid': form.errors.has('cep') }"
                                                      :key="buscaCep()"
                                            />
                                            <has-error :form="form" field="cep"></has-error>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div class="form-group">
                                                    <label for="endereco">Endereço</label>
                                                    <input v-model="form.endereco"
                                                           type="text"
                                                           name="endereco"
                                                           id="endereco"
                                                           class="form-control"
                                                           :class="{ 'is-invalid': form.errors.has('endereco') }"
                                                    />
                                                    <has-error :form="form" field="endereco"></has-error>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="form-group">
                                                    <label for="numero">Numero</label>
                                                    <input v-model="form.numero" type="number"
                                                           name="numero"
                                                           id="numero"
                                                           class="form-control"
                                                           :class="{ 'is-invalid': form.errors.has('numero') }"
                                                    />
                                                    <has-error :form="form" field="numero"></has-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div class="form-group">
                                                    <label for="bairro">Bairro</label>
                                                    <input v-model="form.bairro"
                                                           type="text"
                                                           name="bairro"
                                                           id="bairro"
                                                           class="form-control"
                                                           :class="{ 'is-invalid': form.errors.has('bairro') }"
                                                    />
                                                    <has-error :form="form" field="bairro"></has-error>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="form-group">
                                                    <label for="cidade">Cidade</label>
                                                    <input v-model="form.cidade"
                                                           type="text"
                                                           name="cidade"
                                                           id="cidade"
                                                           class="form-control"
                                                           :class="{ 'is-invalid': form.errors.has('cidade') }"
                                                    />
                                                    <has-error :form="form" field="cidade"></has-error>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="form-group">
                                                    <label for="uf">UF</label>
                                                    <input v-model="form.uf"
                                                           type="text"
                                                           name="uf"
                                                           id="uf"
                                                           maxlength="2"
                                                           class="form-control"
                                                           :class="{ 'is-invalid': form.errors.has('uf') }"
                                                    />
                                                    <has-error :form="form" field="uf"></has-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="complemento">Complemento</label>
                                            <input v-model="form.complemento"
                                                   type="text"
                                                   name="complemento"
                                                   id="complemento"
                                                   class="form-control"
                                                   :class="{ 'is-invalid': form.errors.has('complemento') }"
                                            />
                                            <has-error :form="form" field="complemento"></has-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                <button type="submit" class="btn btn-success">Salvar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </b-container>
    </section>
</template>

<script>
import PhoneMaskInput from "vue-phone-mask-input";
import User from '../../domain/user/User';

export default {
    components: {
        PhoneMaskInput
    },
    data() {
        return {
            fields: [
                { key: 'name', label: 'Nome', sortable: true, sortDirection: 'desc' },
                { key: 'cpf_cnpj', label: 'Documento', sortable: true, class: 'text-center' },
                { key: 'telefone', label: 'Telefone', sortable: true, class: 'text-center' },
                { key: 'email', label: 'Email', sortable: true, class: 'text-center' },
                { key: 'status', label: 'Status', sortable: true, class: 'text-center' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            pageOptions: [5, 10, 15, 100],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],




            editmode: false,
            users: {
                data: [],
                total: 0,
                per_page: 15,
            },
            form: new Form(
                new User()
            ),
            telefone: null,
            current_page: 1,
            isBusy: true
        }
    },
    methods: {
        loadUsers() {
            this.isBusy = true;
            this.$Progress.start();
            axios.get(`/api/user`).then(({data}) => {
                this.users = data.data;
                this.isBusy = false;
            });
            this.$Progress.finish();
        },
        getResults(page = 1) {
            this.isBusy = true;
            this.current_page = page;
            this.$Progress.start();
            axios.get(`/api/user?page=${this.current_page}`).then(({data}) => {
                this.users = data.data;
                this.isBusy = false;
            });
            this.$Progress.finish();
        },
        updateUser() {
            this.$Progress.start();
            this.form.put(`/api/user/${this.form.id}`)
                .then((response) => {
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();
                    this.loadUsers();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
        },
        editModal(user) {
            this.editmode = true;
            this.form.reset();
            this.telefone = this.form.telefone;
            $('#addNew').modal('show');
            this.form.fill(user);
        },
        newModal() {
            this.editmode = false;
            this.form.reset();
            this.telefone = null;
            $('#addNew').modal('show');
        },
        deleteUser(id) {
            Swal.fire({
                title: 'Tem certeza?',
                text: "Você não poderá restaurar depois!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Sim, excluir!'
            }).then((result) => {
                if (result.value) {
                    this.form.delete(`/api/user/${id}`).then(() => {
                        Toast.fire(
                            'Excluído!',
                            'O usuário foi excluído.',
                            'success'
                        );
                        this.loadUsers();
                    }).catch((data) => {
                        Swal.fire("Erro!", data.message, "error");
                    });
                }
            })
        },
        createUser() {
            this.form.post('/api/user')
                .then((response) => {
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();
                    this.loadUsers();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
        mudaStatus(id) {
            this.$Progress.start();
            this.form.put(`/api/usuarioStatus/${id}`)
                .then((data) => {
                    if (!data.data.success) {
                        Toast.fire({
                            icon: 'error',
                            title: 'Ocorreu um erro! Por favor, tente novamente.'
                        });
                        this.$Progress.fail();
                        return;
                    }
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    this.$Progress.finish();
                    this.getResults(this.current_page);
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
        buscaCep(){
            if(this.form.cep.length>=7){
                // axios.get(`https://viacep.com.br/ws/${this.form.cep}/json/`).then((data) => {
                //     console.log(data);
                // })
            }
        },


        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.users.total = filteredItems.length
            this.users.current_page = 1
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 'N') return 'table-danger'
        }

    },
    mounted() {
        this.totalRows = this.users.total

    },
    created() {
        this.$Progress.start();
        this.loadUsers();
        this.$Progress.finish();
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }

    },
    watch: {
        filter(value){
            this.search_term = `&search=${value}`
            console.log(value, this.search_term)
        }
    }
}
</script>
