<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12">

                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Detalhes da enquete: {{ enquete.pergunta }}</h3>

                            <div class="card-tools">

                                <router-link to="/enquetes" class="btn btn-sm btn-warning">
                                    <i class="fas fa-backward"></i>
                                    Voltar
                                </router-link>
                            </div>
                        </div>

                        <div class="card-body table-responsive p-0">

                            <div class="p-3">
                                <RelatorioGrafico :chart-data="datacollection" :height="100"></RelatorioGrafico>

                                <div class="info-box bg-gradient-warning mt-3">
                                    <span class="info-box-icon"><i class="fas fa-check-double"></i></span>
                                    <div class="info-box-content">
                                        <span class="info-box-text">Total Respostas</span>
                                        <span class="info-box-number">{{ total }}</span>
                                    </div>
                                </div>
                            </div>

                            <table class="table table-bordered table-hover">
                                <thead>
                                <tr>
                                    <th>Aluno</th>
                                    <th>Resposta</th>
                                    <th>Data</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-if="respostas.length > 0" v-for="resposta in respostas">
                                    <td>{{ resposta.name }}</td>
                                    <td>{{ resposta.resposta }}</td>
                                    <td>{{ resposta.date }}</td>
                                </tr>
                                <tr v-if="respostas.length <= 0">
                                    <td colspan="5">
                                        Sem respostas cadastradas
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
import RelatorioGrafico from "./RelatorioGrafico.js";

export default {
    name: "EnqueteRelatorio",
    components: {
        RelatorioGrafico,
    },
    data() {
        return {
            enquete: {},
            respostas: {},
            total: 0,
            datacollection: null
        }
    },
    methods: {
        loadEnquetes() {
            axios.get(`/api/enquete/${this.$route.params.id}`).then(({data}) => {
                this.enquete = data.data;
                this.total = this.enquete.total;
                this.respostas = this.enquete.respostas;
                this.fillData()
            });
        },
        fillData ()
        {
            this.datacollection = {
                labels: this.enquete.grafico.labels,
                datasets: [
                    {
                        label: this.enquete.pergunta,
                        backgroundColor: this.enquete.grafico.colors,
                        data: Object.values(JSON.parse(JSON.stringify(this.enquete.grafico.datasets)))
                    },
                ]
            }
        }
    },
    mounted() {},
    created() {
        this.$Progress.start();
        this.loadEnquetes();
        this.$Progress.finish();
    },
    filters: {},
    computed: {},
}
</script>
