<template>
    <div class="row">
        <div class="col-md-6 col-sm-6 col-12">
            <div class="card card-success">
                <div class="card-header border-transparent">
                    <h3 class="card-title">
                        Contas a receber
                    </h3>
                    <div class="card-tools">
                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                            <i class="fas fa-minus"></i>
                        </button>
                        <button type="button" class="btn btn-tool" data-card-widget="remove">
                            <i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive" style="height: 600px;">
                        <table class="table m-0">
                            <thead>
                            <tr>
                                <th>Pagador</th>
                                <th>Valor</th>
                                <th>Data</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="conta in contasReceber.data" :key="conta.id">
                                  <td>{{ conta.usuario_nome }}</td>
                                  <td>{{ conta.valor | currency }}</td>
                                  <td>{{ conta.data_vencimento | moment("DD/MM/YYYY") }}</td>
                                  <td>
                                            <span :value="conta.status"
                                                  class="badge cursor-pointer"
                                                  v-bind:class="conta.status ? 'badge-success' : 'badge-danger'"
                                                  @click="mudaStatus(conta.id, 'D')"
                                            >
                                                {{ conta.status ? 'Pago' : 'Não Pago' }}
                                            </span>
                                  </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer clearfix">
                    <router-link to="/financeiro/receber" class="btn btn-sm btn-secondary float-right">
                        Contas a receber
                    </router-link>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-12">
            <div class="card card-danger">
                <div class="card-header border-transparent">
                    <h3 class="card-title">
                        Contas a Pagar
                        </h3>

                    <div class="card-tools">
                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                            <i class="fas fa-minus"></i>
                        </button>
                        <button type="button" class="btn btn-tool" data-card-widget="remove">
                            <i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive" style="height: 600px;">
                        <table class="table m-0">
                            <thead>
                            <tr>
                                <th>Pagador</th>
                                <th>Total</th>
                                <th>Vencimento</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="conta in contasPagar.data" :key="conta.id">
                                  <td>{{ conta.usuario_nome }}</td>
                                  <td>{{ conta.valor | currency }}</td>
                                  <td>{{ conta.data_vencimento | moment("DD/MM/YYYY") }}</td>
                                  <td>
                                            <span :value="conta.status"
                                                  class="badge cursor-pointer"
                                                  v-bind:class="conta.status ? 'badge-success' : 'badge-danger'"
                                                  @click="mudaStatus(conta.id, 'R')"
                                            >
                                                {{ conta.status ? 'Pago' : 'Não Pago' }}
                                            </span>
                                  </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer clearfix">
                    <router-link to="/financeiro/pagar" class="btn btn-sm btn-secondary float-right">
                        Contas a Pagar
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

const dataAtual = moment().format('YYYY-MM-DD');
const filters = `?periodo=personalizado&search_data_inicio=2000-01-01&search_data_final=${dataAtual}&status=0`;
export default {
    name: "ContasAtrasadas",
        data(){
            return{
                contasPagar:[
                ],
                contasReceber:[
                ],
            }
        },
        methods:{
          getReceitas(){
            axios.get(`/api/financeiro/receber${filters}`).then(({data}) => (this.contasReceber = data.data));
          },
          getDespesas(){
            axios.get(`/api/financeiro/pagar${filters}`).then(({data}) => (this.contasPagar = data.data));
          },
          mudaStatus(id, tipo = 'R') {
            this.$Progress.start();
            axios.put(`/api/contaStatus/${id}`)
                .then((data) => {
                  if (!data.data.success) {
                    Toast.fire({
                      icon: 'error',
                      title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                    this.$Progress.fail();
                    return;
                  }
                  Toast.fire({
                    icon: 'success',
                    title: data.data.message
                  });
                  this.$Progress.finish();

                  if(tipo == 'D')
                    this.getReceitas();
                  else
                    this.getDespesas();
                })
                .catch(() => {
                  Toast.fire({
                    icon: 'error',
                    title: 'Ocorreu um erro! Por favor, tente novamente.'
                  });
                })
          },
        },
        mounted(){
          this.$Progress.start();
          this.getReceitas();
          this.getDespesas();
          this.$Progress.finish();
        },
        created(){

        },
}
</script>

<style>

</style>
