<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">

                    <b-alert
                        variant="warning"
                        show
                        dismissible
                        fade
                        v-if="!statusRegistros">
                        Você atingiou o limite de enquetes!
                    </b-alert>

                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Enquetes</h3>
                            <div class="card-tools">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-primary"
                                    @click="newModal"
                                    title="cadastrar"
                                    v-if="statusRegistros"
                                >
                                    <i class="fa fa-plus-square"></i>
                                    NOVA ENQUETE
                                </button>
                            </div>
                        </div>
                        <div class="card-body table-responsive p-0">

                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>Pergunta</th>
                                    <th>Tipo</th>
                                    <th>Data</th>
                                    <th>Status</th>
                                    <th>Ação</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="enquete in enquetes.data" :key="enquete.id_enquete">
                                    <td>{{ enquete.pergunta }}</td>
                                    <td>{{ enquete.tipo }}</td>
                                    <td>{{ enquete.data_cadastro }}</td>
                                    <td>
                                        <span :value="enquete.status"
                                              @click="mudaStatus(enquete.id_enquete)"
                                              class="badge cursor-pointer"
                                              v-bind:class="enquete.status === 'Y' ? 'badge-success' : 'badge-danger'"
                                        >
                                            {{ enquete.status === 'Y' ? 'Ativa' : 'Inativa' }}
                                        </span>
                                    </td>
                                    <td>
                                        <router-link :to="`/enquete/${enquete.id_enquete}/relatorio`"
                                                     class="btn btn-sm btn-secondary"
                                        >
                                            <i class="fas fa-chart-area"></i> Resultado
                                        </router-link>
                                        <a href="#"
                                           @click="editModal(enquete)"
                                           class="btn btn-sm btn-primary"
                                           title="editar"
                                        >
                                            <i class="fa fa-edit"></i>
                                        </a>
                                        <a href="#"
                                           @click="deleteEnquete(enquete.id_enquete)"
                                           class="btn btn-sm btn-danger"
                                           title="excluir"
                                        >
                                            <i class="fa fa-trash"></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">

                            <no-records v-if="enquetes.data.length <= 0"></no-records>

                            <pagination :data="enquetes" @pagination-change-page="getResults"></pagination>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" v-show="!editmode">Cadastrar Nova Enquete</h5>
                            <h5 class="modal-title" v-show="editmode">Editar Enquete</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <form @submit.prevent="editmode ? updateEnquete() : createEnquete()">
                            <div class="modal-body">
                                <div class="form-group">
                                    <label for="pergunta">Pergunta</label>
                                    <input v-model="form.pergunta"
                                           type="text"
                                           name="pergunta"
                                           class="form-control"
                                           :class="{ 'is-invalid': form.errors.has('pergunta') }"
                                           id="pergunta"
                                    >
                                    <has-error :form="form" field="pergunta"></has-error>
                                </div>
                                <div class="form-group">
                                    <label for="status">Status</label>
                                    <select class="form-control"
                                            v-model="form.status"
                                            id="status"
                                    >
                                        <option
                                            v-for="status in statusEnquete"
                                            :value="status.value"
                                            :selected="status.value === form.status">
                                            {{ status.text }}
                                        </option>
                                    </select>
                                    <has-error :form="form" field="status"></has-error>
                                </div>
                                <div class="form-group">
                                    <label for="tipo">Tipo</label>
                                    <select class="form-control"
                                            v-model="form.tipo"
                                            @change="selectTipo"
                                            :disabled="editmode"
                                            id="tipo"
                                    >
                                        <option
                                            v-for="tipo in tipoEnquete"
                                            :value="tipo.value"
                                            :selected="tipo.value === form.tipo">
                                            {{ tipo.text }}
                                        </option>
                                    </select>
                                    <has-error :form="form" field="tipo"></has-error>
                                </div>

                                <hr>
                                <p>
                                    Respostas<br>
                                    <small class="text-primary" v-show="form.tipo === 'Text'">
                                        <strong>Text Area: </strong> O usuário visualizará uma caixa de texto para escrever sua opinião sobre o assunto.
                                    </small>
                                    <small class="text-primary" v-show="form.tipo === 'Radio'">
                                        <strong>Radio Button: </strong> O usuário poderá selecionar apenas uma das opções
                                    </small>
                                    <small class="text-primary" v-show="form.tipo === 'Check'">
                                        <strong>Check Box: </strong> O usuário poderá selecionar várias ou todas as opções
                                    </small>
                                </p>

                                <div class="input-group pt-1" v-for="(input,k) in inputs" :key="k">
                                    <input type="text"
                                           class="form-control"
                                           :placeholder="'Resposta ' + k "
                                           v-model="input.name">
                                    <input type="hidden"
                                           v-model="input.id">
                                    <span class="input-group-append">
                                        <a class="btn btn-danger btn-flat"
                                           @click="remove(k)"
                                           v-show="(k || ( !k && inputs.length > 1)) && !editmode">
                                            <i class="fas fa-minus-circle"></i>
                                        </a>
                                        <a class="btn btn-success btn-flat"
                                           @click="add(k)"
                                           v-show="(k == inputs.length-1) && inputs.length <= 6 && form.tipo != 'Text' && !editmode">
                                            <i class="fas fa-plus-circle"></i>
                                        </a>
                                    </span>
                                </div>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                <button type="submit" class="btn btn-primary">Salvar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Enquete from "../../domain/enquete/Enquete";

export default {
    components: {
    },
    data() {
        return {
            plano: {},
            editmode: false,
            enquetes: {
                data: []
            },
            inputs: [],
            form: new Form(
                new Enquete()
            ),
            statusEnquete: [
                {value: 'Y', text: 'Ativa'},
                {value: 'N', text: 'Inativa'}
            ],
            tipoEnquete: [
                {value: 'Text', text: 'Text Area'},
                {value: 'Radio', text: 'Radio Button'},
                {value: 'Check', text: 'Check Box'},
            ],
        }
    },
    methods: {
        selectTipo(){
            this.inputs.length <= 0 ? this.add(0) : null;
        },
        add(index) {
            this.inputs.push({name: '', id: null});
        },
        remove(index) {
            this.inputs.splice(index, 1);
        },
        getResults(page = 1) {
            this.$Progress.start();
            axios.get(`/api/enquete?page=${page}`).then(({data}) => {
                this.enquetes = data.data;

                this.statusRegistros();
            });
            this.$Progress.finish();
        },
        editModal(enquete) {
            this.editmode = true;
            this.form.reset();
            $('#addNew').modal('show');

            const localInput = [];
            Object.entries(enquete.inputs).forEach(([key, value]) => {
                localInput.push({name: value, id: key});
            });

            this.inputs = localInput;
            this.form.fill(enquete);
        },
        newModal() {
            this.editmode = false;
            this.inputs = [{name: '', id: null}];
            this.form.reset();
            $('#addNew').modal('show');
        },
        createEnquete() {
            this.$Progress.start();
            this.form.inputs = this.inputs;

            this.form.post(`/api/enquete`)
                .then((data) => {
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    this.$Progress.finish();
                    this.getResults();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
        updateEnquete() {
            this.$Progress.start();
            this.form.inputs = this.inputs;

            this.form.put(`/api/enquete/${this.form.id_enquete}`)
                .then((response) => {
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();

                    this.getResults();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

        },
        deleteEnquete(id) {
            Swal.fire({
                title: 'Tem certeza?',
                text: "Você não poderá restaurar depios!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Sim, excluir!'
            }).then((result) => {
                if (result.value) {
                    this.form.delete('api/enquete/' + id).then(() => {
                        Toast.fire({
                            icon: 'succes',
                            title: 'E enquete foi excluída.'
                        });
                        this.getResults();
                    }).catch((data) => {
                        Swal.fire("Falha!", data.message, "warning");
                    });
                }
            })
        },
        mudaStatus(id) {
            this.$Progress.start();

            this.form.put(`api/enqueteStatus/${id}`)
                .then((data) => {
                    Toast.fire({
                        icon: 'error',
                        title: data.data.message
                    });
                    this.$Progress.finish();
                    this.getResults();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },

    },
    mounted() {
    },
    created() {
        this.$Progress.start();
        this.getResults();
        this.$Progress.finish();

        PLANO.then((result) => {
            this.plano = result.data;
        })
    },
    filters: {
        truncate: function (text, length, suffix) {
            return text.substring(0, length) + suffix;
        },
    },
    computed: {
        statusRegistros() {
            return (this.plano.enquetes >= this.enquetes.total + 1);
        }
    },

}
</script>
