<template>
    <div class="row">
        <div class="col-lg-4 col-6">
            <div class="small-box bg-info">
                <load-spin-card v-bind:status="spinner"></load-spin-card>
                <div class="inner">
                    <h3> {{ aluno.total }} / {{ plano.alunos }} </h3>
                    <p> Alunos Ativos</p>
                </div>
                <div class="icon">
                    <i class="fas fa-user-plus"></i>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-6">
            <div  class="small-box bg-success">
                <load-spin-card v-bind:status="spinner"></load-spin-card>
                <div class="inner">
                    <h3> {{ aluno.total }}  </h3>
                    <p> Todos os aluno </p>
                </div>
                <div class="icon">
                    <i class="fas fa-users"></i>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-6">
            <router-link :to="`/lixeira`" class="small-box-footer">
                <div class="small-box bg-danger">
                 <load-spin-card v-bind:status="spinner"></load-spin-card>
                    <div class="inner">
                        <h3> {{ excluidos }} </h3>

                        <p> Alunos excluídos </p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-trash"></i>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import LoadSpinCard from '../../common/LoadSpinCard.vue';

export default {
name: "CardsListaAlunos",
    data() {
        return {
            aluno: {
                total: 0
            },
            plano: {
                alunos: 0
            },
            excluidos: 0,
            spinner: true,
        }
    },
    props: {
        alunos: {
            required: true
        },
        planos: {
            required: true
        }
    },
    components: {
        LoadSpinCard
    },
    methods: {
        loadAlunos(){
            axios.get("api/aluno?filter=excluidos").then(({ data }) => {this.excluidos = data.data.total});
        },
    },
    mounted() {
        this.$Progress.start();
        this.loadAlunos();
        this.$Progress.finish();
    },
    watch: {
        alunos: function(newVal, oldVal) {
            this.aluno = newVal;
            this.spinner = false;
        },
        planos: function(newVal, oldVal) {
            this.plano = newVal;
            this.spinner = false;
        }
    }
}
</script>
