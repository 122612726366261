<template>
    <section class="content">
            <div class="container-fluid">
                <div class="row">

                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Detalhes da Acemia/Unidade: {{ academia.nome }} </h3>
                                <div class="card-tools">
                                    <router-link to="/unidades" class="btn btn-sm btn-warning">
                                        <i class="fas fa-backward"></i>
                                        Voltar
                                    </router-link>
                                </div>
                            </div>
                            <div class="card-body table-responsive">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-3">

                                            <div class="card card-primary card-outline">
                                                <div class="card-body box-profile">
                                                    <div class="text-center">
                                                        <img class="profile-user-img img-fluid img-circle" :src="academia.imagem">
                                                    </div>

                                                    <h3 class="profile-username text-center"> {{ academia.nome }} </h3>

                                                    <p class="text-muted text-center"></p>

                                                    <ul class="list-group list-group-unbordered mb-3">
                                                        <li class="list-group-item">
                                                            <b>Alunos </b> <a class="float-right">{{ academia.qtd_alunos }}</a>
                                                        </li>
                                                        <li class="list-group-item">
                                                            <b>Professores</b> <a class="float-right">{{ academia.qtd_professores }}</a>
                                                        </li>
                                                        <li class="list-group-item">
                                                            <b>Agenda</b> <a class="float-right">{{ academia.qtd_agendas }}</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="card card-primary">
                                                <div class="card-header">
                                                    <h3 class="card-title">Sobre</h3>
                                                </div>
                                                <div class="card-body">
                                                    <strong><i class="fas fa-user mr-1"></i> Dono </strong>

                                                    <p v-if="academia.responsavel != null" class="text-muted"> {{ academia.responsavel }} </p>
                                                    <p v-else class="text-muted"> <span  class="badge badge-info"> Sem dados</span> </p>

                                                    <hr>

                                                    <strong><i class="fas fa-map-marker-alt mr-1"></i> Endereço</strong>

                                                    <p class="text-muted">
                                                        {{ academia.endereco }}, {{ academia.numero }} | {{ academia.bairro }} <br>
                                                        {{ academia.cidade }} {{ academia.uf }} | {{ academia.cep }}
                                                    </p>

                                                    <hr>

                                                    <strong><i class="fas fa-user-ninja mr-1"></i> Arte Marcial</strong>

                                                    <p v-if="academia.modalidades != null" class="text-muted">
                                                        <span v-for="arte in academia.modalidades" :key="arte" class="badge badge-info mr-1">{{ arte }}</span>
                                                    </p>
                                                    <p v-else class="text-muted"> <span class="badge badge-info">Sem dados</span> </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-9">
                                            <div class="card">
                                                <div class="card-header p-2">
                                                    <ul class="nav nav-pills">
                                                        <li class="nav-item"><a class="nav-link"  href="#dashboard" data-toggle="tab">Painel de Controle</a></li>
                                                        <li class="nav-item"><a class="nav-link" href="#student" data-toggle="tab"> Alunos </a></li>
                                                        <li class="nav-item"><a class="nav-link" href="#schedule" data-toggle="tab"> Agenda </a></li>
                                                        <li class="nav-item"><a class="nav-link" href="#profile" data-toggle="tab"> Perfil </a></li>
                                                        <li class="nav-item"><a class="nav-link" href="#certificate" data-toggle="tab"> Certificados </a></li>
                                                        <li class="nav-item"><a class="nav-link" href="#password" data-toggle="tab"> Senha </a></li>
                                                    </ul>
                                                </div>
                                                <div class="card-body">
                                                    <div class="tab-content">
                                                        <div class="tab-pane fade  show active" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                                                            <dashboard></dashboard>
                                                        </div>
                                                        <div class="tab-pane fade" id="student" role="tabpanel" aria-labelledby="alunos-tab">
                                                            <alunos></alunos>
                                                        </div>
                                                        <div class="tab-pane fade" id="schedule" role="tabpanel" aria-labelledby="agenda-tab">
                                                            <agenda></agenda>
                                                        </div>
                                                        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="edit-tab">
                                                            <dados :dados="academia"></dados>
                                                        </div>
                                                        <div class="tab-pane fade" id="certificate" role="tabpanel" aria-labelledby="certificado-tab">
                                                            <certificado></certificado>
                                                        </div>
                                                        <div class="tab-pane fade" id="password" role="tabpanel" aria-labelledby="password-tab">
                                                            <senha
                                                            :email="academia.email"
                                                            :endpoint="'academia'"
                                                            ></senha>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
    </section>
</template>


<script>
import Alunos from './_resources/Alunos.vue';
import Agenda from './_resources/Agenda.vue';
import Dados from './_resources/Dados.vue';
import Certificado from './_resources/Certificado.vue';
import Senha from './_resources/Senha.vue';
// import Dashboard from './_resources/Dashboard.vue';
import Dashboard from '../Dashboard.vue';
export default {
    components: {
        Dashboard,
        Alunos,
        Agenda,
        Dados,
        Certificado,
        Senha,
    },
    data() {
        return {
            academia:{},
        }
    },
    methods: {
        async loadAcademia() {
            this.academia = await axios.get(`/api/academia/${this.$route.params.id}`).then((post) => {
                return post.data.data;
            });
        },
    },
    created() {
        this.$Progress.start();
        this.loadAcademia();
        this.$Progress.finish();
    },
}
</script>
