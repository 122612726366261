<template>

    <div class="col-md-6 col-sm-6 col-12">

        <div class="card">
            <div class="card-header border-transparent">
                <h3 class="card-title">Próximas Graduações</h3>

                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                    </button>
                    <button type="button" class="btn btn-tool" data-card-widget="remove">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="table-responsive">
                    <table class="table m-0">
                        <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Aulas</th>
                            <th>Graduação</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="graduacao in graduacoes" :key="graduacao.id">
                                <td><a href="#"> {{ graduacao.name }} </a></td>
                                <td> {{ graduacao.presencas }} </td>
                                <td> <span v-html="graduacao.graduacao"> </span> </td>
                                <td>
                                    <button v-if="graduacao.faltam <= 0" class="btn btn-outline-success btn-xs">
                                        Pode Graduar
                                    </button>
                                    <button v-else class="btn btn-outline-danger btn-xs">
                                        Faltam {{ graduacao.faltam }} aulas
                                    </button>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer clearfix">
                <a class="btn btn-sm btn-info float-left">Novo Aluno</a>
                <a class="btn btn-sm btn-secondary float-right">Todos Alunos</a>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "PoxGraduacoes",
            data(){
                return{
                    graduacoes:[]
                }
            },
            methods:{
                loadGraducoes(){
                    var route = '/api/academiaProxGraduacoes';
                    if(this.$route.params.id){
                        route = `/api/academiaProxGraduacoes/${this.$route.params.id}`;
                    }
                    this.$Progress.start();
                        axios.get(route).then(({ data }) => {
                            this.graduacoes = data.data;
                        });
                    this.$Progress.finish();
                },
                createAluno(){
                    console.log('Adicionado novo aluno');
                },

            },
            created(){
                this.loadGraducoes();
                console.log('Prox graduation componente created')
            },
            mounted(){
                console.log('Prox graduation componente mounted')
            },

}
</script>

<style>

</style>
