<template>
  <div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="card-title">
                    <!-- {{ __('adminlte::dashboard.title_daily') }} -->
                    Dados Diarios
                </h5>

                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                    </button>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <div class="row">
                    <div class="col-md-8">
                        <p class="text-center">
                            <strong>
                                {{ data_inicio }} - {{ data_final }}
                                <!-- Precensas entre: 01/08/2021 - 03/12/2021 -->
                                <!-- {{ __('adminlte::dashboard.daily_period', ['start' => '01/' . date('m/Y'), 'end' => date('t/m/Y')]) }} -->
                            </strong>
                        </p>

                        <div class="chart">
                            <div class="chartjs-size-monitor">
                                <div class="chartjs-size-monitor-expand">
                                    <div class=""></div>
                                </div>
                                <div class="chartjs-size-monitor-shrink">
                                    <div class=""></div>
                                </div>
                            </div>
                            <LineGraficos :chart-data="datacollection" :height="150"></LineGraficos>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <p class="text-center"> <strong> Consumo de Recursos </strong> </p>

                        <div class="progress-group">
                            Alunos
                            <span class="float-right"> <b> {{ recursos.alunos.consumido }} </b> /{{ recursos.alunos.disponivel }} </span>
                            <div class="progress progress-sm">
                                <div class="progress-bar bg-gradient-blue" :style="`width: ${recursos.alunos.porcentagem}%`"></div>
                            </div>
                        </div>

                        <div class="progress-group">
                            Professores
                            <span class="float-right"> <b> {{ recursos.professores.consumido }} </b> /{{ recursos.professores.disponivel }} </span>
                            <div class="progress progress-sm">
                                <div class="progress-bar bg-gradient-green" :style="`width: ${recursos.professores.porcentagem}%`"></div>
                            </div>
                        </div>

                        <div class="progress-group">
                           Agendas
                           <span class="float-right"> <b> {{ recursos.agendas.consumido }} </b>/{{ recursos.agendas.disponivel }} </span>
                            <div class="progress progress-sm">
                                <div class="progress-bar bg-gradient-orange" :style="`width: ${recursos.agendas.porcentagem}%`"></div>
                            </div>
                        </div>

                        <div class="progress-group">
                            Graduaçoes
                            <span class="float-right"><b>  {{ recursos.graduacoes }} </b></span>
                            <div class="progress progress-sm">
                                <div class="progress-bar bg-gradient-red" style="width: 100%"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-3 col-6">
                        <div class="description-block border-right">
                            <span :class="[dados_recursos.inativos < dados_recursos.inativos_anterior ? 'text-danger' : 'text-success' ]" class="description-percentage">
                                <i class="fas fa-thumbs-up"></i>
                                <i :class="dados_recursos.inativos < dados_recursos.inativos_anterior ? 'fa-caret-down' : 'fa-caret-up'" class="fas">
                                </i> {{ dados_recursos.inativos_porcentagem }}%
                            </span>
                            <h5 class="description-header"> {{ dados_recursos.ativos }} </h5>
                            <span class="description-text"> ALUNOS ATIVOS </span>
                        </div>
                    </div>
                    <div class="col-sm-3 col-6">
                        <div class="description-block border-right">
                            <span :class="dados_recursos.inativos < dados_recursos.inativos_anterior ? 'text-danger' : 'text-success'" class="description-percentage">
                                <i :class="dados_recursos.inativos < dados_recursos.inativos_anterior ? 'fa-caret-down' : 'fa-caret-up'" class="fas"></i>
                                {{ dados_recursos.inativos_porcentagem }}0%
                            </span>
                            <h5 class="description-header">
                                Esse mes / Ultimo Mes <br>
                                {{ dados_recursos.inativos }} / {{ dados_recursos.inativos_anterior }}
                            </h5>
                            <span class="description-text"> Alunos inativos </span>
                        </div>
                    </div>
                    <div class="col-sm-3 col-6">
                        <div class="description-block border-right">
                            <span :class="dados_recursos.novos < dados_recursos.novos_anterior ? 'text-danger' : 'text-success'" class="description-percentage">
                                <i :class="dados_recursos.novos < dados_recursos.novos_anterior ? 'fa-caret-down' : 'fa-caret-up'" class="fas"></i>
                                {{ dados_recursos.novos_porcentagem }}%
                            </span>
                            <h5 class="description-header">
                                Esse mes / Ultimo Mes <br>
                                {{ dados_recursos.novos }} / {{ dados_recursos.novos_anterior }}
                            </h5>
                            <span class="description-text">
                                Novos alunos
                            </span>
                        </div>
                    </div>
                    <div class="col-sm-3 col-6">
                        <div class="description-block">
                            <span :class="dados_recursos.atrasados < dados_recursos.atrasados_anterior ? 'text-danger' : 'text-success'" class="description-percentage ">
                                <i :class="dados_recursos.atrasados < dados_recursos.atrasados_anterior ? 'fa-caret-down' : 'fa-caret-up'" class="fas"></i>
                                {{ dados_recursos.atrasados_porcentagem }}%
                            </span>
                            <h5 class="description-header">
                                Esse mes / Ultimo Mes <br>
                                {{ dados_recursos.atrasados }} / {{ dados_recursos.atrasados_anterior }}
                            </h5>
                            <span class="description-text">
                                TAXAS MENSAIS DE ATRASO
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import moment from 'moment'
import LineGraficos from './LineGraficos.js'
export default {
    components: {
      LineGraficos
    },
    name: "DadosDiarios",
    data(){
        return{
            datacollection: null,
            recursos: {},
            grafico: [],
            data_inicio: moment().startOf('month').format('DD-MM-YYYY'),
            data_final: moment().endOf('month').format('DD-MM-YYYY'),
            dados_recursos: [],
        }
    },
    methods:{
        fillData (dados) {
            this.datacollection = {
                labels: dados.labels,
                datasets: [{
                    data: dados.data,
                }]
            }
        },
        loadRecursos(){
            var route = '/api/academiaRecursosConsumidos';
            if(this.$route.params.id){
                route = `/api/academiaRecursosConsumidos/${this.$route.params.id}`;
            }
            this.$Progress.start();
                axios.get(route).then(({ data }) => {this.recursos = data.data});
            this.$Progress.finish();
        },
        loadGraficoData(){
            var route = '/api/academiaGraficoDiario';
            if(this.$route.params.id){
                route = `/api/academiaGraficoDiario/${this.$route.params.id}`;
            }
            this.$Progress.start();
                axios.get(route).then(({ data }) => {
                    this.fillData(data.data);
                });
            this.$Progress.finish();
        },
        loadDadosRecursos(){
            var route = '/api/academiaDataRecursos';
            if(this.$route.params.id){
                route = `/api/academiaDataRecursos/${this.$route.params.id}`;
            }
            this.$Progress.start();
                axios.get(route).then(({ data }) => {
                    this.dados_recursos = data.data;
                });
            this.$Progress.finish();
        },
    },
    created(){
        this.loadRecursos();
        this.loadGraficoData();
        this.loadDadosRecursos();
        console.log('Dados diarios componente created')
    },
    mounted(){
        console.log('Dados diarios componente mounted')
    },
}
</script>

<style>

</style>
