<!--
TODO: Máscaras nos campos: CPF/CNPJ, Telefone Internacional, CEP
TODO: Busca automática de endereço com Viacep
-->

<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">

            <div class="card" v-if="$gate.isAdmin()">
              <div class="card-header">
                <h3 class="card-title">Lista de Academias/Unidade</h3>

                <div class="card-tools">

                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      NOVA ACADEMIA/UNIDADE
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Endereco</th>
                      <th>Telefone</th>
                      <th>Responsável</th>
                      <th>Status</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="academia in academias.data" :key="academia.id">

                      <td>{{academia.nome}}
                          <span v-if="academia.matriz == 'Y'" class="badge badge-secondary">
                              Matriz
                          </span>
                      </td>
                      <td>
                          {{ academia.endereco.endereco }}, {{ academia.endereco.numero }} - {{ academia.endereco.bairro}} - {{ academia.endereco.cidade}}/{{academia.endereco.uf}}
                      </td>
                      <td>{{academia.telefone}}</td>
                      <td>{{academia.responsavel}} </td>
                      <td>
                        <span :value="academia.status" @click="mudaStatus(academia.id_academia)" class="badge "
                            v-bind:class="academia.status == 'Y' ? 'badge-success' : 'badge-danger'">
                        {{ academia.status == 'Y' ? 'Active' : 'Inactive' }}
                        </span>
                      </td>
                      <td>

                        <router-link :to="`/unidade/${academia.id_academia}/detalhes`" class="btn btn-sm btn-secondary">
                            <i class="fas fa-eye"></i> Detalhes
                        </router-link>
                        <a href="#" @click="editModal(academia)" class="btn btn-sm btn-primary" title="editar">
                            <i class="fa fa-edit"></i>
                        </a>
                        <a href="#" @click="deleteAcademia(academia.id_academia)" class="btn btn-sm btn-danger" title="excluir">
                            <i class="fa fa-trash"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="academias" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-show="!editmode">Cadastrar Nova Academia/Unidade</h5>
                        <h5 class="modal-title" v-show="editmode">Atualizar Academia/Unidade</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <!-- <form @submit.prevent="createAcademia"> -->

                    <form @submit.prevent="editmode ? updateAcademia() : createAcademia()">

                        <div class="modal-body">

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">

                                        <label>Name</label>
                                        <input v-model="form.nome" type="text" name="nome"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('nome') }">
                                        <has-error :form="form" field="nome"></has-error>

                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">

                                        <label>CNPJ</label>
                                        <input v-model="form.cnpj" type="text" name="cnpj" v-mask="'###.###.###-##'"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('cnpj') }">
                                        <has-error :form="form" field="cnpj"></has-error>

                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">

                                        <label>Responsavel</label>
                                        <input v-model="form.responsavel" type="text" name="responsavel"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('responsavel') }">
                                        <has-error :form="form" field="responsavel"></has-error>

                                    </div>
                                </div>
                            </div>

                            <div class="form-group">

                                <label> Descrição </label>
                                <input v-model="form.descricao" type="text" name="descricao"
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('descricao') }">
                                <has-error :form="form" field="descricao"></has-error>

                            </div>

                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" id="basic-tab" data-toggle="tab" href="#basic" role="tab" aria-controls="basic" aria-selected="true">
                                        Basico
                                    </a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" id="address-tab" data-toggle="tab" href="#address" role="tab" aria-controls="address" aria-selected="false">
                                        Endereço
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <div class="tab-pane fade  show active" id="basic" role="tabpanel" aria-labelledby="basic-tab">

                                    <div class="form-group">

                                            <label> Idioma </label>
                                            <select v-model="form.language" name="language" id="language"
                                                    class="form-control" :class="{ 'is-invalid': form.errors.has('language') }">
                                                <option value="pt">Portugues</option>
                                                <option value="en">Ingles</option>
                                            </select>
                                            <has-error :form="form" field="language"></has-error>

                                    </div>

                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">

                                                <label> Email </label>
                                                <input v-model="form.email" type="email" name="email"
                                                    class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                                                <has-error :form="form" field="email"></has-error>

                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">

                                                <label> Senha </label>
                                                <input v-model="form.senha" type="password" name="senha"
                                                    class="form-control" :class="{ 'is-invalid': form.errors.has('senha') }">
                                                <has-error :form="form" field="senha"></has-error>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">

                                                <label> Website </label>
                                                <input v-model="form.site" type="text" name="site"
                                                    class="form-control" :class="{ 'is-invalid': form.errors.has('site') }">
                                                <has-error :form="form" field="site"></has-error>

                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">

                                                <label> Telefone </label>
                                                <phone-mask-input
                                                v-model="form.telefone"
                                                name="telefone"
                                                autoDetectCountry
                                                wrapperClass="wrraper-example"
                                                inputClass="form-control"
                                                :class="{ 'is-invalid': form.errors.has('telefone') }"
                                                flagClass="flag-example"
                                            />
                                            <has-error :form="form" field="telefone"></has-error>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">

                                                <label>Matriz</label>
                                                <select v-model="form.matriz" name="matriz" id="matriz"
                                                        class="form-control" :class="{ 'is-invalid': form.errors.has('matriz') }">
                                                    <option value="Y">Sim</option>
                                                    <option value="N">Não</option>
                                                </select>
                                                <has-error :form="form" field="matriz"></has-error>

                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">

                                                <label>Status</label>
                                                <select v-model="form.status" name="status" id="status"
                                                        class="form-control" :class="{ 'is-invalid': form.errors.has('status') }">
                                                    <option value="Y">Ativo</option>
                                                    <option value="N">Inativo</option>
                                                </select>
                                                <has-error :form="form" field="status"></has-error>

                                            </div>
                                        </div>
                                        <div class="col">

                                            <label> Image </label>
                                            <input type="file" name="image"
                                                class="form-control" :class="{ 'is-invalid': form.errors.has('image') }"
                                                @change="updateAcademia">
                                            <has-error :form="form" field="image"></has-error>

                                        </div>
                                    </div>

                                </div>
                                <div class="tab-pane fade" id="address" role="tabpanel" aria-labelledby="address-tab">

                                    <Endereco
                                        :selectValue="form.endereco"
                                        v-on:selected="form.endereco = $event;"
                                    />

                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                            <button type="submit" class="btn btn-primary">Salvar</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>

    </div>
  </section>
</template>

<script>
import PhoneMaskInput from "vue-phone-mask-input";
import {mask} from 'vue-the-mask';
import Endereco from "../common/form/Endereco.vue";
import Academia from "../../domain/user/Academia";

export default {
    components:{
        Endereco
    },
    directives: {mask},
    data () {
        return {
            editmode: false,
            academias : {},
            form: new Form(
                new Academia()
            )
        }
    },
    methods: {
    getResults(page = 1) {
        this.$Progress.start();
        axios.get('api/academia?page=' + page).then(({ data }) => (this.academias = data.data));
        this.$Progress.finish();
    },
    updateAcademia(){
        this.$Progress.start();
        this.form.put('api/academia/'+this.form.id_academia)
        .then((response) => {
            $('#addNew').modal('hide');
            Toast.fire({
                icon: 'success',
                title: response.data.message
            });
            this.$Progress.finish();
            this.loadAcademias();
        })
        .catch(() => {
            this.$Progress.fail();
        });
    },
    editModal(academia){
        this.editmode = true;
        this.form.reset();
        $('#addNew').modal('show');
        this.form.fill(academia);
        this.form.senha = null;
    },
    newModal(){
        this.editmode = false;
        this.form.reset();
        $('#addNew').modal('show');
    },
    deleteAcademia(id){
        Swal.fire({
            title: 'Tem certeza?',
            text: "Você não poderá restaurar depois!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Sim, excluir!'
        }).then((result) => {
            if (result.value) {
                this.form.delete('api/academia/'+id).then(()=>{
                    Swal.fire(
                    'Excluído!',
                    'A academia/unidade foi excluída.',
                    'success'
                    );
                this.loadAcademias();
                }).catch((data)=> {
                    Swal.fire("Falha!", data.message, "warning");
                });
            }
        })
    },
    loadAcademias(){
        this.$Progress.start();
        axios.get("api/academia").then(({ data }) => {
            this.academias = data.data;
        });
        this.$Progress.finish();
    },
    createAcademia(){
        this.form.post('api/academia')
        .then((response)=>{
            $('#addNew').modal('hide');
            Toast.fire({
                icon: 'success',
                title: response.data.message
            });
            this.$Progress.finish();
            this.loadAcademias();
        })
        .catch(()=>{
            Toast.fire({
                icon: 'error',
                title: 'Some error occured! Please try again'
            });
        })
    },
    mudaStatus(id) {
        this.$Progress.start();
        this.form.put(`api/academiaStatus/${id}`)
        .then((data) => {
            if (!data.data.success) {
                Toast.fire({
                    icon: 'error',
                    title: 'Some error occured! Please try again'
                });
                this.$Progress.failed();
                return;
            }
            Toast.fire({
                icon: 'success',
                title: data.data.message
            });
            this.$Progress.finish();
            this.loadAcademias();
        })
        .catch(() => {
            Toast.fire({
                icon: 'error',
                title: 'Some error occured! Please try again'
            });
        })
    },

    },
    mounted() {
        console.log('User Component mounted.')
    },
    created() {
        this.$Progress.start();
        this.loadAcademias();
        this.$Progress.finish();
    }
}
</script>
