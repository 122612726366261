export default class Categoria {
    constructor(id = '',
        nome = '',
        tipo = '',
        categoria_id = '',
        subcategoria = ''
    ) {
        this.id = id;
        this.nome = nome;
        this.tipo = tipo;
        this.categoria_id = categoria_id;
        this.subcategoria = subcategoria;
    }
}
