export default class Enquete {
    constructor(    id = '',
                    id_enquete = '',
                    pergunta = '',
                    status = '',
                    tipo = '',
                    inputs = []
    ) {
        this.id = id;
        this.id_enquete = id_enquete;
        this.pergunta = pergunta;
        this.status = status;
        this.tipo = tipo;
        this.inputs = inputs;
    }
}
