<template>
<div>

    <div class="card card-secondary card-tabs">
        <div class="card-header p-0 pt-1">
            <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                <li v-for="arte in modalidades" :key="arte.id" class="nav-item">
                    <a class="nav-link "
                        id="custom-tabs-one-home-tab"
                        data-toggle="pill"
                        :href="'#'+arte.table"
                        role="tab"
                        aria-controls="custom-tabs-one-home"
                        aria-selected=""
                        @click="getPrecensas(arte.id, arte.nome)">
                        {{ arte.nome }}
                    </a>
                </li>
                <span v-if="modalidades.length == 0" > </span>
            </ul>
        </div>
        <div class="card-body">
            <div class="tab-content" id="custom-tabs-one-tabContent">
                    <div v-for="arte in modalidades" :key="arte.id"
                        class="tab-pane fade"
                        :id="arte.table"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-one-home-tab">

                         <div class="text-right">
                            <button class="btn btn-outline-primary" @click="addPrecensa(arte.id)">
                                <i class="fas fa-check"></i>
                                Nova precensas  {{ arte.nome }}
                            </button>
                            <!-- <button class="btn btn-outline-warning" @click="newModal(1,arte.id,arte.table)">
                                <i class="fas fa-trophy"></i>
                                Graduar {{ arte.nome }}
                            </button> -->
                            <graduar-button
                                :id_aluno="$route.params.id"
                                :id_arte="arte.id"
                                v-on:open="modal = $event"
                            >
                                {{arte.nome}}
                            </graduar-button>
                        </div>



                        <template v-if="precensas.total == 0">
                            <p>Nenhuma Presença</p>
                        </template>

                        <template v-else>
                        <div class="timeline timeline-inverse">

                            <div class="time-label">
                                <span class="bg-danger">
                                    12/12/2012
                                </span>
                            </div>


                            <div v-for="precensa in precensas.data" :key="precensa.id_graduacao">

                                    <template  v-if="precensa.cor_grau == 'medalha'">
                                            <i  class="fas fa-medal bg-warning"></i>
                                            <div class="timeline-item">
                                                <span class="time">
                                                    <i class="far fa-calendar"></i> {{ precensa.data_cadastro | moment("DD/MM/YYYY hh:mm")}}
                                                    <button class="btn btn-danger btn-sm"
                                                    @click="removePresenca(precensa.id_graduacao, arte.id)">
                                                        <i class="fas fa-trash"></i>
                                                    </button>
                                                </span>

                                                <h3 class="timeline-header border-0"><strong>
                                                    {{ precensa.observacoes }}
                                                </strong></h3>
                                            </div>
                                    </template>
                                    <template v-else>
                                        <template v-if="arte.id != 1">
                                            <template v-if="precensa.linha_faixa != 'l_faixa'">
                                                <i class="fas fa-envelope bg-primary"></i>
                                                <div  class="timeline-item">
                                                    <span class="time">
                                                        <i class="far fa-calendar"></i>
                                                            {{ precensa.data_cadastro | moment("DD/MM/YYYY hh:mm")}}
                                                        <button class="btn btn-danger btn-sm" @click="removePresenca(precensa.id_graduacao, arte.id)">
                                                            <i class="fas fa-trash"></i>
                                                        </button>
                                                    </span>
                                                    <h3 class="timeline-header">
                                                        <strong>
                                                            <!-- {{ __('adminlte::adminlte.students_graduation_others', ['graduation' => strtoupper(str_replace('_', ' ', $data->linha_faixa))]) }} -->
                                                            O aluno recebeu {{ precensa.linha_faixa }} no dia
                                                        </strong>
                                                    </h3>
                                                    <div class="timeline-body">
                                                        <div v-if="precensa.cores" class='graduacao'>
                                                            <div :style="{ backgroundColor: precensa.cores.c1}" class='cor'></div>
                                                            <div :style="{ backgroundColor: precensa.cores.c2}" class='cor text-center'> {{ arte.nome }} </div>
                                                        </div>
                                                            {{ precensa.observacoes }}
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <i class="fas fa-rocket bg-info"></i>
                                                <div class="timeline-item">
                                                    <span class="time">
                                                        <i class="far fa-calendar"></i> {{ precensa.data_cadastro | moment("DD/MM/YYYY hh:mm")}}
                                                        <button class="btn btn-danger btn-sm" @click="removePresenca(precensa.id_graduacao, arte.id)">
                                                            <i class="fas fa-trash"></i>
                                                        </button>
                                                    </span>
                                                    <h3 class="timeline-header border-0"><strong> Nova presenca no dia</strong></h3>
                                                </div>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <template v-if="precensa.faixa || precensa.grau > 0">

                                                    <i class="fas fa-envelope bg-primary"></i>
                                                    <div class="timeline-item">
                                                        <span class="time">
                                                            <i class="far fa-calendar"></i> {{ precensa.data_cadastro | moment("DD/MM/YYYY hh:mm")}}
                                                            <button class="btn btn-danger btn-sm" @click="removePresenca(precensa.id_graduacao, arte.id)">
                                                                <i class="fas fa-trash"></i>
                                                            </button>
                                                        </span>
                                                        <h3 class="timeline-header">
                                                            <strong>
                                                                O aluno recebeu () no dia
                                                                <!-- {{ __('adminlte::adminlte.students_graduation_bjj', [
                                                                                'belt' => strtoupper($data->faixa),
                                                                                'grade' => $data->grau,
                                                                                'line' => $data->linha_faixa != 'l_faixa' ? $data->linha_faixa : '',
                                                                                ]) }} -->
                                                                </strong>
                                                        </h3>
                                                        <div class="timeline-body">

                                                            <div class='faixa'>
                                                                <div :class="precensa.faixa == 'preta ponta' ? 'vermelha ponta' : 'preta ponta'">
                                                                    <template v-if="precensa.grau > 0">
                                                                        <template v-for="item in precensa.grau">
                                                                            <div class='grau branco'></div>
                                                                        </template>
                                                                    </template>
                                                                </div>
                                                            </div>
                                                                {{ precensa.observacoes }}
                                                            <br>
                                                        </div>
                                                    </div>

                                            </template>
                                            <template v-else>
                                                <i class="fas fa-rocket bg-info"></i>
                                                <div class="timeline-item">
                                                    <span class="time">
                                                        <i class="far fa-calendar"></i> {{ precensa.data_cadastro | moment("DD/MM/YYYY hh:mm")}}
                                                        <button class="btn btn-danger btn-sm" @click="removePresenca(precensa.id_graduacao, arte.id)">
                                                            <i class="fas fa-trash"></i>
                                                        </button>
                                                    </span>
                                                    <h3 class="timeline-header border-0"><strong> Nova presencas no dia </strong></h3>
                                                </div>
                                            </template>
                                        </template>

                                    </template>

                            </div>

                            <div>
                                <i class="far fa-clock bg-gray"></i>
                            </div>
                        </div>
                        <!-- <pagination :data="precensas" @pagination-change-page="getResults(arte.id)"></pagination> -->
                        </template>
                    </div>
                        <ModalGraduar
                            :openModal="modal"
                            v-on:close="modal = $event; $event.reload != null ? getPrecensas($event.reload) : null"
                        >
                        de {{arte_nome}}
                        </ModalGraduar>
            </div>
        </div>
    </div>

</div>

</template>

<script>
import moment from 'moment';
import GraduarButton from "../../../common/GraduarButton.vue";
import ModalGraduar from "../../../common/ModalGraduar.vue";

export default {
    name: "Historicos",
    components:{
        GraduarButton,
        ModalGraduar,
    },
    data(){
        return {
            arte_nome: '',
            modal: {status: 0, id_arte: ''},
            precensas: [],
            graduacoes: [],
            modalidades:[],
        }
    },
    methods:{
        loadModalidadesAluno(){
            this.$Progress.start();
                axios.get(`/api/aluno/modalidades/${this.$route.params.id}`).then(({ data }) => {this.modalidades = data.data});
            this.$Progress.finish();
        },
        getResults(id,page = 1) {
            this.$Progress.start();
                axios.get(`/api/aluno/presencas/${this.$route.params.id}/`+id +'/page=' + page).then(({ data }) => (
                    this.precensas = data.data
                ));
            this.$Progress.finish();
        },
        addPrecensa(id_arte){

            var form = new Form({ id_arte: id_arte });

            form.post(`/api/aluno/add_precensa/${this.$route.params.id}`)
              .then((response)=>{
                if(response.data.status == 'success'){
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.getPrecensas(id_arte);
                }else{
                    Toast.fire({
                        icon: 'warning',
                        title: response.data.message
                    });
                }
              })
              .catch(()=>{
                  Toast.fire({
                      icon: 'error',
                      title: 'Ocorreu um erro! Por favor, tente novamente.'
                  });
              })
        },
        removePresenca(id_graduacao, id_arte){

            axios.post(`/api/aluno/remove_presenca/${id_graduacao}`)
                .then((response)=>{
                    this.getPrecensas(id_arte);
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
                .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })

        },
        getPrecensas($id, arte_nome = null){
            this.$Progress.start();
            axios.get(`/api/aluno/presencas/${this.$route.params.id}/`+ $id).then(({ data }) => {
                this.precensas = data.data
                });
            this.$Progress.finish();
            this.arte_nome = arte_nome;
        }
    },
     mounted() {
        console.log('Historico component mounted.')
    },
    created() {
       console.log('created componenten method');
       this.loadModalidadesAluno();
    },
}
</script>

<style scoped>

</style>
