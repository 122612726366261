<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Produtos e Serviços</h3>
                            <div class="card-tools">
                                <button type="button" class="btn btn-sm btn-primary" @click="newModal" title="cadastrar novo">
                                    <i class="fa fa-plus-square"></i>
                                    NOVO PRODUTO
                                </button>
                            </div>
                        </div>
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Nome</th>
                                    <th>Preço Venda</th>
                                    <th>Foto</th>
                                    <th>Ação</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="produto in produtos.data" :key="produto.id">
                                    <td>{{ produto.codigo }}</td>
                                    <td>{{ produto.nome }}</td>
                                    <td>{{ produto.preco_venda | currency  }}</td>
                                    <td>
                                        <img v-if="produto.image" :src="`/img/produtos/${produto.image}`" :alt="produto.nome" class="img-circle img-size-50 img-thumbnail">
                                    </td>
                                    <td>
                                        <a href="#" @click="editModal(produto)" class="btn btn-sm btn-primary" title="editar">
                                            <i class="fa fa-edit"></i>
                                        </a>
                                        <a href="#" @click="deleteProduto(produto.id)" class="btn btn-sm btn-danger" title="excluir">
                                            <i class="fa fa-trash"></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <no-records v-if="produtos.data.length <= 0"></no-records>
                            <pagination :data="produtos" @pagination-change-page="getResults"></pagination>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" v-show="!editmode">Criar Novo Produto</h5>
                            <h5 class="modal-title" v-show="editmode">Atualizar Informações do Produto</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <form @submit.prevent="editmode ? updateProduto() : createProduto()">

                            <div class="modal-body">

                                <div class="row">
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="codigo">Código</label>
                                            <input v-model="form.codigo"
                                                   type="text"
                                                   name="codigo"
                                                   id="codigo"
                                                   class="form-control"
                                                   :class="{ 'is-invalid': form.errors.has('codigo') }"
                                            >
                                            <has-error :form="form" field="codigo"></has-error>
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="form-group">
                                            <label for="nome">Nome</label>
                                            <input v-model="form.nome"
                                                   type="text"
                                                   name="nome"
                                                   id="nome"
                                                   class="form-control"
                                                   :class="{ 'is-invalid': form.errors.has('nome') }"
                                            >
                                            <has-error :form="form" field="nome"></has-error>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="preco_custo">Preço de Custo</label>
                                            <money
                                                id="preco_custo"
                                                v-model="form.preco_custo"
                                                v-bind="form.preco_custo"
                                                :class="{ 'is-invalid': form.errors.has('preco_custo') }"
                                                class="form-control"
                                                name="preco_custo"
                                            ></money>
                                            <has-error :form="form" field="preco_custo"></has-error>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="nome">Preço de Venda</label>
                                            <money
                                                id="preco_venda"
                                                v-model="form.preco_venda"
                                                v-bind="form.preco_venda"
                                                :class="{ 'is-invalid': form.errors.has('preco_venda') }"
                                                class="form-control"
                                                name="preco_venda"
                                            ></money>
                                            <has-error :form="form" field="preco_venda"></has-error>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="qty">Quantidade</label>
                                            <input v-model="form.qty"
                                                   type="number"
                                                   name="qty"
                                                   id="qty"
                                                   class="form-control"
                                                   :class="{ 'is-invalid': form.errors.has('qty') }"
                                            >
                                            <has-error :form="form" field="qty"></has-error>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="controlar_estoque">Controlar Estoque?</label>
                                            <select name="controlar_estoque"
                                                    v-model="form.controlar_estoque"
                                                    id="controlar_estoque"
                                                    class="form-control"
                                                    :class="{ 'is-invalid': form.errors.has('controlar_estoque') }"
                                            >
                                                <option value="0">Sim</option>
                                                <option value="1">Não</option>
                                            </select>
                                            <has-error :form="form" field="controlar_estoque"></has-error>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="body">Descrição</label>
                                    <vue-editor v-model="form.body" :class="{ 'is-invalid': form.errors.has('body') }" id="body"/>
                                    <has-error :form="form" field="body"></has-error>
                                </div>

                                <div class="form-group">
                                    <label>Foto do Produto</label>
                                    <input type="file" name="image"
                                           class="form-control"
                                           :class="{ 'is-invalid': form.errors.has('image') }"
                                           @change="updatePhoto"
                                    >
                                    <has-error :form="form" field="image"></has-error>
                                </div>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                <button type="submit" class="btn btn-success">Salvar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import Produto from "../../../domain/financeiro/Produto";

export default {
    components: {
    },
    data() {
        return {
            editmode: false,
            produtos: {
                data: []
            },
            form: new Form(
                new Produto()
            ),
        }
    },
    methods: {
        loadCategorias() {
            this.$Progress.start();
            axios.get(`/api/financeiro/produto`).then(({data}) => (this.produtos = data.data));
            this.$Progress.finish();
        },
        getResults(page = 1) {
            this.$Progress.start();
            axios.get(`/api/financeiro/produto?page=${page}`).then(({data}) => (this.produtos = data.data));
            this.$Progress.finish();
        },
        createProduto() {
            this.form.post('/api/financeiro/produto')
                .then((response) => {
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();
                    this.loadCategorias();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
        updateProduto() {
            this.$Progress.start();
            this.form.put(`/api/financeiro/produto/${this.form.id}`)
                .then((response) => {
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();
                    this.loadCategorias();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
        },
        editModal(produto) {
            console.log(produto);
            this.editmode = true;
            this.form.reset();
            $('#addNew').modal('show');
            this.form.fill(produto);
        },
        newModal() {
            this.editmode = false;
            this.form.reset();
            $('#addNew').modal('show');
        },
        deleteProduto(id) {
            Swal.fire({
                title: 'Tem certeza?',
                text: "Você não poderá restaurar depois!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Sim, excluir!'
            }).then((result) => {
                if (result.value) {
                    this.form.delete(`/api/financeiro/produto/${id}`).then(() => {
                        Toast.fire(
                            'Excluído!',
                            'A produto foi excluído.',
                            'success'
                        );
                        this.loadCategorias();
                    }).catch((data) => {
                        Swal.fire("Erro!", data.message, "error");
                    });
                }
            })
        },

        updatePhoto(e){
            let file = e.target.files[0];
            let reader = new FileReader();
            let limit = 1024 * 1024 * 2;
            if(file['size'] > limit){
                swal({
                    type: 'error',
                    title: 'Oops...',
                    text: 'Sua foto é muito grande! Tente redimensionar ela.',
                })
                return false;
            }
            reader.onloadend = (file) => {
                this.form.image = reader.result;
            }
            reader.readAsDataURL(file);
        },
    },
    mounted() {

    },
    created() {
        this.$Progress.start();
        this.loadCategorias();
        this.$Progress.finish();
    }
}
</script>
