<template>
<div class="col">
        <div class="row">
            <div class="col">
                <div class="card bg-light text-center">
                    <h5 class="card-header ">Presenças em aulas</h5>
                    <div class="card-body">
                        <p class="card-text ">
                           <span v-if="presencas != 0">
                               {{ presencas }} presenças
                           </span>
                           <span v-else> O aluno ainda não possu nenhuma presença</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card bg-light text-center">
                    <h5 class="card-header ">
                        Modalidades do aluno</h5>
                    <div class="card-body">
                        <p class="card-text ">

                            <span v-for="faixa in faixas" v-html="faixa"></span>

                            <span v-if="faixas.length == 0" class="badge badge-info"> Sem dados</span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="col">

                <div class="card bg-light">
                    <h5 class="card-header"> Medalhas conquistadas</h5>
                    <div class="card-body">
                        <p class="card-text ">
                            <span v-for="medalha in medalhas">
                                {{ medalha.observacoes }}
                            </span>

                            <span v-if="data.medalhas.length == 0" class="badge badge-info"> Sem dados</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <form-medalhas></form-medalhas>
            </div>
        </div>
        <div class="row">
            <div class="col-12">

                <div class="card bg-light">
                    <h5 class="card-header ">Adicionar Modalidade</h5>
                        <div class="card-body ">

                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                   <SelectGraduacao
                                    :aluno_martial_arts="modalidades_aluno"
                                    v-on:selected="
                                        form.martial_arts = $event.martial_arts;
                                        form.presencas = $event.presencas;
                                        form.graduacao = $event.graduacao;
                                        "
                                />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <button @click="addModalidade()" type="submit" class="btn btn-success float-right">
                                Salvar
                                </button>
                            </div>
                        </div>
                        </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import SelectGraduacao from "../../common/SelectGraduacao.vue";
import FormMedalhas from './FormMedalhas.vue';
export default {
  components: {
        FormMedalhas,
        SelectGraduacao,
    },
    name: "Dashboard",
    props: {
        modalidades_aluno: {type: Array},
        faixas: {type: Array},
        medalhas: {type: Array},
        presencas: {type: Number},
    },
    data(){
        return {
            form: new Form({
                martial_arts: [],
                graduacao: [],
                presencas: []
            }),
            martial_arts: [],
            modalidades: [],
            options: [],
            data: {
                presencas: 0,
                modalidades: [
                    {nome: 'nome', faixa: 'faixa'}
                ],
                medalhas: [{
                    icone: 'icon',
                    descricao: 'descricao',
                    data: 'data',
                    modalidade: 'modalidade',
                }],
            }
        }
    },
    methods:{
        addModalidade(){
            this.form.post(`/api/aluno/add_modalidades/${this.$route.params.id}`)
            .then((response)=>{
                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                });
                this.$Progress.finish();
            })
            .catch(()=>{
                Toast.fire({
                    icon: 'error',
                    title: 'Ocorreu um erro! Por favor, tente novamente.'
                });
            })
        },
    },
    mounted(){},
    created(){
        console.log('Dashboard componente created');
    }
}
</script>

<style scoped>

</style>
