<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Detalhes do Post: {{ post.titulo }}</h3>
                            <div class="card-tools">
                                <router-link to="/posts" class="btn btn-sm btn-warning">
                                    <i class="fas fa-backward"></i>
                                    Voltar
                                </router-link>
                            </div>
                        </div>
                        <div class="card-body table-responsive p-4">

                            <div class="row">
                                <div class="col-12 col-md-12 col-lg-8 order-2 order-md-1">
                                    <div class="row">
                                        <div class="col-12 col-sm-4">
                                            <div class="info-box bg-light">
                                                <div class="info-box-content">
                                                    <span class="info-box-text text-center text-muted">Comentários</span>
                                                    <span class="info-box-number text-center text-muted mb-0"> {{ post.qtd_comentarios }} </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-4">
                                            <div class="info-box bg-light">
                                                <div class="info-box-content">
                                                    <span class="info-box-text text-center text-muted"> Views</span>
                                                    <span class="info-box-number text-center text-muted mb-0"> {{ post.visitas }} </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-4">
                                            <div class="info-box bg-light">
                                                <div class="info-box-content">
                                                    <span class="info-box-text text-center text-muted"> Created At</span>
                                                    <span class="info-box-number text-center text-muted mb-0"> {{ post.created_at }} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <h4> Body </h4>
                                            <div class="post clearfix">
                                                <div v-html="post.texto"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 col-lg-4 order-1 order-md-2">
                                    <div class="text-muted">
                                        <p class="text-sm"> Author
                                            <b class="d-block"> {{ post.autor }} </b>
                                        </p>
                                        <p class="text-sm"> Imagem<br>
                                            <img v-if="post.image" :src="`/img/posts/${post.image}`" :alt="post.titulo" class="img-circle img-size-50 img-thumbnail">
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <section  class="col-12 ui-sortable">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card card-primary">
                                            <div class="card-header">
                                                <h3 class="card-title">Comentarios do Post</h3>
                                            </div>
                                            <div class="card-body">
                                                <div v-for="comentario in comentarios.data" :key="comentario.id" class="direct-chat-msg">
                                                    <div class="direct-chat-infos clearfix">
                                                        <span class="direct-chat-name float-left"> {{ comentario.name }}</span>
                                                        <span class="float-right">
                                                            <div class="card-tools">
                                                                <button type="button" class="btn btn-xs btn-danger rm-comment" @click="deleteComentario(comentario.id)">
                                                                    <i class="fas fa-trash"></i>
                                                                </button>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <img class="direct-chat-img" :src="`img/profile/${comentario.photo}`" alt="Foto do aluno">
                                                    <div class="direct-chat-text">
                                                        {{ comentario.comment }}
                                                    </div>
                                                    <hr>
                                                </div>

                                                <no-records v-if="comentarios.data.length <= 0"></no-records>
                                            </div>
                                            <div class="card-footer">
                                                <pagination :data="comentarios" @pagination-change-page="getResults"></pagination>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            post:{
                titulo: '',
                qtd_comentarios: '',
                visitas: '',
                created_at: '',
                texto: '',
                autor: '',
                image: '',
            },
            comentarios:{},
        }
    },
    methods: {
        async loadPost() {
            this.post = await axios.get(`/api/post/${this.$route.params.id}`).then((post) => {
                this.loadComentarios(post.data.data.id);
                return post.data.data;
            });
        },
        async loadComentarios(id){
            this.comentarios = await axios.get(`/api/postComentarios/${id}`).then((data) => {
                return data.data.data
            });
        },
        getResults(page = 1) {
            this.$Progress.start();
            axios.get(`/api/postComentarios/${id}?page=${page}`).then(({data}) => (this.comentarios = data.data.data));
            this.$Progress.finish();
        },
        deleteComentario(id){
            Swal.fire({
                title: 'Tem certeza?',
                text: "Você não poderá restaurar depois!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Sim, excluir!'
            }).then((result) => {
                if (result.value) {
                    axios.delete(`/api/postComentario/${id}`).then(() => {
                        Toast.fire(
                            'Excluído!',
                            'O comentário foi excluído.',
                            'success'
                        );
                        this.loadComentarios(this.post.id);
                    }).catch((data) => {
                        Swal.fire("Erro!", data.message, "warning");
                    });
                }
            })
        },
    },
    created() {
        this.$Progress.start();
        this.loadPost();
        this.$Progress.finish();
    },
}
</script>
