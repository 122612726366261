export default class Banco {
    constructor(id,
        banco_id,
        descricao,
        saldo_inicial,
        saldo_atual,
        agencia,
        agencia_digito,
        conta,
        conta_digito,
    ) {
        this.id = id;
        this.banco_id = banco_id;
        this.descricao = descricao;
        this.saldo_inicial = saldo_inicial;
        this.saldo_atual = saldo_atual;
        this.agencia = agencia;
        this.agencia_digito = agencia_digito;
        this.conta = conta;
        this.conta_digito = conta_digito;
    }
}
