<template>
    <section class="content">




        <div class="container-fluid">
            <div class="row">

                <div class="col-12">

                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Vendas</h3>

                            <div class="card-tools">
                                <button class="btn btn-sm btn-danger" title="adicionar filtros" type="button"
                                        v-on:click="aplicaFiltros">
                                    <i class="fa fa-filter"></i>
                                    FILTRAR
                                </button>
                                <button type="button" class="btn btn-sm btn-primary" @click="newModal" title="cadastrar novo">
                                    <i class="fa fa-plus-square"></i>
                                    NOVA VENDA
                                </button>
                            </div>
                        </div>
                        <div class="card-body table-responsive p-0">
                            <Filtros
                                :abrir="mostrarFiltros"
                                :vendas="true"
                                v-on:filtros="setFiltros($event)"
                            />

                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Pagador</th>
                                        <th>Data</th>
                                        <th>Total</th>
                                        <th>Status</th>
                                        <th>Método de Pagamento</th>
                                        <th>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="venda in vendas.data" :key="venda.id">
                                        <td>{{ venda.usuario_nome }}</td>
                                        <td>{{ venda.data_venda | moment("DD/MM/YYYY") }}</td>
                                        <td>{{ venda.total | currency  }}</td>
                                        <td>
                                            <span
                                                v-if="venda.status === 1"
                                                class="badge badge-success"
                                            >
                                                Finalizada
                                            </span>
                                            <span
                                                v-else-if="venda.status === 2"
                                                class="badge bg-lime"
                                            >
                                                Finalizada Boleto
                                            </span>
                                            <span
                                                v-else-if="venda.status === 3"
                                                class="badge bg-olive"
                                            >
                                                Finalizada Cartão
                                            </span>
                                            <span
                                                v-else
                                                class="badge badge-warning"
                                            >
                                                Em Aberto
                                            </span>
                                        </td>
                                        <td>{{ venda.metodo_pagamento }}</td>
                                        <td>
                                            <button
                                                type="button"
                                                @click="editModal(venda)"
                                                class="btn btn-sm"
                                                :class="!(venda.status > 0) ? 'btn-primary' : 'btn-light'"
                                                :title="!(venda.status > 0) ? 'editar' : 'visualizar'"
                                            >
                                                <i class="fa" :class="!(venda.status > 0) ? 'fa-edit' : 'fa-eye'"></i>
                                            </button>
                                            <button
                                                type="button"
                                                @click="deleteVenda(venda.id)"
                                                class="btn btn-sm btn-danger"
                                                title="excluir"
                                            >
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <no-records v-if="vendas.data.length <= 0"></no-records>
                            <pagination :data="vendas" @pagination-change-page="getResults"></pagination>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal fade" id="finalizaCartao" tabindex="-1" role="dialog" aria-labelledby="finalizaBoleto" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Finalizar com Boletos</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body">
                            <div class="row">
                                <div class="col">

                                    <b-form-group label="Forma de Pagamento" v-slot="{ ariaDescribedby }">
                                        <b-form-radio-group
                                            v-model="selected"
                                            :aria-describedby="ariaDescribedby"
                                            name="radio-sub-component"
                                        >
                                            <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="PagHiper">PagHiper</b-form-radio>
                                        </b-form-radio-group>
                                    </b-form-group>

                                    <div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <b-button variant="success">Finalizar e Gerar Boletos</b-button>
                            <b-button variant="secondary" data-dismiss="modal">Cancelar</b-button>
                        </div>
                    </div>
                </div>
            </div>

  <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Cadastrar Nova Venda</h5>
                    <h5 class="modal-title" v-show="editmode">Editar Venda</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateVenda() : createVenda()">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12" v-if="statusVenda">
                                <b-alert show variant="danger">
                                    Essa venda já foi finalizada e não pode mais ser alterada.
                                </b-alert>
                            </div>

                            <div class="col col-9">
                                <div class="form-group">
                                     <SelectPessoa
                                        :class="{ 'is-invalid': form.errors.has('usuario_id') }"
                                        :selectValue="{id: form.usuario_id, table: form.table, name: form.usuario_nome, form}"
                                        title="Recebedores"
                                        v-on:selected="eventoDeUsuario"
                                    />
                                    <has-error :form="form" field="usuario_id"></has-error>
                                 </div>
                            </div>
                            <div class="col col-3">
                                <div class="form-group">
                                    <label for="data_venda">Data</label>
                                    <b-form-datepicker
                                        id="data_venda"
                                        v-model="form.data_venda"
                                        :class="{ 'is-invalid': form.errors.has('data_venda') }"
                                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                        :disabled="statusVenda"
                                    />
                                    <has-error :form="form" field="data_venda"></has-error>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <SelectCategoria
                                        :class="{ 'is-invalid': form.errors.has('categoria_id') }"
                                        :selectValue="{value: form.categoria_id, form}"
                                        v-on:selected="form.categoria_id = $event.id;"
                                        tipo="R"
                                    />
                                    <has-error :form="form" field="categoria_id"></has-error>
                                   </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <SelectBanco
                                        :class="{ 'is-invalid': form.errors.has('banco_id') }"
                                        :selectValue="{value: form.banco_id, form}"
                                        v-on:selected="form.banco_id = $event.id;"
                                    />
                                    <has-error :form="form" field="banco_id"></has-error>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label >Informacoes Adicionais</label>
                            <textarea
                                v-model="form.informacoes_adicionais"
                                name="informacoes_adicionais"
                                class="form-control"
                                rows="3"
                                :class="{ 'is-invalid': form.errors.has('informacoes_adicionais') }"
                                :disabled="statusVenda"
                            />
                            <has-error :form="form" field="informacoes_adicionais"></has-error>
                        </div>

                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a
                                    class="nav-link active"
                                    id="items-tab"
                                    data-toggle="tab"
                                    href="#items"
                                    role="tab"
                                    aria-controls="basic"
                                    aria-selected="true"
                                >
                                    Items
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a
                                    class="nav-link"
                                    id="payments-tab"
                                    data-toggle="tab"
                                    href="#payments"
                                    role="tab"
                                    aria-controls="address"
                                    aria-selected="false"
                                >
                                    Pagamentos
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane fade  show active" id="items" role="tabpanel" aria-labelledby="items-tab">
                                <itens
                                    :selectValue="{form, items: form.produtos}"
                                    v-on:selected="eventoDeProduto"
                                />
                            </div>
                            <div class="tab-pane fade" id="payments" role="tabpanel" aria-labelledby="payments-tab">
                                <pagamentos
                                    :selectValue="{form, parcelas: form.parcelas, editmode}"
                                    v-on:selected="eventoDeParcela"
                                />
                            </div>
                        </div>

                        <hr>

                        <div class="row">
                            <!-- accepted payments column -->
                            <div class="col-6 text-center">
                                <p class="lead">Formas de Pagamento</p>
                                <ul>
                                    <li>Dinheiro</li>
                                    <li>Cartão de crédito</li>
                                    <li>Cartão de débito</li>
                                    <li>Boleto Bancário</li>
                                </ul>

                                <p class="text-muted well well-sm shadow-none mt-3">
                                    Disponibilizamos os mais populares gateways de pagamentos para facilitar e trazer resultados positivos a sua academia.
                                </p>
                            </div>
                            <!-- /.col -->
                            <div class="col-6">
                                <div class="table-responsive">
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <th style="width:30%">
                                                    Sub Total :
                                                </th>
                                                <td>
                                                    <strong id="subTotal"> {{ subtotal | currency  }} </strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Desconto
                                                </th>
                                                <td>
                                                    <div class="row">
                                                        <div class="col-5">
                                                             <select
                                                                @change="fnDesconto"
                                                                v-model="form.desconto_tipo"
                                                                name="desconto_tipo"
                                                                id="desconto_tipo"
                                                                class="form-control"
                                                                :disabled="statusVenda"
                                                             >
                                                                <option>Sem Desconto</option>
                                                                <option value="%"> % </option>
                                                                <option value="$"> $ </option>
                                                            </select>
                                                        </div>
                                                        <div class="col-7">
                                                             <div class="form-group">
                                                                 <money
                                                                     v-model="desconto"
                                                                     v-bind="fnDesconto()"
                                                                     class="form-control"
                                                                     name="desconto_valor"
                                                                     :disabled="statusVenda"
                                                                 />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Total
                                                </th>
                                                <td>
                                                    <strong> {{ total | currency  }} </strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- /.col -->
                        </div>
                        <div class="row no-print">
                            <div class="col-12">

                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                        <button
                            type="submit"
                            class="btn btn-success float-right"
                            id="saveVenda"
                            v-if="form.status <= 0"
                            @click="salvaVenda()"
                        >
                            <i class="far fa-save"></i>
                            SALVAR
                        </button>
                        <button
                            type="submit"
                            class="btn btn-outline-primary float-right"
                            v-if="editmode && !statusVenda"
                            @click="finalizaVenda()"
                        >
                            <i class="far fa-check-all"></i>
                            FINALIZAR
                        </button>
                    </div>
                  </form>
                </div>
            </div>
        </div>

        </div>
    </section>
</template>

<script>
/*
*  TODO: EDITAR VENDAS
*
* */

import moment from 'moment';
import SelectPessoa from "../financeiro/common/SelectPessoa";
import SelectBanco from "../financeiro/common/SelectBanco";
import SelectCategoria from "../financeiro/common/SelectCategoria";
import SelectFormaDePagamento from "../financeiro/common/SelectFormaDePagamento";
import Itens from './resources/Itens.vue';
import Pagamentos from './resources/Pagamentos.vue';
import Filtros from "../financeiro/common/Filtros";
import Venda from "../../domain/financeiro/Venda";

export default {
    components: {
        Filtros,
        SelectPessoa,
        SelectCategoria,
        SelectBanco,
        SelectFormaDePagamento,
        Itens,
        Pagamentos,
    },
    name: "Venda",
    data(){
        return{
            mostrarFiltros: false,
            editmode: false,
            form: new Form(
                new Venda()
            ),
            vendas: {
                data: []
            },
            subtotal: 0,
            total: 0,
            desconto: 0,
        }
    },
    methods:{
        getResults(page = 1) {
            this.$Progress.start();
            axios.get(`/api/financeiro/venda?page=${page}&${this.filtros}`)
                .then(({data}) => (this.vendas = data.data));
            this.$Progress.finish();
        },
        editModal(venda) {
            this.editmode = true;
            this.form.reset();
            $('#addNew').modal('show');
            this.form.fill(venda);
        },
        newModal() {
            this.editmode = false;
            this.form.reset();
            $('#addNew').modal('show');
        },


        createVenda(){
            this.form.post(`api/financeiro/venda`)
            .then((response)=>{
                this.getResults();
                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                });

                this.editmode = true;
                this.form.reset();
                this.form.fill(response.data.data);
                this.$Progress.finish();
            })
            .catch((error)=>{
                Toast.fire({
                    icon: 'error',
                    title: 'Ocorreu um erro! Por favor, tente novamente.'
                });
                this.$Progress.fail();
            })
        },
        updateVenda(){
            let tipo = this.form.tipo;
            if(tipo === 'salvar') {
                this.executarUpdate();
            }

            if(tipo === 'finalizar') {
                // TODO: Verificar se está configuirado pagseguro/stripe/pagarme
                if(this.form.forma_pagamento_id === 2 || this.form.forma_pagamento_id === 3 || this.form.forma_pagamento_id === 9) {
                    alert('cartão de crédito/débito/recorrente')
                }

                // TODO: verificar se está configurado pagseguro/stripe/pagarme/paghiper
                if(this.form.forma_pagamento_id === 5) {
                    // Swal.fire({
                    //     title: 'Are you sure?',
                    //     text: "You won't be able to revert this!",
                    //     icon: 'warning',
                    //     showCancelButton: true,
                    //     confirmButtonColor: '#3085d6',
                    //     cancelButtonColor: '#d33',
                    //     confirmButtonText: 'Yes, delete it!'
                    // }).then((result) => {
                    //     if (result.isConfirmed) {
                    //         Swal.fire(
                    //             'Deleted!',
                    //             'Your file has been deleted.',
                    //             'success'
                    //         )
                    //     }
                    // })

                    const { value: formValues } = Swal.fire({
                        title: 'Gerar Boletos',
                        showCancelButton: true,
                        confirmButtonText: 'Finalizar!',
                        html:
                            '<p>Selecione o gateway que irá gerar os boletos.</p>' +
                            '<label><input type="radio" name="gateway" value="paghiper" id="swal-input1" class="form-input"> PagHiper</label><br>',
                        focusConfirm: false,
                        preConfirm: () => {
                            const checkBox = document.querySelector('input[name=gateway]:checked');
                            if(!checkBox) {
                                Swal.showValidationMessage(
                                    '<i class="fa fa-info-circle"></i> Por favor selecione uma opção'
                                );
                                return;
                            }

                            this.form.gateway = checkBox.value;
                            this.executarUpdate();

                            return true;
                        }
                    })
                }
            }
        },
        salvaVenda(){
            this.form.tipo = 'salvar';
        },
        finalizaVenda(){
            this.form.tipo = 'finalizar';
        },
        executarUpdate(){
            this.$Progress.start();
            this.form.put(`api/financeiro/venda/${this.form.id}`)
                .then((response) => {
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                    this.$Progress.fail();
                });
        },
        deleteVenda(id) {
            Swal.fire({
                title: 'Tem certeza?',
                text: "Você não poderá restaurar depois!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Sim, excluir!'
            }).then((result) => {
                if (result.value) {
                    this.form.delete(`/api/financeiro/venda/${id}`).then(() => {
                        Toast.fire(
                            'Excluída!',
                            'A venda foi excluída.',
                            'success'
                        );
                        this.getResults();
                    }).catch((error) => {
                        Swal.fire(error.response.data.title, error.response.data.message, error.response.data.icon);
                    });
                }
            })
        },
        setFiltros(data){
            this.filtros = Object.keys(data)
                .map(key => `${key}=${data[key] != undefined ? data[key] : ''}`)
                .join('&');
            this.getResults(1);
        },
        aplicaFiltros() {
            this.mostrarFiltros = !this.mostrarFiltros;
        },
        eventoDeUsuario(event) {
            this.form.usuario_id = event.id;
            this.form.usuario_nome = event.name;
            this.form.table = event.table;
        },
        eventoDeProduto(event){
            this.form.produtos = event.itens;
            this.form.sub_total = event.subtotal;
            this.form.total = event.subtotal;
            this.subtotal = event.subtotal;
            this.total = event.total;
            this.fnDesconto()
        },
        eventoDeParcela(event){
            this.form.parcelas = event.parcelas;
            this.form.condicao_pagamento = event.condicao_pagamento;
            this.form.forma_pagamento_id = event.forma_pagamento_id;
            this.form.primeiro_vencimento = event.primeiro_vencimento;
        },
        fnDesconto() {
            let result = this.form.sub_total;
            if(this.form.desconto_tipo === '%'){
                result = (this.form.sub_total - (this.form.sub_total * (this.desconto / 100)).toFixed(2));
            }

            if(this.form.desconto_tipo === '$'){
                result = this.form.sub_total - this.desconto;
            }

            this.form.desconto_valor = this.desconto;
            this.form.total = result;
            this.total = result;

            if(this.form.desconto_tipo === '%' ? ' %' : '')
                return {
                    decimal: '.',
                    prefix: '',
                    suffix: ' %',
                    precision: 2,
                    masked: false
                }
        }
    },
    mounted(){
        this.getResults();
    },
    created(){},
    computed: {
        tipoDeDesconto(){
            let tipo = this.form.desconto_tipo;
            return !(tipo == '%' || tipo == '$');
        },
        statusVenda(){
            return (this.form.status > 0);
        }
    },
}
</script>
