<template>
  <div class="col-12">
        <div class="card card-primary card-outline card-tabs">
            <div class="card-header p-0 pt-1 border-bottom-0">
                <ul class="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                    <li class="nav-item">
                        <a
                            class="nav-link active"
                            id="custom-tabs-three-student-tab"
                            data-toggle="pill"
                            href="#custom-tabs-three-student"
                            role="tab"
                            aria-controls="custom-tabs-three-student"
                            aria-selected="true">
                            Aplicativos alunos
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="custom-tabs-three-teacher-tab" data-toggle="pill" href="#custom-tabs-three-teacher" role="tab" aria-controls="custom-tabs-three-teacher" aria-selected="true">
                            Aplicativos professores
                        </a>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <div class="tab-content" id="custom-tabs-three-tabContent">
                    <div class="tab-pane fade show active" id="custom-tabs-three-student" role="tabpanel" aria-labelledby="custom-tabs-three-student-tab">

                        <div class="card card-primary">
                            <form @submit.prevent="save()">
                                <div class="card-header">
                                    <h3 class="card-title">
                                        <strong>Configuração do aplicativo de alunos</strong>
                                    </h3>
                                </div>
                                    <div class="card-body">

                                        <div class="row">
                                            <div class="col-6">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                            <input type="checkbox" class="custom-control-input custom-student"
                                                                id="customSwitchMostrarColegas"
                                                                name="mostrar_colegas" v-model="form.mostrar_colegas"
                                                            >
                                                            <label class="custom-control-label" for="customSwitchMostrarColegas">
                                                            Mostrar colegas de treinamento?
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                        <input type="checkbox" class="custom-control-input custom-student"
                                                        id="customSwitchMostrarCronograma" name="mostrar_cronograma" v-model="form.mostrar_cronograma"
                                                        >
                                                        <label class="custom-control-label" for="customSwitchMostrarCronograma">
                                                            Mostrar horário das aulas?
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                        <input type="checkbox" class="custom-control-input custom-student"
                                                        id="customSwitchMostrarTotalPresenca" name="mostrar_total_presencas" v-model="form.mostrar_total_presencas"
                                                        >
                                                        <label class="custom-control-label" for="customSwitchMostrarTotalPresenca">
                                                            Mostrar assiduidade total para a formatura?
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                        <input type="checkbox" class="custom-control-input custom-student"
                                                        id="customSwitchMostrarContasaberto" name="mostrar_contas_aberto" v-model="form.mostrar_contas_aberto"
                                                        >
                                                        <label class="custom-control-label" for="customSwitchMostrarContasaberto">
                                                            Mostrar contas abertas?
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                            <input type="checkbox" class="custom-control-input custom-student"
                                                            id="customSwitchBloqueioAtraso" name="bloqueio_atraso" v-model="form.bloqueio_atraso"
                                                        >
                                                            <label class="custom-control-label" for="customSwitchBloqueioAtraso">
                                                                Bloqueio com pagamento mensal atrasado?
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                            <input type="checkbox" class="custom-control-input custom-student"
                                                            id="customSwitchMostrarPlanos" name="mostrar_planos" v-model="form.mostrar_planos"
                                                            >
                                                            <label class="custom-control-label" for="customSwitchMostrarPlanos">
                                                                Mostrar planos disponíveis?
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                            <input type="checkbox" class="custom-control-input custom-student"
                                                            id="customSwitchMostrarResultadosEnquete" name="mostrar_resultados_enquete" v-model="form.mostrar_resultados_enquete"
                                                            >
                                                            <label class="custom-control-label" for="customSwitchMostrarResultadosEnquete">
                                                                Mostrar resultado da enquete ?
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                            <input type="checkbox" class="custom-control-input custom-student"
                                                            id="customSwitchMostrarHistoricoEnquete" name="mostrar_historico_enquete" v-model="form.mostrar_historico_enquete"
                                                            >
                                                            <label class="custom-control-label" for="customSwitchMostrarHistoricoEnquete">
                                                                Mostrar enquete antigas ?
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                            <input type="checkbox" class="custom-control-input custom-student"
                                                            id="customSwitchMostrarBlog" name="mostrar_blog" v-model="form.mostrar_blog"
                                                            >
                                                            <label class="custom-control-label" for="customSwitchMostrarBlog">
                                                                Mostrar blog / notícias?
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label>Dias até o bloqueio por falta de pagamento?</label>
                                                        <input type="text" v-model="form.dias_atraso"
                                                            class="form-control dias_atraso" id="dias_atraso"
                                                            name="dias_atraso" placeholder=""
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        <!-- @else
                                            <p>
                                                {{ __('adminlte::adminlte.apps_not_config') }}
                                            </p>
                                        @endif -->
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button class="btn btn-success float-right" id="saveChanges">
                                        Atualizar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-three-teacher" role="tabpanel" aria-labelledby="custom-tabs-three-teacher-tab">
                    </div>
                </div>
            </div>
            <!-- /.card -->

        </div>

    </div>
</template>

<script>
export default {
    data(){
        return{
            form: new Form({
                mostrar_colegas: '',
                mostrar_cronograma: '',
                mostrar_total_presencas: '',
                mostrar_contas_aberto: '',
                bloqueio_atraso: '',
                mostrar_planos: '',
                mostrar_resultados_enquete: '',
                mostrar_historico_enquete: '',
                mostrar_blog: '',
                dias_atraso: '',
            }),
        }
    },
    methods:{
        loadAppConfigs(){
            this.$Progress.start();
                axios.get("/api/configuracao/aplicativo").then(({data}) => {
                    this.form.fill(data.data);
                });
            this.$Progress.finish();
            console.log(this.form);
        },
        save(){
            this.form.campos = this.inputs;
            this.$Progress.start();
            this.form.post('/api/configuracao/aplicativo/save')
                .then((data) => {
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    this.$Progress.finish();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
    },
    created(){
        this.loadAppConfigs();
        console.log('Aplicativo componente created')
    },
    mounted(){
        console.log('Aplicativo componente mounted')
    },

}
</script>

<style>

</style>
