<template>
    <section class="content">
        <div class="container-fluid">
 <!-- <h1 class="m-0 text-dark"> Dashboard </h1> -->

            <DadosDiarios></DadosDiarios>
            <Financeiro></Financeiro>
            <Faixas></Faixas>
            <ContasAtrasadas></ContasAtrasadas>

            <div class="row">
                <PoxGraduacoes></PoxGraduacoes>
                <Modalidades></Modalidades>
                <Atendimento></Atendimento>
            </div>

        </div>
    </section>
</template>

<script>
import DadosDiarios from './dashboard/DadosDiarios.vue';
import Financeiro from './dashboard/Financeiro.vue';
import Faixas from './dashboard/Faixas.vue';
import ContasAtrasadas from './dashboard/ContasAtrasadas.vue';
import PoxGraduacoes from './dashboard/PoxGraduacoes.vue';
import Modalidades from './dashboard/Modalidades.vue';
import Atendimento from './dashboard/Atendimento.vue';

    export default {
        components:{
            DadosDiarios,
            Financeiro,
            Faixas,
            ContasAtrasadas,
            PoxGraduacoes,
            Modalidades,
            Atendimento
        },
        data(){
            return{

            }
        },
        mounted() {
            console.log('Component mounted.')
        }
    }
</script>
