<template>

<div>
        <form @submit.prevent="saveAluno()">
                    <div class="modal-body">
                        <!-- <div class="form-group">
                            <label>Academias</label>
                            <select name="id_academia" v-model="form.id_academia" id="id_academia"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('id_academia') }">
                                <option
                                    v-for="academia in academias.data" :key="academia.id_academia"
                                    :value="academia.id_academia">
                                    {{ academia.nome }}
                                </option>
                            </select>
                            <has-error :form="form" field="id_academia"></has-error>
                        </div> -->

                        <SelectAcademia :selecionada="{id_academia: form.id_academia}" />
                        <div class="row">
                            <div class="col">
                              <div class="form-group">
                                    <label>Nome</label>
                                    <input v-model="form.nome" type="text" name="nome"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('nome') }">
                                    <has-error :form="form" field="nome"></has-error>
                                </div>
                            </div>
                            <div class="col">
                               <div class="form-group">
                                    <label> Sobrenome </label>
                                    <input v-model="form.sobrenome" type="text" name="sobrenome"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('sobrenome') }">
                                    <has-error :form="form" field="sobrenome"></has-error>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                              <div class="form-group">
                                    <label>CPF</label>
                                    <input v-model="form.cpf" type="text" name="cpf" v-mask="'###.###.###-##'"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('cpf') }">
                                    <has-error :form="form" field="cpf"></has-error>
                                </div>
                            </div>
                            <div class="col">
                               <div class="form-group">
                                    <label>RG</label>
                                    <input v-model="form.rg" type="text" name="rg"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('rg') }">
                                    <has-error :form="form" field="rg"></has-error>
                                </div>
                            </div>
                        </div>

                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link active" id="basic-tab" data-toggle="tab" href="#basic" role="tab" aria-controls="basic" aria-selected="true">
                                Basico
                            </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="address-tab" data-toggle="tab" href="#address" role="tab" aria-controls="address" aria-selected="false">
                                Endereço
                            </a>
                        </li>
                    </ul>
                <div class="tab-content">

                    <div class="tab-pane fade  show active" id="basic" role="tabpanel" aria-labelledby="basic-tab">

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">

                                            <label> Email </label>
                                            <input v-model="form.email" type="email" name="email"
                                                class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                                            <has-error :form="form" field="email"></has-error>

                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">

                                            <label>Senha</label>
                                            <input v-model="form.password" type="password" name="password"
                                                class="form-control" :class="{ 'is-invalid': form.errors.has('password') }">
                                            <has-error :form="form" field="password"></has-error>

                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">

                                            <label>Data de Nascimento</label>
                                            <b-form-datepicker
                                                id="data_nascimento"
                                                v-model="form.data_nascimento"
                                                :class="{ 'is-invalid': form.errors.has('data_nascimento') }"
                                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                            />
                                            <!-- <input v-model="form.data_nascimento" type="date" name="data_nascimento"
                                                class="form-control" :class="{ 'is-invalid': form.errors.has('data_nascimento') }"> -->
                                            <has-error :form="form" field="data_nascimento"></has-error>

                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">

                                            <label>Sexo</label>
                                            <select name="sexo" v-model="form.sexo" id="sexo"
                                                class="form-control" :class="{ 'is-invalid': form.errors.has('sexo') }">
                                                <option value="F">Feminino</option>
                                                <option value="M">Masculino</option>
                                            </select>
                                            <has-error :form="form" field="sexo"></has-error>

                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">

                                            <label>Telefone</label>
                                            <phone-mask-input
                                                v-model="form.telefone"
                                                autoDetectCountry
                                                wrapperClass="wrraper-example"
                                                inputClass="form-control"
                                                :class="{ 'is-invalid': form.errors.has('telefone') }"
                                                flagClass="flag-example"
                                            />
                                            <has-error :form="form" field="telefone"></has-error>

                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">

                                            <SelectPessoa
                                                :class="{ 'is-invalid': form.errors.has('usuario_id') }"
                                                :selectValue="{name: form.responsavel}"
                                                title="Recebedores"
                                                v-on:selected="form.responsavel = $event.name"
                                            />
                                            <has-error :form="form" field="responsavel"></has-error>

                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">

                                            <label>Status</label>
                                            <select name="status" v-model="form.status" id="status"
                                                class="form-control" :class="{ 'is-invalid': form.errors.has('status') }">
                                                <option value="Y">Ativo</option>
                                                <option value="N">Inativo</option>
                                            </select>
                                            <has-error :form="form" field="status"></has-error>

                                        </div>
                                    </div>
                                    <div class="col">
                                        <label>User Foto</label>
                                        <input type="file" name="foto"
                                                class="form-control" :class="{ 'is-invalid': form.errors.has('foto') }"
                                                @change="saveAluno"
                                        >
                                        <has-error :form="form" field="foto"></has-error>
                                    </div>
                                </div>

                            </div>
                            <div class="tab-pane fade" id="address" role="tabpanel" aria-labelledby="address-tab">

                            <Endereco
                                :selectValue="form.endereco"
                                v-on:selected="form.endereco = $event;"
                            ></Endereco>

                            </div>
                            <SelectGraduacao
                                :selectValue="form.modalidades"
                                v-on:selected="form.modalidades = $event;"
                            ></SelectGraduacao>
                       </div>
                    </div>

                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary"> Salvar </button>
                    </div>

                  </form>
</div>
</template>

<script>
import SelectPessoa from "../../../financeiro/common/SelectPessoa";
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import SelectGraduacao from "../../common/SelectGraduacao.vue";
import PhoneMaskInput from "vue-phone-mask-input";
import {mask} from 'vue-the-mask'
import Endereco from '../../../common/form/Endereco.vue';
import LoadSpinCard from '../../../common/LoadSpinCard.vue';
import Aluno from "../../../../domain/user/Aluno";
import SelectAcademia from '../../../common/form/SelectAcademia.vue';
import SelectResponsavel from '../../common/SelectResponsavel.vue'

export default {
     components:{
        Endereco,
        Multiselect,
        SelectGraduacao,
        LoadSpinCard,
        SelectAcademia,
        SelectResponsavel,
        SelectPessoa
    },
    directives: {mask},
    name: "Dados",
    props:{
        dados:{
            type: Object,
        },
    },
    data(){
        return{
            academias: [],
            form: new Form(
                new Aluno(),
            ),
            alunos: [],
        }
    },
    methods:{
        loadAlunos(){
            this.$Progress.start();
            axios.get("/api/aluno").then(({ data }) => {
                this.alunos = data.data;
                console.log(this.alunos);
            });
            this.$Progress.finish();
        },
        // loadModalidades(){
        //     this.$Progress.start();
        //     axios.get("/api/artes_marciais/").then(({ data }) => {this.modalidades = data.data});
        //     this.$Progress.finish();
        // },
        saveAluno(){
            console.log(this.form);
            this.$Progress.start();
            this.form.put('/api/aluno/'+this.form.id)
            .then((response) => {
                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                });
                this.$Progress.finish();
            })
            .catch(() => {
                this.$Progress.fail();
            });
        },
    },
    created(){
        this.loadAlunos();
        console.log('Dados componente created');
    },
    mounted(){
        console.log('Dados component mounted');
    },
    watch:{
        dados: function (dados){
            this.form.fill(dados);
            console.log(this.form);
        }
    }

}
</script>

<style scoped>

</style>
