<template>
    <div>
        <div class="card-info mt-3">
            <div class="card-header ui-sortable-handle" >
                <h3 class="card-title">PagSeguro</h3>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <form @submit.prevent="save()">
                        <div class="form-group">
                            <label>Ativar pagamento com Pagseguro?</label>
                            <select v-model="form.pagseguro_status"  name="pagseguro_status" class="form-control">
                                <option value="0">Não</option>
                                <option value="1">Sim</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>E-mail no PagSeguro</label>
                            <input v-model="form.pagseguro_email"  type="text" name="pagseguro_email" class="form-control">
                        </div>
                        <div class="form-group">
                            <label>Token do PagSeguro</label>
                            <input v-model="form.pagseguro_token"  type="text" name="pagseguro_token" class="form-control">
                        </div>
                        <div class="form-group">
                            <label>Url de notificação</label>
                            <input v-model="form.pagseguro_prefixo"  type="text" name="pagseguro_prefixo" class="form-control">
                        </div>
                        <div class="form-group">
                            <label>PagSeguro Sandbox (SIM - somente se houver uma conta de teste no PagSeguro)</label>
                            <select v-model="form.pagseguro_sandbox" name="pagseguro_sandbox" class="form-control">
                                <option value="0">Não</option>
                                <option value="1">Sim</option>
                            </select>
                        </div>
                        <button type="submit" class="btn btn-success float-right">
                            Salvar
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            form: new Form({
                pagseguro_status : '',
                pagseguro_email : '',
                pagseguro_token : '',
                pagseguro_prefixo : '',
                pagseguro_sandbox : '',
            }),
        }
    },
    methods:{
        loadGateway(){
            this.$Progress.start();
                axios.get("/api/configuracao/pagseguro").then(({data}) => {
                    this.form.fill(data.data);
                });
            this.$Progress.finish();
        },
        save(){
            this.$Progress.start();
            this.form.post('/api/configuracao/save')
                .then((data) => {
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    this.$Progress.finish();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
    },
    created(){
        this.loadGateway();
        console.log('Stripe componete created')
    },
    mounted(){
        console.log('Stripe componete mounted')
    },

}
</script>

<style>

</style>
