import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {},
  data(){
      return{
        options: {
            responsive:true,
            legend: {
                display: false
            },
            scales: {
                xAxes:[],
                yAxes:[{
                    ticks:{
                        beginAtZero:true
                    }
                }]
            }
        }
      }
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
