import { Doughnut, mixins } from "vue-chartjs";
const { reactiveProp } = mixins
// TODO : pesquisar option para colocar legenda ao lado do grafico;
export default {
    extends: Doughnut,
    mixins: [reactiveProp],
    props: {

    },
    data(){
        return{
            options: {
                responsive: true,
                legend: {
                    position: 'right'
                }
            },
        }
    },
    mounted() {
    this.renderChart(this.chartData, this.options);
    }
};
