<template>
    <div>
        <label class="typo__label">Forma de Pagamento</label>

        <multiselect
            v-model="value"
            track-by="nome"
            label="nome"
            placeholder="Selecione um"
            :options="options"
            :searchable="false"
            :allow-empty="false"
            @select="setSelected"
        >
            <template slot="singleLabel" slot-scope="{ option }">
                <strong>{{ option.nome }}</strong>
            </template>
        </multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    components: {
        Multiselect
    },
    props: {
        selectValue: {
            type: [Number, String],
        }
    },
    data() {
        return {
            options: [
                {nome: 'Carregando...', id: null},
            ],
            value: []
        }
    },
    methods: {
        getResults(search = '') {
            this.$Progress.start();
            axios.get(`/api/financeiro/formasDePagamento?d=${search}`).then(({data}) => (this.options = data.data));
            this.$Progress.finish();
        },
        setSelected(selectedOption) {
            this.$emit('selected', selectedOption)
        }
    },
    created() {
        this.getResults();
    },
    watch: {
        selectValue: function (newVal, oldVal) {
            let resultValue = null;
            this.options.map(function (data, key) {
                if (data.id === newVal) {
                    resultValue = data;
                }
            });

            this.value = resultValue;
        }
    }
}
</script>

