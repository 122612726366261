export default class Horario {
    constructor(id= '',
                id_agenda= '',
                evento= '',
                hora_inicio= '',
                hora_final= '',
                id_arte_marcial= '',
                id_professor= '',
                dia_semana= [],
                cor= '',
                descricao= '',
                qtde_alunos= '',
                tempo_presenca= '',
                tempo_maximo= ''
    ) {
        this.id = id;
        this.id_agenda = id_agenda;
        this.evento = evento;
        this.hora_inicio = hora_inicio;
        this.hora_final = hora_final;
        this.id_arte_marcial = id_arte_marcial;
        this.id_professor = id_professor;
        this.dia_semana = dia_semana;
        this.cor = cor;
        this.descricao = descricao;
        this.qtde_alunos = qtde_alunos;
        this.tempo_presenca = tempo_presenca;
        this.tempo_maximo = tempo_maximo;
    }
}
