<template>
  <div>
      <div class="card-info mt-3">
            <div class="card-header ui-sortable-handle" >
                <h3 class="card-title">Paghiper</h3>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <form @submit.prevent="save()">
                        <div class="form-group">
                            <label>Ativar recebimento com boleto?</label>
                            <select v-model="form.paghiper_status" name="paghiper_status" class="form-control">
                                <option value="0">Não</option>
                                <option value="1">Sim</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>API KEY</label>
                            <input v-model="form.paghiper_api_key"  type="text" name="paghiper_api_key" class="form-control">
                        </div>
                        <div class="form-group">
                            <label>TOKEN</label>
                            <input v-model="form.paghiper_token"  type="text" name="paghiper_token" class="form-control">
                        </div>
                        <div class="form-group">
                            <label>Percentual de Multa sobre pagamento em atraso</label>
                            <input v-model="form.paghiper_percentual_multa" type="text" name="paghiper_percentual_multa" class="form-control">
                        </div>
                        <div class="form-group">
                            <label>Dias para pagamento após vencimento</label>
                            <input v-model="form.paghiper_dias_vencimento" type="text" name="paghiper_dias_vencimento" class="form-control">
                        </div>
                    <button type="submit" class="btn btn-success float-right">
                        Salvar
                    </button>
                    </form>

                </div>
            </div>
        </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            form: new Form({
                paghiper_status: '',
                paghiper_api_key: '',
                paghiper_token: '',
                paghiper_dias_vencimento: '',
                paghiper_percentual_multa: '',
            }),
        }
    },
    methods:{
        loadGateway(){
            this.$Progress.start();
                axios.get("/api/configuracao/paghiper").then(({data}) => {
                    this.form.fill(data.data);
                });
            this.$Progress.finish();
        },
        save(){
            this.$Progress.start();
            this.form.post('/api/configuracao/save')
                .then((data) => {
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    this.$Progress.finish();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
    },
    created(){
        this.loadGateway();
        console.log('Componente paghiper created')
    },
    mounted(){
        console.log('Componente paghiper mounte')
    },

}
</script>

<style>

</style>
