<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12">

                    <b-alert
                        variant="warning"
                        show
                        dismissible
                        fade
                        v-if="!statusRegistros">
                        Você atingiou o limite de horários!
                    </b-alert>

                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Agenda de Horários</h3>
                            <div class="card-tools">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-primary"
                                    @click="newModal"
                                    title="cadastrar"
                                    v-if="statusRegistros"
                                >
                                    <i class="fa fa-plus-square"></i>
                                    NOVO HORARIO
                                </button>
                            </div>
                        </div>

                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Hora Inicio/Final</th>
                                    <th>Dias</th>
                                    <th>Status</th>
                                    <th>Ação</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-if="agendas.data.length <= 0" class="text-center">
                                    <td colspan="5">
                                        Nenhum horário cadastrado
                                    </td>
                                </tr>
                                <tr v-for="agenda in agendas.data" :key="agenda.id_agenda">
                                    <td>{{ agenda.evento }}</td>
                                    <td>
                                        {{ agenda.hora_inicio }} - {{ agenda.hora_final }}
                                    </td>
                                    <td>
                                            <label
                                                   v-show="agenda.dia_semana.split('*').sort().indexOf(dia[0]) != -1"
                                                   v-for="dia in dias_semana" :key="dia[0]">
                                                {{ dia[1].substring(0,3) }} &#9900;&nbsp;
                                            </label>
                                    </td>
                                    <td>
                                        <span :value="agenda.status" @click="mudaStatus(agenda.id_agenda)"
                                              class="badge cursor-pointer"
                                              v-bind:class="agenda.status ? 'badge-success' : 'badge-danger'">
                                            {{ agenda.status ? 'Ativo' : 'Inativo' }}
                                        </span>
                                    </td>
                                    <td>
                                        <router-link class="btn btn-sm btn-secondary"
                                           :to="`agenda/${agenda.id_agenda}/confirmados`">
                                            <i class="fas fa-users"></i>
                                            Alunos
                                        </router-link>
                                        <a class="btn btn-sm btn-primary"
                                           href="#"
                                           @click="editModal(agenda)">
                                            <i class="fa fa-edit"></i>
                                        </a>

                                        <a class="btn btn-sm btn-danger"
                                           href="#"
                                           @click="deleteEnquete(agenda.id_agenda)">
                                            <i class="fa fa-trash"></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <no-records v-if="agendas.data.length <= 0"></no-records>
                            <pagination :data="agendas" @pagination-change-page="getResults"></pagination>
                        </div>
                    </div>
                </div>
            </div>



            <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" v-show="!editmode">Cadastrar Nova Agenda</h5>
                            <h5 class="modal-title" v-show="editmode">Editar Agenda</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <form @submit.prevent="editmode ? updateAgenda() : createAgenda()">
                            <div class="modal-body">

                                <div class="form-group">
                                    <label>Evento</label>
                                    <input v-model="form.evento"
                                           type="text"
                                           name="evento"
                                           class="form-control"
                                           :class="{ 'is-invalid': form.errors.has('evento') }">
                                    <has-error :form="form" field="evento"></has-error>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="hora_inicio">Hora Início</label>
                                            <input type="time"
                                                   v-model="form.hora_inicio"
                                                   id="hora_inicio"
                                                   class="form-control">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="hora_final">Hora Final</label>
                                            <input type="time"
                                                   v-model="form.hora_final"
                                                   id="hora_final"
                                                   class="form-control">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="id_arte_marcial">Arte Marcial</label>
                                            <select v-model="form.id_arte_marcial"
                                                id="id_arte_marcial"
                                                class="form-control"
                                                :class="{ 'is-invalid': form.errors.has('id_arte_marcial') }">
                                                <option
                                                    v-for="(modalidade, index) in modalidades"
                                                    :value="index"
                                                    :selected="index == form.id_arte_marcial"
                                                >
                                                    {{ modalidade}}
                                                </option>
                                            </select>
                                            <has-error :form="form" field="id_arte_marcial"></has-error>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="id_professor">Professor</label>
                                            <select v-model="form.id_professor"
                                                    id="id_professor"
                                                    class="form-control"
                                                    :class="{ 'is-invalid': form.errors.has('id_professor') }">
                                                <option
                                                    v-for="(professor, index) in professores"
                                                    :value="index"
                                                    :selected="index == form.id_professor"
                                                >
                                                    {{ professor }}
                                                </option>
                                            </select>
                                            <has-error :form="form" field="id_professor"></has-error>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Dias da Semana</label>
                                            <div class="btn-group btn-block btn-group-toggle" data-toggle="buttons">
                                                <label class="btn bg-primary"
                                                       :class="form.dia_semana.indexOf(dia[0]) != -1 ? 'active': ''"
                                                       v-for="dia in dias_semana" :key="dia[0]">
                                                    <input type="checkbox"
                                                           v-model="form.dia_semana"
                                                           :true-value="form.dia_semana"
                                                           :value="dia[0]">{{ dia[1] }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <label for="cor">Cor</label>
                                        <input type="color"
                                               value="#ff0000"
                                               v-model="form.cor"
                                               id="cor"
                                               class="form-control">
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label>Texto</label>
                                    <vue-editor v-model="form.descricao"
                                                :class="{ 'is-invalid': form.errors.has('descricao') }"/>
                                    <has-error :form="form" field="descricao"></has-error>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="qtde_alunos">Limite de Alunos</label>
                                            <input v-model="form.qtde_alunos"
                                                   type="number"
                                                   id="qtde_alunos"
                                                   class="form-control"
                                                   :class="{ 'is-invalid': form.errors.has('qtde_alunos') }">
                                            <has-error :form="form" field="qtde_alunos"></has-error>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="tempo_presenca">Limite de Horas 1</label>
                                            <input v-model="form.tempo_presenca"
                                                   type="number"
                                                   id="tempo_presenca"
                                                   class="form-control"
                                                   :class="{ 'is-invalid': form.errors.has('tempo_presenca') }">
                                            <has-error :form="form" field="tempo_presenca"></has-error>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="tempo_maximo">Limite de Horas 2</label>
                                            <input v-model="form.tempo_maximo"
                                                   type="number"
                                                   id="tempo_maximo"
                                                   class="form-control"
                                                   :class="{ 'is-invalid': form.errors.has('tempo_maximo') }">
                                            <has-error :form="form" field="tempo_maximo"></has-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                <button type="submit" class="btn btn-success">Salvar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import Horario from '../../domain/agenda/Horario';

export default {
    components: {
        VueTagsInput
    },
    data() {
        return {
            plano: {},
            date: new Date(),
            editmode: false,
            agendas: {
                data: []
            },
            modalidades: [],
            professores: [],
            dias_semana: [
                ['0', 'Segunda'],
                ['1', 'Terça'],
                ['2', 'Quarta'],
                ['3', 'Quinta'],
                ['4', 'Sexta'],
                ['5', 'Sabado'],
                ['6', 'Domingo'],
            ],
            form: new Form(
                new Horario()
            ),
            autocompleteItems: [],
        }
    },
    methods: {
        getResults(page = 1) {
            this.$Progress.start();
            axios.get(`/api/agenda?page=${page}`).then(({data}) => (this.agendas = data.data));
            this.$Progress.finish();
        },
        loadAgendas() {
            axios.get(`/api/agenda`).then(({data}) => (this.agendas = data.data));
        },
        loadModalidades() {
            axios.get(`/api/academiaModalidades`).then(({data}) => {
                this.modalidades = data.data;
            });
        },
        loadProfessores() {
            axios.get(`/api/professorSelect`).then(({data}) => {
                this.professores = data.data;
            });
        },
        editModal(post) {
            this.editmode = true;
            this.form.reset();
            $('#addNew').modal('show');
            this.form.fill(post);
            this.form.dia_semana = post.dia_semana.split("*");
        },
        newModal() {
            this.editmode = false;
            this.form.reset();
            $('#addNew').modal('show');
        },
        createAgenda() {
            this.$Progress.start();

            this.form.post(`/api/agenda`)
                .then((data) => {
                    if (data.data.success) {
                        $('#addNew').modal('hide');
                        Toast.fire({
                            icon: 'success',
                            title: data.data.message
                        });
                        this.$Progress.finish();
                        this.loadAgendas();
                    } else {
                        Toast.fire({
                            icon: 'error',
                            title: 'Ocorreu um erro! Por favor, tente novamente.'
                        });

                        this.$Progress.failed();
                    }
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
        updateAgenda() {
            this.$Progress.start();
            this.form.put(`/api/agenda/${this.form.id}`)
                .then((response) => {
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();
                    this.loadAgendas();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

        },
        deleteEnquete(id) {
            Swal.fire({
                title: 'Tem certeza?',
                text: "Você não poderá restaurar depois!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Sim, excluir!'
            }).then((result) => {
                if (result.value) {
                    this.form.delete(`/api/agenda/${id}`).then(() => {
                        Toast.fire(
                            'Excluído!',
                            'O horário foi excluído.',
                            'success'
                        );
                        this.loadAgendas();
                    }).catch((data) => {
                        Swal.fire("Erro!", data.message, "warning");
                    });
                }
            })
        },
        mudaStatus(id) {
            this.$Progress.start();

            this.form.put(`/api/agendaStatus/${id}`)
                .then((data) => {
                    if (data.data.success) {
                        Toast.fire({
                            icon: 'success',
                            title: data.data.message
                        });
                        this.$Progress.finish();
                        this.loadAgendas();
                    } else {
                        Toast.fire({
                            icon: 'error',
                            title: 'Ocorreu um erro! Por favor, tente novamente.'
                        });
                        this.$Progress.failed();
                    }
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
        diaSemanas(dias) {
            return dias.split("*").sort();
        }
    },
    mounted() {

    },
    created() {
        this.$Progress.start();

        this.loadAgendas();
        this.loadModalidades();
        this.loadProfessores();

        PLANO.then((result) => {
            this.plano = result.data;
        })

        this.$Progress.finish();
    },
    filters: {
        truncate: function (text, length, suffix) {
            return text.substring(0, length) + suffix;
        },
    },
    computed: {
        filteredItems() {
            return this.autocompleteItems.filter(i => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
        },
        statusRegistros() {
            return (this.plano.aulas >= this.agendas.total + 1);
        }
    },
}
</script>
