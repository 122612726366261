<template>

<div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
    <div class="modal-dialog text-left">
        <div class="modal-content bg-warning">
            <div class="modal-header">
            <h4 class="modal-title">Fazer Graduação <slot></slot> </h4>
            <button type="button" @click="closeModal()" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="'true'">×</span></button>
            </div>
            <form @submit.prevent="addGraduacao()">
                <div class="modal-body">
                    <div class="form-group">
                        <p class="callout callout-danger">
                            Muita atenção quando for registrar uma graduação para o aluno, quando voce precisar registrar uma graduação antiga
                            altera as opçoes abaixo!
                        </p>

                        <label>Tipo de Graduação</label>
                        <select name="type_graduation" v-model="form.type_graduation" id="type_graduation" :class="{ 'is-invalid': form.errors.has('type_graduation') }"
                            class="form-control">
                                <option value="graduacao">Nova Graduação</option>
                                <option value="history">Adicionar Historico</option>
                                <option value="presenca">Adicionar Presenca</option>
                        </select>
                        <has-error :form="form" field="type_graduation"></has-error>
                    </div>
                    <div v-if="form.type_graduation != ''">
                        <div class="form-group">
                            <div class="row">

                                <div class="col-md-12" v-if="artes.length > 0">
                                    <label>Arte Marcial</label>
                                    <select name="arte_marcial" v-model="form.id_arte" id="arte_marcial" class="form-control">
                                        <option v-for="arte in artes" :key="arte.id" :value="arte.id">
                                            {{ arte.label }}
                                        </option>
                                    </select>
                                    <has-error :form="form" field="graduate"></has-error>
                                </div>
                            </div>
                        </div>
                        <div v-if="form.type_graduation != 'presenca'">
                            <div v-if="form.id_arte == 1">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="faixa">Faixa</label>
                                            <select class="form-control" v-model="form.faixa" name="faixa" id="faixa">
                                                <option selected="" value="branca">Branca</option>
                                                <option value="cinza">Cinza</option>
                                                <option value="amarela">Amarela</option>
                                                <option value="laranja">Laranja</option>
                                                <option value="verde">Verde</option>
                                                <option value="azul">Azul</option>
                                                <option value="roxa">Roxa</option>
                                                <option value="marrom">Marrom</option>
                                                <option value="preta">Preta</option>
                                                <option value="vermelha">Vermelha
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="grau">Grau</label>
                                            <select class="form-control" v-model="form.grau" name="grau" id="grau">
                                                <option selected="" value="0">0 Grau</option>
                                                <option value="1">1º Grau</option>
                                                <option value="2">2º Grau</option>
                                                <option value="3">3º Grau</option>
                                                <option value="4">4º Grau</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="linha">Linha</label>
                                            <select class="form-control" v-model="form.linha_faixa" name="linha_faixa" id="linha_faixa">
                                                <option selected="" value="l_faixa">Sem Linha</option>
                                                <option value="branca">Branca</option>
                                                <option value="preta">Preta</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="cor_grau">Cor do Grau</label>
                                            <select class="form-control" v-model="form.cor_grau" name="cor_grau" id="cor_grau">
                                                <option value="branco" selected="">Branco (Padrão)</option>
                                                <option value="vermelho">Vermelho</option>
                                                <option value="cinza">Cinza</option>
                                                <option value="amarelo">Amarelo</option>
                                                <option value="laranja">Laranja</option>
                                                <option value="verde">Verde</option>
                                                <option value="azul">Azul</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" v-else>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label>Selecione a graduacao</label>
                                        <select name="linha_faixa" v-model="form.linha_faixa" id="linha_faixa" :class="{ 'is-invalid': form.errors.has('linha_faixa') }"
                                            class="form-control">
                                            <option v-for="(graduacao, index) in graduacoes" :key="index" :value="index">
                                            {{ graduacao }}
                                        </option>
                                        </select>
                                        <has-error :form="form" field="linha_faixa"></has-error>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-group">
                                <p class="callout callout-danger">
                                    Muita atenção quando for registrar uma graduação para o aluno, quando voce precisar registrar uma graduação antiga
                                    altera as opçoes abaixo!
                                </p>

                                <label>Tipo de Graduação</label>
                                <select name="type_graduation" v-model="form.type_graduation" id="type_graduation" :class="{ 'is-invalid': form.errors.has('type_graduation') }"
                                    class="form-control">
                                        <option value="graduacao">Nova Graduação</option>
                                        <option value="history">Adicionar Historico</option>
                                        <option value="presenca">Adicionar Presenca</option>
                                </select>
                                <has-error :form="form" field="type_graduation"></has-error>
                            </div> -->
                            </div>
                            <div class="form-group">
                                <label>Data da {{ form.type_graduation == 'presenca' ? 'presenca' : 'graduação'}}</label>
                                <b-form-datepicker
                                    id="date_graduate"
                                    v-model="form.date_graduate"
                                    :class="{ 'is-invalid': form.errors.has('date_graduate') }"
                                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                />
                                <has-error :form="form" field="date_graduate"></has-error>
                            </div>
                            <div v-if="form.type_graduation != 'presenca' && form.type_graduation != ''" class="form-group">

                                <label>Comentario</label>
                                <input v-model="form.comments" type="text" name="comments"
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('comments') }">
                                <has-error :form="form" field="comments"></has-error>
                            </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button"  @click="closeModal()" class="btn btn-dark" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary"> Salvar </button>
                </div>
            </form>
        </div>
    <!-- /.modal-content -->
    </div>
</div>

</template>

<script>
import Presenca from '../../domain/precensas/Presenca';
export default {
    props:{
        openModal: {type: Object, required: true },
    },
    data(){
        return{
            form: new Form(
                new Presenca(this.id_aluno,this.id_arte),
            ),
            graduacoes: [],
            artes: [],
        }
    },
    methods:{
        addGraduacao(){

            this.form.post(`/api/aluno/${this.form.id_aluno}/add_graduar`)
              .then((response)=>{
                if(response.data.status == 'success'){
                    $('#addNew').modal('hide');
                    this.$emit('close',{status: 0, id_arte: '', reload: this.form.id_arte});
                    this.form.reset();
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                }else{
                    Toast.fire({
                      icon: 'warning',
                      title: response.data.message
                  });
                }
              })
              .catch(()=>{
                  Toast.fire({
                      icon: 'error',
                      title: 'Ocorreu um erro! Por favor, tente novamente.'
                  });
              })
        },
        getGraduacoes(id_arte = null){

            this.$Progress.start();

                axios.get(`/api/modalidade/${id_arte}/graduacoes`).then(({ data }) => (
                    this.graduacoes = data
                ));

            this.$Progress.finish();
        },
        newModal(){
            $('#addNew').modal('show');
        },
        closeModal(){
            this.form.reset();
            this.$emit('close',{status: 0, id_arte: this.form.id_arte});
        }
    },
    created(){ },
    mounted(){
        console.log("GraduarModal componente mounted");
    },
    watch:{
        openModal: function (newVal, oldVal){
            this.form.id_aluno = newVal.id_aluno;

            if(newVal.id_arte){
                this.getGraduacoes(newVal.id_arte);
                this.form.id_arte = newVal.id_arte;
            }
            if(newVal.artes)
                this.artes = newVal.artes;

            this.newModal();
        },
    },

}
</script>

<style>

</style>
