<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">

              <b-alert
                  variant="warning"
                  show
                  dismissible
                  fade
                  v-if="!statusRegistros">
                  Você atingiou o limite de professores!
              </b-alert>

              <div class="card-header">
                <h3 class="card-title">Professores</h3>

                <div class="card-tools">

                  <button
                      type="button"
                        class="btn btn-sm btn-primary"
                        @click="newModal"
                        title="cadastrar"
                        v-if="statusRegistros"
                  >
                      <i class="fa fa-plus-square"></i>
                      NOVO PROFESSOR
                  </button>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                  <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Idade</th>
                      <th>Telefone</th>
                      <th>Email</th>
                      <th>Arte Marcial</th>
                      <th>Status</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="professor in professores.data" :key="professor.id_professor">

                      <td>{{professor.nome}}</td>
                      <td>{{professor.idade}}  </td>
                      <td>{{professor.telefone}}</td>
                      <td>{{professor.email}}</td>
                      <td>
                          <div v-if="professor.martial_arts != 0">
                            <span v-for="modalidade in professor.martial_arts" :key="modalidade.id" >
                                {{ modalidade.label }} -
                            </span>
                          </div>
                      </td>
                      <td>
                        <span :value="professor.status" @click="mudaStatus(professor.id_professor)" class="badge "
                            v-bind:class="professor.status == 'Y' ? 'badge-success' : 'badge-danger'">
                        {{ professor.status == 'Y' ? 'Active' : 'Inactive' }}
                        </span>
                      </td>

                      <td>
                        <a href="#" @click="editModal(professor)" class="btn btn-sm btn-primary" title="editar">
                             <i class="fa fa-edit"></i>
                        </a>

                        <a href="#" @click="deleteProfessor(professor.id_professor)" class="btn btn-sm btn-danger" title="excluir">
                            <i class="fa fa-trash"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                  <no-records v-if="professores.data.length <= 0"></no-records>
                  <pagination :data="professores" @pagination-change-page="getResults"></pagination>
              </div>
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Cadastrat Novo Professor</h5>
                    <h5 class="modal-title" v-show="editmode">Editar Professor</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateProfessor() : createProfessor()">

                    <div class="modal-body">

                        <select-academia v-if="!editmode"
                            :selecionada="form"
                            v-on:selected="form.id_academia = $event"
                        />
                        <div class="row">
                            <div class="col">
                                <div class="form-group">

                                    <label>Nome</label>
                                    <input v-model="form.nome" type="text" name="nome"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('nome') }">
                                    <has-error :form="form" field="nome"></has-error>

                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">

                                    <label>Sobre Nome</label>
                                    <input v-model="form.sobrenome" type="text" name="sobrenome"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('sobrenome') }">
                                    <has-error :form="form" field="sobrenome"></has-error>

                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <div class="form-group">

                                    <label>CPF</label>
                                    <input v-model="form.cpf" type="text" name="cpf" v-mask="'###.###.###-##'"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('cpf') }">
                                    <has-error :form="form" field="cpf"></has-error>

                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">

                                    <label>RG</label>
                                    <input v-model="form.rg" type="text" name="rg"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('rg') }">
                                    <has-error :form="form" field="rg"></has-error>

                                </div>
                            </div>
                        </div>

                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="basic-tab" data-toggle="tab" href="#basic" role="tab" aria-controls="basic" aria-selected="true">
                                    Basico
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="address-tab" data-toggle="tab" href="#address" role="tab" aria-controls="address" aria-selected="false">
                                    Enderecos
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane fade  show active" id="basic" role="tabpanel" aria-labelledby="basic-tab">

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">

                                            <label> Email </label>
                                            <input v-model="form.email" type="email" name="email"
                                                class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                                            <has-error :form="form" field="email"></has-error>

                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">

                                            <label>Senha</label>
                                            <input v-model="form.senha" type="password" name="senha"
                                                class="form-control" :class="{ 'is-invalid': form.errors.has('senha') }">
                                            <has-error :form="form" field="senha"></has-error>

                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">

                                            <label>Data de Nascimento</label>
                                            <input v-model="form.data_nascimento" type="date" name="data_nascimento"
                                                class="form-control" :class="{ 'is-invalid': form.errors.has('data_nascimento') }">
                                            <has-error :form="form" field="data_nascimento"></has-error>

                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">

                                            <label>Sexo</label>
                                            <select name="sexo" v-model="form.sexo" id="sexo"
                                                class="form-control" :class="{ 'is-invalid': form.errors.has('sexo') }">
                                                <option value="f">Feminino</option>
                                                <option value="m">Masculino</option>
                                            </select>
                                            <has-error :form="form" field="sexo"></has-error>

                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">

                                            <label>Telefone</label>
                                            <phone-mask-input
                                                v-model="form.telefone"
                                                autoDetectCountry
                                                wrapperClass="wrraper-example"
                                                inputClass="form-control"
                                                :class="{ 'is-invalid': form.errors.has('telefone') }"
                                                flagClass="flag-example"
                                            />
                                            <has-error :form="form" field="telefone"></has-error>

                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">

                                            <label>Status</label>
                                            <select name="status" v-model="form.status" id="status"
                                                class="form-control" :class="{ 'is-invalid': form.errors.has('status') }">
                                                <option value="Y">Ativo</option>
                                                <option value="N">Inativo</option>
                                            </select>
                                            <has-error :form="form" field="status"></has-error>

                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="categories">Artes Marciais</label>

                                                <multiselect v-model="form.martial_arts" tag-placeholder="Adiconar arte marcial"
                                                    placeholder="Selecione as modalidades" label="label"
                                                    track-by="code"
                                                    :options="modalidades"
                                                    :multiple="true"
                                                    :taggable="true"
                                                    @select="addModalidade($event)"
                                                    @remove="remove($event)"
                                                ></multiselect>
                                        </div>
                                    </div>
                                    <div class="col">

                                        <label>Foto</label>
                                        <input type="file" name="foto"
                                                class="form-control" :class="{ 'is-invalid': form.errors.has('foto') }"
                                                @change="updateProfessor"
                                        >
                                        <has-error :form="form" field="foto"></has-error>

                                    </div>
                                </div>

                            </div>
                            <div class="tab-pane fade" id="address" role="tabpanel" aria-labelledby="address-tab">

                                <Endereco
                                    :selectValue="form.endereco"
                                    v-on:selected="form.endereco = $event;"
                                ></Endereco>

                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Atualizar</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Salvar</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import Multiselect from 'vue-multiselect';
import PhoneMaskInput from "vue-phone-mask-input";
import {mask} from 'vue-the-mask'
import Endereco from "../common/form/Endereco.vue";
import Professor from "../../domain/user/Professor";
import SelectAcademia from  "../common/form/SelectAcademia.vue";

    export default {
        components: {Multiselect,Endereco,SelectAcademia},
        directives: {mask},
        data () {
            return {
                plano: {},
                columns: [
                    {label: 'Nome', field: 'nome'},
                    {label: 'Idade', field: 'data_nascimento', headerClass: 'class-in-header second-class'},
                    {label: 'Telefone', field: 'telefone'},
                    {label: 'Email', field: 'email'},
                    {label: 'Arte Marcial', representedAs: ({martial_arts}) => `${martial_arts.label}`, interpolate: true},
                    {label: 'Status', representedAs: (status, id_professor) => `<span :value="${status}" @click="mudaStatus(${id_professor})" class="badge "
                            :class="${status == 'Y' ? 'badge-success' : 'badge-danger'}">
                        ${ status == 'Y' ? 'Active' : 'Inactive' }
                        </span>`, interpolate:   true
                    },
                    {label: 'Ação' }
                ],
                rows: [],
                page: 1,
                per_page: 10,


                editmode: false,
                professores : {
                    data: []
                },
                martial_arts: [],
                modalidades: [],
                unidades: [],
                form: new Form(
                    new Professor()
                )
            }
        },
        methods: {
            getResults(page = 1) {

                this.$Progress.start();

                axios.get('api/professor?page=' + page).then(({ data }) => {
                    this.professores = data.data;
                    this.rows = data.data.data
                });

                this.$Progress.finish();
            },
            updateProfessor(){
                this.$Progress.start();

                this.form.put('api/professor/'+this.form.id_professor)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadProfessores();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            editModal(professor){
                this.editmode = true;
                this.form.reset();
                this.form.fill(professor);
                // this.form.martial_arts.forEach((arte, index) => {
                //     this.form.martial_arts[index] = this.modalidades.find(mod => mod.code == arte);
                // });
                this.form.martial_arts = Object.values(JSON.parse(JSON.stringify(professor.martial_arts)));
                this.form.senha = null;
                $('#addNew').modal('show');
            },
            // modalidadeP(prof){
            //     var mod = this.modalidades.find(mod => mod.code == prof);
            //     return 'mod.label';
            // },
            newModal(){
                this.editmode = false;
                this.form.reset();
                console.log(this.form);
                $('#addNew').modal('show');
            },
            addModalidade($event){
                console.log($event);
            },
            deleteProfessor(id){
                Swal.fire({
                    title: 'Tem certeza?',
                    text: "Você não poderá restaurar depois!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Sim, excluir!'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                this.form.delete('api/professor/'+id).then(()=>{
                                        Swal.fire(
                                        'Excluido!',
                                        'O professor foi excluído.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadProfessores();
                                }).catch((data)=> {
                                  Swal.fire("Falha!", data.message, "warning");
                              });
                         }
                    })
            },
          loadProfessores(){
            this.$Progress.start();

            if(this.$gate.isAdmin()){
              axios.get("api/professor").then(({ data }) => {
                this.professores = data.data;
                this.rows = data.data.data
              });
            }

            this.$Progress.finish();
          },
            loadModalidades() {
                this.$Progress.start();

                axios.get(`/api/academiaModalidadesSelect`).then(({data}) => {
                    this.modalidades = Object.values(JSON.parse(JSON.stringify(data.data)));
                    console.log('MODALIDADES',this.modalidades);
                });

                this.$Progress.finish();
            },
            buscaCep(){
                if(this.form.cep.length>=7){
                    axios.get(`https://viacep.com.br/ws/${this.form.cep}/json/`).then((data) => {
                        console.log(data);
                    })
                }
            },
          createProfessor(){
              console.log(this.form);
              this.form.post('api/professor')
              .then((response)=>{
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: response.data.message
                  });

                  this.$Progress.finish();
                  this.loadProfessores();

              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
         mudaStatus(id) {
            this.$Progress.start();
            // console.log(id);
            this.form.put(`api/professorStatus/${id}`)
                .then((data) => {
                    if (!data.data.success) {
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                        this.$Progress.failed();
                        return;
                    }
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    this.$Progress.finish();
                    this.loadProfessores();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
        },
        remove(id){
            console.log(id);
        }
        },
        mounted() {
            console.log('User Component mounted.')
        },
        created() {

            this.$Progress.start();
            this.loadProfessores();
            this.loadModalidades();
            PLANO.then((result) => {
                this.plano = result.data;
            })
            this.$Progress.finish();
        },
        computed: {
            statusRegistros() {
                return (this.plano.professores >= this.professores.total + 1);
            }
        }
    }
</script>
