<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Categorias de Contas</h3>
                            <div class="card-tools">
                                <button type="button" class="btn btn-sm btn-primary" @click="newModal" title="cadastrar nova">
                                    <i class="fa fa-plus-square"></i>
                                    NOVA CATEGORIA
                                </button>
                            </div>
                        </div>
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>Categoria</th>
                                    <th>Categoria Pai</th>
                                    <th>Tipo</th>
                                    <th>Ação</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="categoria in categorias.data" :key="categoria.id">
                                    <td>{{ categoria.nome }}</td>
                                    <td>{{ categoria.categoria_pai }}</td>
                                    <td>
                                        <span class="badge "
                                              :class="categoria.tipo === 'R' ? 'badge-success' : 'badge-danger'"
                                        >
                                            <i class="fas "
                                               :class="categoria.tipo === 'R' ? 'fa-arrow-alt-circle-up' : 'fa-arrow-alt-circle-down'"
                                            ></i>
                                            {{ categoria.tipo === 'R' ? 'Receita' : 'Despeza' }}
                                        </span>
                                    </td>
                                    <td>
                                        <a href="#" @click="editModal(categoria)" class="btn btn-sm btn-primary" title="editar">
                                            <i class="fa fa-edit"></i>
                                        </a>
                                        <a href="#" @click="deleteUser(categoria.id)" class="btn btn-sm btn-danger" title="excluir">
                                            <i class="fa fa-trash"></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <no-records v-if="categorias.data.length <= 0"></no-records>
                            <pagination :data="categorias" @pagination-change-page="getResults"></pagination>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" v-show="!editmode">Criar Nova Categoria</h5>
                            <h5 class="modal-title" v-show="editmode">Atualizar Informações da Categoria</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <form @submit.prevent="editmode ? updateCategoria() : createCategoria()">

                            <div class="modal-body">

                                <div class="form-group">
                                    <label for="tipo">Tipo</label>
                                    <select name="tipo"
                                            v-model="form.tipo"
                                            id="tipo"
                                            class="form-control"
                                            :class="{ 'is-invalid': form.errors.has('tipo') }"
                                    >
                                        <option value="R">Receita</option>
                                        <option value="D">Despeza</option>
                                    </select>
                                    <has-error :form="form" field="type"></has-error>
                                </div>

                                <div class="form-group">
                                    <label for="name">Nome</label>
                                    <input v-model="form.nome"
                                           type="text"
                                           name="name"
                                           id="name"
                                           class="form-control"
                                           :class="{ 'is-invalid': form.errors.has('name') }"
                                    >
                                    <has-error :form="form" field="name"></has-error>
                                </div>

                                <div class="form-group">
                                    <label for="categoria_id">Categoria Pai</label>
                                    <select name="categoria_id"
                                            v-model="form.categoria_id"
                                            id="categoria_id"
                                            class="form-control"
                                            :class="{ 'is-invalid': form.errors.has('categoria_id') }"
                                    >
                                        <option
                                            v-for="categoria in categorias.data"
                                            :value="categoria.id"
                                            :selected="categoria.id == form.categoria_id"
                                        >
                                            {{ categoria.nome }}
                                        </option>
                                    </select>
                                    <has-error :form="form" field="type"></has-error>
                                </div>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                <button type="submit" class="btn btn-success">Salvar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import Categoria from "../../../domain/financeiro/Categoria";

export default {
    components: {
    },
    data() {
        return {
            editmode: false,
            categorias: {
                data: []
            },
            form: new Form(
                new Categoria()
            ),
        }
    },
    methods: {
        loadCategorias() {
            this.$Progress.start();
            axios.get(`/api/financeiro/categoria`).then(({data}) => (this.categorias = data.data));
            this.$Progress.finish();
        },
        getResults(page = 1) {
            this.$Progress.start();
            axios.get(`/api/financeiro/categoria?page=${page}`).then(({data}) => (this.categorias = data.data));
            this.$Progress.finish();
        },
        createCategoria() {
            this.form.post('/api/financeiro/categoria')
                .then((response) => {
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();
                    this.loadCategorias();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
        updateCategoria() {
            this.$Progress.start();
            this.form.put(`/api/financeiro/categoria/${this.form.id}`)
                .then((response) => {
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();
                    this.loadCategorias();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
        },
        editModal(categoria) {
            this.editmode = true;
            this.form.reset();
            $('#addNew').modal('show');
            this.form.fill(categoria);
        },
        newModal() {
            this.editmode = false;
            this.form.reset();
            $('#addNew').modal('show');
        },
        deleteUser(id) {
            Swal.fire({
                title: 'Tem certeza?',
                text: "Você não poderá restaurar depois!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Sim, excluir!'
            }).then((result) => {
                if (result.value) {
                    this.form.delete(`/api/financeiro/categoria/${id}`).then(() => {
                        Toast.fire(
                            'Excluído!',
                            'A categoria foi excluída.',
                            'success'
                        );
                        this.loadCategorias();
                    }).catch((data) => {
                        Swal.fire("Erro!", data.message, "error");
                    });
                }
            })
        },

    },
    mounted() {

    },
    created() {
        this.$Progress.start();
        this.loadCategorias();
        this.$Progress.finish();
    }
}
</script>
