<template>
    <div class="row justify-content-center">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="col">
                        <div class="form-group">
                            <label for="categories">Select your categories.</label>
                            <multiselect v-model="selectedCategories" tag-placeholder="Selecione as categorias de vídeos da sua academia."
                                         placeholder="Selecione as categorias" label="label"
                                         track-by="code"
                                         :options="categories"
                                         :multiple="true"
                                         :taggable="true"
                                         @select="setSelected"
                                         @remove="setSelected"
                            ></multiselect>

<!--                            <v-select-->
<!--                                multiple-->
<!--                                v-model="selectedCategories"-->
<!--                                :options="categories"-->
<!--                                @input="setSelected"-->
<!--                                id="categories"-->
<!--                            />-->
                        </div>
                        <button type="button" class="btn btn-sm btn-outline-dark float-right" @click="categoriaModal">Pedir nova categoria</button>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade" id="pedirCategoria" tabindex="-1" role="dialog" aria-labelledby="pedirCategoria" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Pedir nova categoria</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <form @submit.prevent="enviarPedido()">
                        <div class="modal-body">
                            <div class="form-group">
                                <label for="categoria">Nome da Categoria</label>
                                <input v-model="form.categoria" type="text" name="categoria" id="categoria"
                                       class="form-control"
                                       :class="{ 'is-invalid': form.errors.has('categoria') }">
                                <has-error :form="form" field="categoria"></has-error>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                            <button type="submit" class="btn btn-success">Enviar Pedido</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
name: "CategoriasVideo",
    components: {
        Multiselect
    },
    data() {
        return {
            categories: [],
            selectedCategories: [],
            form: new Form({
                categoria: ''
            })
        }
    },
    methods: {
        loadCategories() {
            axios.get("api/videoCategories").then(({data}) => {
                this.categories =Object.values(JSON.parse(JSON.stringify(data.data.categories)));
                this.selectedCategories = Object.values(JSON.parse(JSON.stringify(data.data.selectedCategories)));
                this.carregaCategorias()
            });
        },
        setSelected(values) {
            this.$Progress.start();

            axios.put('api/videoCategories', values).then((data) => {
                Toast.fire({
                    icon: 'success',
                    title: data.data.message
                });
                this.carregaCategorias()
            }).catch((data) => {
                Toast.fire({
                    icon: 'error',
                    title: 'Erro! Categoria não adicionada, contate o suporte.'
                });
            }).finally(() => {
                this.$Progress.finish();
            });
        },
        categoriaModal() {
            this.form.reset();
            $('#pedirCategoria').modal('show');
        },
        enviarPedido() {
            this.$Progress.start();

            this.form.post('api/videoCategories')
                .then((data) => {
                    if (data.data.success) {
                        $('#pedirCategoria').modal('hide');
                        Toast.fire({
                            icon: 'success',
                            title: data.data.message
                        });
                        this.$Progress.finish();
                    } else {
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                        this.$Progress.failed();
                    }
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                    this.$Progress.failed();
                })
        },
        carregaCategorias(){
            this.$emit('categories', this.selectedCategories)
        }
    },
    created() {
        this.$Progress.start();
        this.loadCategories();
        this.$Progress.finish();
    },
    mounted() {
    }
}
</script>

<style scoped>

</style>
