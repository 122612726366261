<template>
    <div class="row">
        <div class="col col-md-6">
            <div class="info-box bg-gradient-info">
                <span class="info-box-icon"><i class="fas fa-check"></i></span>
                <div class="info-box-content">
                    <span class="info-box-text">Soma dos Valores</span>
                    <span class="info-box-number">{{ valor.total | currency }}</span>
                </div>
            </div>
        </div>

        <div class="col col-md-6">
            <div class="info-box bg-gradient-success">
                <span class="info-box-icon"><i class="fas fa-check-double"></i></span>
                <div class="info-box-content">
                    <span class="info-box-text">Soma dos Valores Pagos</span>
                    <span class="info-box-number">{{ valor.total_pago | currency }}</span>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "CardValores",
    props: {
        valores: {}
    },
    data() {
        return {
            valor: {
                total_pago: 0,
                total: 0
            }
        }
    },
    watch: {
        valores: function (newVal, oldVal) {
            this.valor = newVal
        },
    },
}
</script>

<style scoped>

</style>
