<template>
    <div>

        <table class="table table-striped" id="items_table">
            <thead>
                <tr>
                    <th style="width: 10px">#</th>
                    <th> Produto </th>
                    <th> Quantidade </th>
                    <th> Valor </th>
                    <th> Subtotal </th>
                    <th style="width: 40px">&nbsp;</th>
                </tr>
            </thead>
            <tbody class="repeatable-container">

                <tr v-for="(input,index) in items" :key="index">
                    <td>#{{index}}</td>
                    <td>
                        <div class="form-group">
                            <select
                                name="produto_id"
                                v-model="input.produto_id"
                                @change="produto($event.target.value,index)"
                                class="form-control"
                                style='width: 100%;'
                                :disabled="status"
                            >
                                <option
                                    v-for="produto in produtos"
                                    :key="produto.id"
                                    :value="produto.id"
                                >
                                    {{ produto.nome }}
                                </option>
                            </select>
                        </div>
                    </td>
                    <td>
                        <div class="form-group">
                            <input
                                name="quantidade"
                                v-model="input.quantidade"
                                @change="subtotal($event.target.value, index)"
                                @keyup="subtotal($event.target.value, index)"
                                class="form-control"
                                type="number"
                                min="1"
                                :disabled="status"
                            />
                        </div>
                    </td>
                    <td>
                        <div class="form-group">
                            <money
                                v-model="input.valor"
                                v-bind="subtotal(input.quantidade, index)"
                                class="form-control"
                                name="valor"
                                :disabled="status"
                            ></money>
                        </div>
                    </td>
                    <td>
                        <div class="form-group">
                            <money
                                v-model="input.total"
                                class="form-control"
                                name="total"
                                disabled
                            ></money>
                        </div>
                    </td>
                    <td>
                        <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            @click="remove(index)"
                            v-if="!status"
                        >
                            <i class="fas fa-trash"></i>
                        </button>
                    </td>
                </tr>

            </tbody>
            <tfoot>
                <tr>
                    <th colspan="6" class="text-right">
                        Quantidade total de produtos: <span id="totalProdutos">{{ totais.quantidade }}</span>
                    </th>
                </tr>
            </tfoot>
        </table>

        <div class="row">
            <div class="col text-center">
                <a
                    class="btn btn-success"
                    @click="add()"
                    v-show="totais.quantidade <= 15"
                    v-if="!status"
                >
                    <i class="fas fa-plus-circle"></i> Adicionar Produto
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Itens",
    props: {
        selectValue: {
            // type: [Object, Array, undefined],
            required: true
        }
    },
    data(){
        return {
            items: [],
            produtos:[],
            a: false,
        }
    },
    methods:{
        add() {
            this.items.push({produto_id: null, quantidade: 0, valor: 0, total: 0});
        },
        remove(index) {
            this.items.splice(index, 1);
        },
        produto(id,index){
            this.produtos.map((data) => {
                if(data.id == id) {
                    this.items[index].produto_id = data.id;
                    this.items[index].quantidade = data.quantidade ? data.quantidade : 1;
                    this.items[index].valor = data.preco_venda;
                    this.items[index].total = data.preco_venda * this.items[index].quantidade;
                }
            });
        },
        subtotal(quantidade, index) {
            this.items[index].total = this.items[index].valor * quantidade;
        },
        loadItens() {
            this.$Progress.start();
            axios.get(`/api/financeiro/produto`).then(({data}) => {
                this.produtos = data.data.data;
            });
            this.$Progress.finish();
        }
    },
    mounted(){
        this.loadItens();
        this.items = [];
    },
    created() {
        this.items = [];
    },
    computed: {
        totais() {
            let total = this.items.reduce((a, b) => parseFloat(a) + parseFloat(b['total']), 0);
            let data = {
                itens: this.items,
                total: total,
                subtotal: total,
                quantidade: this.items.length
            }

            this.$emit('selected', data);

            return data;
        },
        status: {
            get: function () {
                return this.a;
            },
            set: function (v) {
                this.a = v;
            }
        }
    },
    watch: {
        selectValue: function (newVal, oldVal) {
            this.a = (newVal.form.status > 0)
            this.items = newVal.form.produtos;
        }
    }
}
</script>

