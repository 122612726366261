<template>
  <div>
      <div class="card-info mt-3">
            <div class="card-header ui-sortable-handle" >
                <h3 class="card-title">Pagar.me</h3>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <form @submit.prevent="save()">
                        <div class="form-group">
                            <label>Ativar recebimento com Pagar.me?</label>
                            <select v-model="form.pagarme_status" name="pagarme_status" class="form-control">
                                <option value="0">Não</option>
                                <option value="1">Sim</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Chave de API do Pagar.me</label>
                            <input v-model="form.pagarme_partner_key" type="text" name="pagarme_partner_key" class="form-control">
                        </div>
                        <button type="submit" class="btn btn-success float-right">
                            Salvar
                        </button>
                    </form>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            form: new Form({
                pagarme_status: '',
                pagarme_partner_key: '',
            }),
        }
    },
    methods:{
        loadGateway(){
            this.$Progress.start();
                axios.get("/api/configuracao/pagarme").then(({data}) => {
                    this.form.fill(data.data);
                });
            this.$Progress.finish();
        },
        save(){
            this.$Progress.start();
            this.form.post('/api/configuracao/save')
                .then((data) => {
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    this.$Progress.finish();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
    },
    created(){
        this.loadGateway();
        console.log('Componente pagarme created')
    },
    mounted(){
        console.log('Componente pagarme mounte')
    },
}
</script>

<style>

</style>
