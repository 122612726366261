export default class Aluno {
    constructor(id= '',
                id_academia= '',
                type= '',
                nome= '',
                sobrenome= '',
                email= '',
                senha= '',
                email_verified_at= '',
                cpf= '',
                rg= '',
                roles= '',
                language= '',
                status= '',
                telefone= '',
                cep= '',
                endereco= {},
                numero= '',
                bairro= '',
                cidade= '',
                uf= '',
                sexo= '',
                responsavel= '',
                foto= '',
                complemento= '',
                martial_arts= [],
                graduacao= [],
                precensas= [],
                data_nascimento= ''
    ) {
        this.id = id;
        this.id_academia = id_academia;
        this.type = type;
        this.nome = nome;
        this.sobrenome = sobrenome;
        this.email = email;
        this.senha = senha;
        this.email_verified_at = email_verified_at;
        this.cpf = cpf;
        this.rg = rg;
        this.roles = roles;
        this.language = language;
        this.status = status;
        this.telefone = telefone;
        this.cep = cep;
        this.endereco = endereco;
        this.numero = numero;
        this.bairro = bairro;
        this.cidade = cidade;
        this.uf = uf;
        this.sexo = sexo;
        this.responsavel = responsavel;
        this.foto = foto;
        this.complemento = complemento;
        this.martial_arts = martial_arts;
        this.graduacao = graduacao;
        this.precensas = precensas;
        this.data_nascimento =data_nascimento;
    }
}
