<template>
     <div class="col-12">
        <div class="card card-primary card-outline card-tabs">
            <form @submit.prevent="save()">
                <div class="box box-primary box-solid">
                    <div class="box-header with-border m-3">
                        <h3 class="box-title">Configurações de Privacidade</h3>
                    </div>
                    <div class="box-body text-center">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Mostrar posts no aplicativo público?</label>
                                        <select v-model="form.posts_publicos" class="form-control" name="posts_publicos">
                                            <option value="0"> Não </option>
                                            <option value="1"> Sim </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Mostrar vídeos no aplicativo público?</label>
                                        <select v-model="form.videos_publicos" class="form-control" name="videos_publicos">
                                            <option value="0"> Não </option>
                                            <option value="1"> Sim </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Permitir que alunos façam seu próprio cadastro?</label>
                                        <select v-model="form.registro_alunos" class="form-control" name="registro_alunos">
                                            <option value="0"> Não </option>
                                            <option value="1"> Sim </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-success">
                            Atualizar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            form: new Form({
                posts_publicos: '',
                videos_publicos: '',
                registro_alunos: '',
            }),
        }
    },
    methods:{
        loadPrivacidade(){
            this.$Progress.start();
                axios.get("/api/configuracao/privacidade").then(({data}) => {
                    this.form.fill(data.data);
                });
            this.$Progress.finish();
        },
        save(){
            this.$Progress.start();
            this.form.post('/api/configuracao/save')
                .then((data) => {
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    this.$Progress.finish();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
    },
    created(){
        this.loadPrivacidade();
        console.log('Componente privacidade created')
    },
    mounted(){
        console.log('Componente privacidade mounte')
    },

}
</script>

<style>

</style>
