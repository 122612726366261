<template>
<section class="content">
            <div class="container-fluid">
                <div class="row">

                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Perfil: {{ aluno.name }}</h3>
                                <div class="card-tools">
                                    <router-link to="/alunos" class="btn btn-sm btn-warning">
                                        <i class="fas fa-backward"></i>
                                        Voltar
                                    </router-link>
                                </div>
                            </div>
                            <div class="card-body table-responsive">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-3">

                                        <div class="card card-primary card-outline">
                                            <div class="card-body box-profile">
                                                <div class="text-center">
                                                    <img class="profile-user-img img-fluid img-circle">
                                                </div>

                                                <h3 class="profile-username text-center"> {{ aluno.nome }} </h3>

                                                <p class="text-muted text-center"> {{ aluno.data_nascimento | moment("DD/MM/YYYY") }} </p>

                                                <ul class="list-group list-group-unbordered mb-3">

                                                </ul>

                                            </div>
                                            <!-- /.card-body -->
                                        </div>

                                        <div class="card card-primary">
                                            <div class="card-header">
                                                <h3 class="card-title"> Sobre </h3>
                                            </div>
                                            <!-- /.card-header -->
                                            <div class="card-body">
                                                <strong><i class="fas fa-user mr-1"></i> Responsavel </strong>
                                                <p v-if="aluno.resposavel != null" class="text-muted"> {{ aluno.resposavel }} </p>
                                                <p v-else class="text-muted"> <span  class="badge badge-info"> Sem dados</span> </p>

                                                <hr>
                                                <span v-for="faixa in aluno.faixas" v-html="faixa"></span>

                                                <hr>
                                                <strong><i class="fas fa-map-marker-alt mr-1"></i> Endereco</strong>

                                                <p class="text-muted">
                                                    <!-- {{ aluno.endereco.endereco }} -->
                                                    {{ aluno.numero }} - {{ aluno.bairro }} <br>
                                                    {{ aluno.cidade }} - {{ aluno.uf }} - {{ aluno.cep }}
                                                </p>

                                                <hr>

                                                <strong><i class="fas fa-user-ninja mr-1"></i>Artes Marcias</strong>

                                                <p v-if="modalidades.length > 0" class="text-muted">
                                                    <span v-for="modalidade in modalidades" :key="modalidade.id" class="badge badge-info mr-1">
                                                        {{ modalidade.nome }}
                                                    </span>
                                                </p>
                                                <p v-else>
                                                    <span class="badge badge-info"> Sem dados</span>
                                                </p>
                                            </div>
                                        </div>

                                        </div>


                                        <div class="col-md-9">
                                            <div class="card">
                                                <div class="card-header p-2">
                                                    <ul class="nav nav-pills">
                                                        <li class="nav-item">
                                                            <a class="nav-link" href="#dashboard" data-toggle="tab">
                                                                Painel de Controle
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a class="nav-link " href="#historico" data-toggle="tab">
                                                                Historico
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a class="nav-link " href="#notificacao" data-toggle="tab">
                                                                Notificações
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a class="nav-link " href="#contas" data-toggle="tab">
                                                                Contas
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a class="nav-link " href="#dados" data-toggle="tab">
                                                                Perfil
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a class="nav-link " href="#password" data-toggle="tab">
                                                                Senha
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="card-body">
                                                    <div class="tab-content">
                                                        <div class="tab-pane fade  show active" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                                                            <dashboard
                                                                :modalidades_aluno="modalidades"
                                                                :medalhas="aluno.medalhas"
                                                                :presencas="aluno.qtd_presencas"
                                                                :faixas="aluno.faixas"
                                                            ></dashboard>
                                                        </div>
                                                        <div class="tab-pane fade" id="historico" role="tabpanel" aria-labelledby="historico-tab">
                                                            <historicos></historicos>
                                                        </div>
                                                        <div class="tab-pane fade" id="notificacao" role="tabpanel" aria-labelledby="notificacao-tab">
                                                            <notificacoes></notificacoes>
                                                        </div>
                                                        <div class="tab-pane fade" id="contas" role="tabpanel" aria-labelledby="contas-tab">
                                                            <contas></contas>
                                                        </div>
                                                        <div class="tab-pane fade" id="dados" role="tabpanel" aria-labelledby="dados-tab">
                                                            <dados :dados="aluno"></dados>
                                                        </div>
                                                        <div class="tab-pane fade" id="password" role="tabpanel" aria-labelledby="password-tab">
                                                            <senha :selectValue="aluno.email"></senha>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
    </section>
</template>

<script>

import moment from 'moment';
import Dashboard from './resources/Dashboard.vue';
import Historicos from './resources/Historicos.vue';
import Notificacoes from './resources/Notificacoes.vue';
import Contas from './resources/Contas.vue';
import Dados from './resources/Dados.vue';
import Senha from './resources/Senha.vue';

export default {
    components: {
        Dashboard,
        Historicos,
        Notificacoes,
        Contas,
        Dados,
        Senha,
    },
    data(){
        return {
            aluno: {},
            modalidades: [],
        }
    },
    methods: {
         loadAluno() {
            this.$Progress.start();
                axios.get(`/api/aluno/${this.$route.params.id}`).then(({ data }) => { this.aluno = data.data });
            this.$Progress.finish()
        },
        loadModalidadesAluno(){
            this.$Progress.start();
                axios.get(`/api/aluno/modalidades/${this.$route.params.id}`).then(({ data }) => {this.modalidades = data.data});
            this.$Progress.finish();
        },
    },
    mounted() {
        console.log('User Component mounted.')
    },
    created() {
        this.$Progress.start();
        this.loadAluno();
        this.loadModalidadesAluno();
        this.$Progress.finish();
    }
}
</script>

<style scoped>

</style>
