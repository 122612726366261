<template>
    <div>
        <div class="card-body table-responsive p-0">
            <table class="table table-hover">
            <thead>
                <tr>
                    <th>Nome</th>
                    <th>Idade</th>
                    <th>Graduação</th>
                    <th>Quant Aulas</th>
                    <th>Ultima aula</th>
                    <th>Status</th>
                    <th>Ação</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(aluno, i) in alunos.data" :key="aluno.id">

                <td>
                    {{aluno.full_name}}
                </td>
                <td> {{aluno.idade}} </td>
                <td>
                    <span v-for="arte in aluno.artes_marciais" :key="arte.id">
                        {{ arte.graduacao }}
                    </span>
                </td>
                <td>
                    <span v-for="arte in aluno.artes_marciais" :key="arte.id">
                        {{ arte.presencas_graduacao }} <br>
                    </span>
                </td>
                <td>
                    <span v-for="arte in aluno.artes_marciais" :key="arte.id">
                        {{ arte.data_ultima_presenca | moment("D/MM/YYYY") }} <br>
                    </span>
                </td>
                <td>
                    <span :value="aluno.status" @click="mudaStatus(aluno.id)" class="badge "
                        v-bind:class="aluno.status == 'Y' ? 'badge-success' : 'badge-danger'">
                    {{ aluno.status == 'Y' ? 'Active' : 'Inactive' }}
                    </span>
                </td>

                <td>
                    <!-- <a href="#" @click="graduar(aluno.id)" class="btn btn-sm btn-primary" title="graduar">
                        Graduar
                    </a> -->
                    <graduar-button
                        :id_aluno="aluno.id"
                        :artes="artes[i]"
                        v-on:open="modal = $event"
                    >
                    </graduar-button>

                    <ModalGraduar
                        :openModal="modal"
                        v-on:close="modal = $event"
                    >
                    do {{ aluno.full_name }}
                    </ModalGraduar>
                </td>
                </tr>
            </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import GraduarButton from "../../common/GraduarButton.vue";
import ModalGraduar from "../../common/ModalGraduar.vue";
export default {
    name: "Alunos",
    components:{
        GraduarButton,
        ModalGraduar,
    },
    data(){
        return{
            alunos: {
                data: []
            },
            artes: [
                [{id: 1, label: 'jiui-jitsu'},{id: 2, label: 'karate'}],
                [{id: 3, label: 'teste'},{id: 4, label: 'teste'}],
                [{id: 5, label: '22222'},{id: 6, label: '222222'}]
            ],
            modal: {status: 0},
        }
    },
    methods:{
        getResults(page = 1) {
            this.$Progress.start();

            axios.get(`/api/aluno/${this.$route.params.id}/academia?page=` + page).then(({ data }) => (this.alunos = data.data ));

            this.$Progress.finish();
        },
        loadAlunos() {
            this.$Progress.start();
            axios.get(`/api/aluno/${this.$route.params.id}/academia`).then(({ data }) => {this.alunos = data.data });
            this.$Progress.finish()
        },
        graduar(id){
            this.$Progress.start();

            axios.post(`/api/graduar/${id}`)
                .then((data) => {
                    if (!data.data.success) {
                        Toast.fire({
                            icon: 'error',
                            title: 'Ocorreu um erro! Por favor, tente novamente.'
                        });
                        this.$Progress.failed();
                        return;
                    }
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    this.$Progress.finish();
                    this.loadAlunos();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
        mudaStatus(id){
            this.$Progress.start();

            axios.put(`/api/alunoStatus/${id}/${this.$route.params.id}`)
                .then((data) => {
                    if (!data.data.success) {
                        Toast.fire({
                            icon: 'error',
                            title: 'Ocorreu um erro! Por favor, tente novamente.'
                        });
                        this.$Progress.failed();
                        return;
                    }
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    this.$Progress.finish();
                    this.loadAlunos();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        }
    },
    mounted(){},
    created(){
        this.loadAlunos();
        console.log('Alunos Component created');
    },
}
</script>

<style scoped>

</style>
