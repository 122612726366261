<template>
    <section class="content">
        <div class="container-fluid">

            <CardValores :valores="valor"/>

            <div class="row">

                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Contas A Receber</h3>
                            <div class="card-tools">
                                <button class="btn btn-sm btn-danger" title="adicionar filtros" type="button"
                                        @click="aplicaFiltros">
                                    <i class="fa fa-filter"></i>
                                    FILTRAR
                                </button>

                                <button class="btn btn-sm btn-primary" title="cadastrar nova" type="button"
                                        @click="newModal">
                                    <i class="fa fa-plus-square"></i>
                                    ADICIONAR
                                </button>
                            </div>
                        </div>
                        <div class="card-body p-0">

                            <Filtros
                                :abrir="mostrarFiltros"
                                v-on:filtros="setFiltros($event)"
                            />

                            <table class="table table-hover table-striped">
                                <thead>
                                <tr>
                                    <th>Pagador</th>
                                    <th>Valor</th>
                                    <th>Valor Pago</th>
                                    <th>Data Vencimento</th>
                                    <th>Data Pagamento</th>
                                    <th>Status</th>
                                    <th>Ação</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="conta in contas.data" :key="conta.id">
                                        <td>{{ conta.usuario_nome }}</td>
                                        <td>{{ conta.valor | currency }}</td>
                                        <td>{{ conta.valor_pago | currency }}</td>
                                        <td>{{ conta.data_vencimento | moment("DD/MM/YYYY") }}</td>
                                        <td>{{ conta.data_pagamento | moment("DD/MM/YYYY") }}</td>
                                        <td>
                                            <span :value="conta.status"
                                                  class="badge cursor-pointer"
                                                  :class="conta.status ? 'badge-success' : 'badge-danger'"
                                                  @click="mudaStatus(conta.id)"
                                            >
                                                {{ conta.status ? 'Pago' : 'Não Pago' }}
                                            </span>
                                        </td>
                                        <td>
                                            <button
                                                type="button"
                                                class="btn btn-sm btn-primary"
                                                title="editar"
                                                @click="editModal(conta)"
                                            >
                                                <i class="fa fa-edit"></i>
                                            </button>
                                            <button
                                                type="button"
                                                class="btn btn-sm btn-danger"
                                                title="excluir"
                                                @click="deleteBanco(conta.id)"
                                            >
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <no-records v-if="contas.data.length <= 0"></no-records>
                            <pagination :data="contas" @pagination-change-page="getResults"></pagination>
                        </div>
                    </div>
                </div>
            </div>


            <div id="addNew" aria-hidden="true" aria-labelledby="addNew" class="modal fade" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 v-show="!editmode" class="modal-title">Criar Nova Conta A Receber</h5>
                            <h5 v-show="editmode" class="modal-title">Atualizar Informações da Conta A Receber</h5>
                            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <form @submit.prevent="editmode ? updateConta() : createConta()">

                            <div class="modal-body">

                                <div class="row">
                                    <div class="col-8">
                                        <div class="form-group">
                                            <SelectPessoa
                                                :class="{ 'is-invalid': form.errors.has('usuario_id') }"
                                                :selectValue="{id: form.usuario_id, table: form.table, name: form.usuario_nome}"
                                                title="Recebedores"
                                                v-on:selected="form.usuario_id = $event.id; form.table = $event.table;"
                                            />
                                            <has-error :form="form" field="usuario_id"></has-error>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <SelectCategoria
                                                :class="{ 'is-invalid': form.errors.has('categoria_id') }"
                                                :selectValue="form.categoria_id"
                                                v-on:selected="form.categoria_id = $event.id;"
                                                tipo="R"
                                            />
                                            <has-error :form="form" field="categoria_id"></has-error>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <SelectBanco
                                                :class="{ 'is-invalid': form.errors.has('banco_id') }"
                                                :selectValue="form.banco_id"
                                                v-on:selected="form.banco_id = $event.id;"
                                            />
                                            <has-error :form="form" field="banco_id"></has-error>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <SelectFormaDePagamento
                                                :class="{ 'is-invalid': form.errors.has('forma_pagamento') }"
                                                :selectValue="form.forma_pagamento"
                                                @selected="form.forma_pagamento = $event.id;"
                                            />
                                            <has-error :form="form" field="forma_pagamento"></has-error>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="valor">Valor</label>
                                            <money
                                                id="valor"
                                                v-model="form.valor"
                                                :class="{ 'is-invalid': form.errors.has('valor') }"
                                                class="form-control"
                                                name="valor"
                                                @keyup="calculaTotal"
                                            ></money>
                                            <has-error :form="form" field="valor"></has-error>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="data_competencia">Data de Competência</label>
                                            <input
                                                id="data_competencia"
                                                v-model="form.data_competencia"
                                                :class="{ 'is-invalid': form.errors.has('data_competencia') }"
                                                class="form-control"
                                                name="data_competencia"
                                                type="date"
                                            >
                                            <has-error :form="form" field="data_competencia"></has-error>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="data_vencimento">Data de Vencimento</label>
                                            <input
                                                id="data_vencimento"
                                                v-model="form.data_vencimento"
                                                :class="{ 'is-invalid': form.errors.has('data_vencimento') }"
                                                class="form-control"
                                                name="data_vencimento"
                                                type="date"
                                            >
                                            <has-error :form="form" field="data_vencimento"></has-error>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="status">Pago</label><br>
                                            <b-form-checkbox
                                                v-model="form.status"
                                                @change="calculaTotal"
                                                name="check-button"
                                                button
                                                :button-variant="!form.status ? 'success' : 'danger'"
                                            >
                                                {{ !form.status ? 'Pagar' : 'Pago' }}
                                            </b-form-checkbox>

                                            <has-error :form="form" field="status"></has-error>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="detalhes">Informações Adicionais</label>
                                            <input id="detalhes"
                                                   v-model="form.detalhes"
                                                   :class="{ 'is-invalid': form.errors.has('detalhes') }"
                                                   class="form-control"
                                                   name="detalhes"
                                                   type="text"
                                            >
                                            <has-error :form="form" field="detalhes"></has-error>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="form.status">
                                    <fieldset>
                                        <legend>Dados do Pagamento:</legend>

                                        <div class="row">
                                            <div class="col">
                                                <div class="form-group">
                                                    <label for="data_pagamento">Data de Pagamento</label>
                                                    <input id="data_pagamento"
                                                           v-model="form.data_pagamento"
                                                           :class="{ 'is-invalid': form.errors.has('data_pagamento') }"
                                                           class="form-control"
                                                           name="data_pagamento"
                                                           type="date"
                                                    >
                                                    <has-error :form="form" field="data_pagamento"></has-error>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="form-group">
                                                    <label for="desconto">Descontos</label>
                                                    <money
                                                        id="desconto"
                                                        v-model="form.desconto"
                                                        :class="{ 'is-invalid': form.errors.has('desconto') }"
                                                        class="form-control"
                                                        name="desconto"
                                                        v-bind="calculaTotal()"
                                                    ></money>
                                                    <has-error :form="form" field="desconto"></has-error>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="form-group">
                                                    <label for="acrescimo">Acréscimos</label>
                                                    <money
                                                        id="acrescimo"
                                                        v-model="form.acrescimo"
                                                        :class="{ 'is-invalid': form.errors.has('acrescimo') }"
                                                        class="form-control"
                                                        name="acrescimo"
                                                        v-bind="calculaTotal()"
                                                    ></money>
                                                    <has-error :form="form" field="acrescimo"></has-error>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="form-group">
                                                    <label for="valor_pago">Valor Pago</label>
                                                    <money
                                                        id="valor_pago"
                                                        v-model="form.valor_pago"
                                                        :class="{ 'is-invalid': form.errors.has('valor_pago') }"
                                                        class="form-control"
                                                        name="valor_pago"
                                                        readonly
                                                    ></money>
                                                    <has-error :form="form" field="valor_pago"></has-error>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>

                                </div>

                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-secondary" data-dismiss="modal" type="button">Fechar</button>
                                <button class="btn btn-success" type="submit">Salvar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>

    </section>
</template>

<script>
import moment from 'moment';
import SelectPessoa from "../common/SelectPessoa";
import SelectBanco from "../common/SelectBanco";
import SelectCategoria from "../common/SelectCategoria";
import SelectFormaDePagamento from "../common/SelectFormaDePagamento";
import Filtros from "../common/Filtros";
import Conta from "../../../domain/financeiro/Conta";
import CardValores from "../common/CardValores";

export default {
    components: {
        CardValores,
        Filtros,
        SelectPessoa,
        SelectCategoria,
        SelectBanco,
        SelectFormaDePagamento,
    },
    data() {
        return {
            editmode: false,
            mostrarFiltros: false,
            contas: {
                data: []
            },
            filtros: {},
            valor: {
                total: 0,
                total_pago: 0
            },
            form: new Form(
                new Conta()
            ),
        }
    },
    methods: {
        setFiltros(data){
            this.filtros = Object.keys(data)
                .map(key => `${key}=${data[key] != undefined ? data[key] : ''}`)
                .join('&');
            this.getResults(1);
        },
        loadContas() {
            this.getResults();
        },
        getResults(page = 1) {
            this.$Progress.start();
            axios.get(`/api/financeiro/receber?page=${page}&${this.filtros}`).then(({data}) => (this.contas = data.data));
            axios.get(`/api/financeiro/receber_valor?${this.filtros}`).then(({data}) => (this.valor = data.data));

            this.$Progress.finish();
        },
        createConta() {
            this.calculaTotal();
            this.form.post('/api/financeiro/receber')
                .then((response) => {
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();
                    this.loadContas();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
        updateConta() {
            this.calculaTotal();
            this.$Progress.start();
            this.form.put(`/api/financeiro/receber/${this.form.id}`)
                .then((response) => {
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();
                    this.loadContas();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
        },
        editModal(conta) {
            this.editmode = true;
            this.form.reset();
            $('#addNew').modal('show');
            this.form.fill(conta);
        },
        newModal() {
            this.editmode = false;
            this.form.reset();
            $('#addNew').modal('show');
        },
        deleteBanco(id) {
            Swal.fire({
                title: 'Tem certeza?',
                text: "Você não poderá restaurar depois!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Sim, excluir!'
            }).then((result) => {
                if (result.value) {
                    this.form.delete(`/api/financeiro/receber/${id}`).then(() => {
                        Toast.fire(
                            'Excluído!',
                            'A conta foi excluída.',
                            'success'
                        );
                        this.loadContas();
                    }).catch((data) => {
                        Swal.fire("Erro!", data.message, "error");
                    });
                }
            })
        },
        mudaStatus(id) {
            this.$Progress.start();
            this.form.put(`/api/contaStatus/${id}`)
                .then((data) => {
                    if (!data.data.success) {
                        Toast.fire({
                            icon: 'error',
                            title: 'Ocorreu um erro! Por favor, tente novamente.'
                        });
                        this.$Progress.fail();
                        return;
                    }
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    this.$Progress.finish();
                    this.loadContas();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
        calculaTotal() {
            const d = new Date();

            if (this.form.status === true) {
                let desconto = parseFloat(this.form.desconto) ?? 0;
                let acrescimo = parseFloat(this.form.acrescimo) ?? 0;
                let valor = parseFloat(this.form.valor) ?? 0;
                this.form.data_pagamento = d.toISOString().split('T')[0];
                this.form.valor_pago = (valor - desconto) + acrescimo;
            } else {
                this.form.valor_pago = 0;
                this.form.acrescimo = 0;
                this.form.desconto = 0;
                this.form.data_pagamento = null;
            }
        },
        aplicaFiltros() {
            this.mostrarFiltros = !this.mostrarFiltros;
        },
    },
    created() {
        this.$Progress.start();
        this.loadContas();
        this.$Progress.finish();
    },
}
</script>
