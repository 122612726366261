<template>
    <div>

        <blockquote class="quote-success">
            <p>Selecione um modelo para usar e baixe o esboço</p>
        </blockquote>

        <form @submit.prevent="createCertificado()">
            <div class="form-group">
                <label> Modelo do certificado </label>
                <select v-model="form.certificado" name="certificado" id="certificado"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('certificado') }">
                    <option v-for="modelo in modelos" :key="modelo.id" :value="modelo.id"> {{ modelo.nome }} </option>
                </select>
                <has-error :form="form" field="certificado"></has-error>
            </div>

            <div class="form-group">
                <div class="custom-file">
                    <label> Image </label>
                    <input type="file" name="imagem" id="imagem"
                        class="form-control" :class="{ 'is-invalid': form.errors.has('imagem') }"
                        @change="createCertificado">
                    <has-error :form="form" field="imagem"></has-error>
                </div>
                <small>
                    Selecione e faça upload do seu certificado no tamanho 1280x960px.
                </small>
            </div>

            <div class="form-group">
                <button type="submit" class="btn btn-success float-right">
                    Salvar
                </button>
            </div>

        </form>

        <p class="text-danger text-center">
            Os modelos de certificados diferem-se em fontes e ordem de texto, preste muita atenção ao modelo escolhido para que se adapte melhor ao modelo que você usa atualmente como certificado na sua academia ou faça download de um dos nossos modelos e adapte para sua realidade.
        </p>

        <div class="row">
            <div v-for="certificado in certificados" :key="certificado.id" class="col-md-6">
                <div class="card card-widget">
                    <div class="card-header">
                        <span class="description">Compartilhado publicamente - {{ certificado.data }}</span>
                    </div>
                    <div class="card-body">
                        <img class="img-fluid pad" :src="certificado.img" alt="Photo">
                        <hr>
                        <button @click="visualizar(certificado)" type="button" class="btn btn-sm"><i class="fas fa-eye"></i> Visualizar</button>
                        <button @click="download(certificado)" type="button" class="btn btn-default btn-sm"><i class="fas fa-download"></i> Download</button>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
export default {
    name: "Certificado",
    data(){
        return{
            certificados: [
                {id: 1, data: '21/04/2019', img: 'http://lorempixel.com.br/500/300/?1'},
                {id: 1, data: '21/09/2020', img: 'http://lorempixel.com.br/500/300/?2'},
                {id: 1, data: '21/12/2021', img: 'http://lorempixel.com.br/500/300/?3'},
            ],
            modelos: [
                {id: 1, nome: '1'},
                {id: 2, nome: '2'},
                {id: 3, nome: '3'},
            ],
            form: new Form({
                modelo: '',
                imagem: '',
            }),
        }
    },
    methods:{
        createCertificado(){
            console.log(this.form);
            return false;
            this.$Progress.start();
            this.form.post(`api/academia/${this.$route.param.id}/certificados`)
            .then((response) => {
                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                });
                this.$Progress.finish();
            })
            .catch(() => {
                this.$Progress.fail();
            });

        },
        loadCertificados(){
            this.$Progress.start;
            axios.get(`api/academia/${this.$route.param.id}/certificados`).then((data) => { this.certificados = data.data})
            this.$Progress.finish;
        },
        loadModelos(){
            this.$Progress.start;
            axios.get(`api/academia/${this.$route.param.id}/certificados/modelos`).then((data) => { this.modelos = data.data})
            this.$Progress.finish;
        },
        visualizar(certificado){
            console.log('visualizar');
        },
        download(certificado){
            console.log('download');
        },
    },
    created(){
        // this.loadCertificados();
        // this.loadModelos();
    },
    mounted(){
        console.log('Componente montado!');
    }
}
</script>

<style scoped>

</style>
