import { HorizontalBar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
    extends: HorizontalBar,
    mixins: [reactiveProp],
    props: {
        options: {
            maintainAspectRatio:false,
            scales:{
                xAxes:[],
                yAxes:[{
                    ticks:{
                        beginAtZero:true
                    }
                }]
            },
            responsive:true
        },
        chartData: {}
    },
    mounted () {
        this.renderChart(this.chartData, this.options)
    }
}
