<template>
    <div>
        <form @submit.prevent="updatePassword()">

            <blockquote class="quote-danger">
                <p>Cuidado ao alterar a senha de acesso!</p>
            </blockquote>

            <div class="form-group">
                <label>Email</label>
                <input v-model="form.email" type="email" name="email" disabled
                    class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                <has-error :form="form" field="email"></has-error>
            </div>

            <div class="form-group">
               <label>Senha</label>
                <input v-model="form.password" type="password" name="password"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('password') }">
                <has-error :form="form" field="password"></has-error>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col">
                        <button type="submit" class="btn btn-success float-right">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>

        </form>
    </div>
</template>

<script>
export default {
    name: "Senha",
    props:{
        email: {type: String},
        endpoint: {type: String}
    },
    data(){
        return{
            form: new Form({
                email: '',
            }),
        }
    },
    methods:{
        updatePassword(){
            console.log(this.form, this.$route.params.id);
            this.$Progress.start();
                this.form.post(`/api/${this.endpoint}/${this.$route.params.id}/changepassword`)
                .then((response) => {
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();

                })
                .catch(() => {
                    this.$Progress.fail();
                });
        },
    },
    created(){
    },
    mounted(){
        console.log("Senha componente mounted");
    },
    watch: {
        email: function (newVal, oldVal) {
            this.form.email = newVal;
        },
        endpoint: function (newVal, oldVal) {
            this.endpoint = newVal;
        }
    }
}
</script>

<style scoped>

</style>
