<template>
  <div class="col-12">
        <div class="card card-primary card-outline card-tabs">
            <div class="card-header p-0 pt-1 border-bottom-0">
                <ul class="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                    <li v-for="modalidade in modalidades" :key="modalidade.table" class="nav-item">
                        <a
                            class="nav-link"  :class="{ 'disabled text-muted': !modalidade.status }"
                            :id="'custom-tabs-three-'+modalidade.table+'-tab'"
                            data-toggle="pill"
                            :href="'#custom-tabs-three-'+modalidade.table"
                            role="tab"
                             @click="selectModalidade(modalidade)"
                            :aria-controls="'custom-tabs-three-'+modalidade.table"
                            aria-selected="true">
                            {{ modalidade.nome }}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <div class="tab-content">
                    <div v-for="modalidade in modalidades" :key="modalidade.table" role="tabpanel" class="tab-pane" :id="'custom-tabs-three-'+modalidade.table">
                        <form @submit.prevent="save()">
                            <div v-if="modalidade.status" class="row">
                                    <div v-for="input in inputs" :key="input.campo" class="col-3">
                                        <div class="form-group">
                                            <label :for="input.campo">{{ modalidade.campos_nome[input.campo] }} *</label>
                                            <input v-model="input.valor" :id="input.campo" :name="input.campo"
                                                type="number" class="form-control"
                                                :disabled="input.campo === 'adulto_coral_branca' || input.campo === 'adulto_coral_preta' ? '' : null"
                                            >
                                        </div>
                                    </div>
                            </div>
                            <p v-else>
                                Modalidade não tem campos de configuração.
                            </p>
                        </form>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <button class="btn btn-success" @click="save" id="saveChanges">
                    Atualizar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            modalidades: [],
            inputs: [],
            form: new Form({
                campos: '',
                name_table: '',
            }),
        }
    },
    methods:{
        loadModalidades(){
            this.$Progress.start();
                axios.get("/api/configuracao/modalidades").then(({ data }) => {
                    this.modalidades = data.data;
                });
            this.$Progress.finish();
        },
        selectModalidade(modalidade){
            this.inputs = [];
            this.form.name_table = modalidade.table;
            for (var [key, value] of Object.entries(modalidade.campos)) {
                this.inputs.push({campo: key, valor: value});
            };
        },
        save(){
            this.form.campos = this.inputs;
            this.$Progress.start();
            this.form.post(`/api/configuracao/modalidades/save`)
                .then((data) => {
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    this.loadModalidades();
                    this.$Progress.finish();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
    },
    created(){
        this.loadModalidades();
        console.log('Config modalidades component created')
    },
    mounted(){
        console.log('Config modalidades component mounted')
    },

}
</script>

<style>

</style>
