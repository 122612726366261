<template>
    <div>
        <div class="col p-3" id="modalFiltros" style="display:none" >
            <form @submit.prevent="filtrar()">

                <div class="row">
                    <div class="col">
                        <SelectPessoa
                            :selectValue="{id: form.usuario_id, table: form.table, name: form.usuario_nome}"
                            title="Recebedores"
                            v-on:selected="setUserData"
                        ></SelectPessoa>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="form-group">
                            <label for="periodo">Período</label>
                            <select class="form-control" v-model="form.periodo" id="periodo">
                                <option
                                    :value="periodo.code"
                                    v-for="periodo in periodos"
                                    :selected="periodo.code == form.periodo"
                                >
                                    {{ periodo.label }}
                                </option>
                            </select>
                            <has-error :form="form" field="periodo"></has-error>
                        </div>
                    </div>
                    <div class="col-6 col-md-4">
                        <div class="form-group">
                            <label for="search_data_inicio">De:</label>
                            <input id="search_data_inicio"
                                   v-model="form.search_data_inicio"
                                   class="form-control"
                                   name="search_data_inicio"
                                   type="date"
                            >
                        </div>
                    </div>
                    <div class="col-6 col-md-4">
                        <div class="form-group">
                            <label for="search_data_final">Até</label>
                            <input id="search_data_final"
                                   v-model="form.search_data_final"
                                   class="form-control"
                                   name="search_data_final"
                                   type="date"
                            >
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-5" v-if="!vendas">
                        <div class="form-group">
                            <SelectCategoria
                                :selectValue="form.categoria_id"
                                v-on:selected="form.categoria_id = $event.id;"
                                tipo="D"
                            ></SelectCategoria>
                        </div>
                    </div>
                    <div class="col-12 col-md-5">
                        <div class="form-group">
                            <SelectFormaDePagamento
                                :selectValue="form.forma_pagamento"
                                v-on:selected="form.forma_pagamento = $event.id;"
                            ></SelectFormaDePagamento>
                        </div>
                    </div>
                    <div class="col col-md-2">
                        <div class="form-group">
                            <label for="status">Pago</label>
                            <select
                                class="form-control"
                                v-model="form.status"
                                id="status"
                            >
                                <option>-- Limpar --</option>
                                <option value="1">Sim</option>
                                <option value="0">Não</option>
                            </select>
                        </div>
                    </div>
                </div>

                <button class="btn btn-success float-right" type="submit">Filtrar</button>
                <div class="clearfix"></div>
            </form>
        </div>

        <div class="m-4">
            <b-breadcrumb v-if="filterBy.length > 0">
                <b-breadcrumb-item active>
                    <b-icon icon="filter"></b-icon>
                    Filtrado por:
                </b-breadcrumb-item>
                <b-breadcrumb-item active v-for="filter in filterBy"  v-bind:key="filter.title">
                    {{ filter.title }}: {{ filter.value }}
                </b-breadcrumb-item>
            </b-breadcrumb>


        </div>
    </div>
</template>

<script>
import SelectPessoa from "./SelectPessoa";
import SelectCategoria from "./SelectCategoria";
import SelectBanco from "./SelectBanco";
import SelectFormaDePagamento from "./SelectFormaDePagamento";

export default {
    name: "Filtros",
    components: {
        SelectPessoa,
        SelectCategoria,
        SelectBanco,
        SelectFormaDePagamento,
    },
    props: {
        abrir: false,
        vendas: false,
    },
    data() {
        return {
            form: new Form({
                table: '',
                usuario_id: '',
                usuario_nome: '',
                periodo: 'este_mes',
                search_data_inicio: '',
                search_data_final: '',
                status: false,
                categoria_id: '',
                forma_pagamento: '',
            }),
            periodos: [
                { code: 'este_mes', label: 'Este Mês' },
                { code: 'ultimos_30_dias', label: 'Últimos 30 Dias' },
                { code: 'ultimo_mes', label: 'Mês Anterior' },
                { code: 'esta_semana', label: 'Esta Semana' },
                { code: 'personalizado', label: 'Personalizado' },
            ],
            filterBy: [],
        }
    },
    methods: {
        filtrar(){
            this.$emit('filtros', this.form)

            this.filterBy = [];
            if(this.form.usuario_nome) {
                this.filterBy.push({title: 'Recebedores', value: this.form.usuario_nome})
            }
            if(this.form.periodo) {
                this.periodos.forEach((data) => {
                    if(data.code == this.form.periodo)
                        this.filterBy.push({title: 'Período', value: data.label})
                })
            }
            if(this.form.search_data_inicio) {
                this.filterBy.push({title: 'Data Início', value: this.form.search_data_inicio })
            }
            if(this.form.search_data_final) {
                this.filterBy.push({title: 'Data Final', value: this.form.search_data_final })
            }
            if(this.form.status !== false) {
                this.filterBy.push({title: 'Status', value: this.form.status == 1 ? 'Sim' : 'Não'})
            }
            if(this.form.categoria_id) {
                this.filterBy.push({title: 'Categoria', value: this.form.categoria_id})
            }
            if(this.form.forma_pagamento) {
                this.filterBy.push({title: 'Forma de Pagamento', value: this.form.forma_pagamento})
            }

            this.abrirModal();
        },
        abrirModal() {
            $('#modalFiltros').slideToggle();
        },
        setUserData($event) {
            this.form.usuario_id = $event.id;
            this.form.table = $event.table;
            this.form.usuario_nome = $event.name;
        }
    },
    watch: {
        abrir: function () {
            this.abrirModal()
        },
    },
}
</script>
