<template>
    <div>

        <blockquote class="quote-danger">
            <p> Cuidado ao alterar a senha de acesso! </p>
        </blockquote>
        <form @submit.prevent="updatePassword()">
            <div class="form-group">
                <label>Email</label>
                <input v-model="form.email" type="email" name="email" disabled
                    class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
            </div>

            <div class="form-group">
                    <label>Senha</label>
                    <input v-model="form.password" type="password" name="password"
                        class="form-control" :class="{ 'is-invalid': form.errors.has('password') }">
                    <has-error :form="form" field="password"></has-error>
                <small>
                    Deixe em branco para não alterar a senha.
                </small>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col">
                        <button type="submit" class="btn btn-success float-right">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: "Senha",
    props: {
        selectValue: {
            type: String,
        },
    },
    data(){
        return{
            form: new Form({
                email: '',
                password: '',
            }),
        }
    },
    methods:{
        updatePassword(){
            if(this.form.password == '')
                return false;

            this.$Progress.start();
                this.form.put(`/api/aluno/${this.$route.params.id}/updatepassword`)
                .then((response) => {
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
        }
    },
    watch: {
        selectValue: function (email) {
            this.form.email = email;
        }
    },
    mounted(){},
    created(){},
}
</script>

<style scoped>

</style>
