<template>
    <section class="content">
        <div class="container-fluid">
            <div class="card card-solid">
                <div class="card-body">
                    <div class="row d-flex align-items-stretch">

                        <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
                            <router-link to="/alunos" class="nav-link" active-class="active">
                                <div class="info-box">
                                    <span class="info-box-icon bg-secondary"><i class="far fa-envelope"></i></span>

                                    <div class="info-box-content">
                                        <span class="info-box-number"> Academia </span>
                                        <p> Alterar dados da academia como nome, email, endereço, telefone e etc. </p>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
                            <router-link to="/configuracao/modalidades" class="nav-link" active-class="active">
                                <div class="info-box">
                                    <span class="info-box-icon bg-secondary"><i class="far fa-envelope"></i></span>

                                    <div class="info-box-content">
                                        <span class="info-box-number">Modalidades</span>
                                        <p>Ativar, desativar e configurarnumeros de presenas para cada modalidade</p>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
                            <router-link to="/configuracao/aplicativos" class="nav-link" active-class="active">
                                <div class="info-box text-center">
                                    <span class="info-box-icon bg-secondary"><i class="far fa-envelope"></i></span>

                                    <div class="info-box-content">
                                        <span class="info-box-number">Apps</span>
                                        <p>Alterar configuração do aplicativos para estudantes e professores</p>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
                            <router-link to="/alunos" class="nav-link" active-class="active">
                                <div class="info-box">
                                    <span class="info-box-icon bg-secondary"><i class="far fa-envelope"></i></span>

                                    <div class="info-box-content">
                                        <span class="info-box-number">Notificações</span>
                                        <p>Modifique o tempo médio de envio de noficações.</p>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
                            <router-link to="" class="nav-link" active-class="active">
                                <div class="info-box">
                                    <span class="info-box-icon bg-secondary"><i class="far fa-envelope"></i></span>

                                    <div class="info-box-content">
                                        <span class="info-box-number">Pagamentos e Recebimentos</span>
                                        <p>Defina os padrões de recebimento e pagamento.</p>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
                            <router-link to="/configuracao/gateways" class="nav-link" active-class="active">
                                <div class="info-box">
                                    <span class="info-box-icon bg-secondary"><i class="far fa-envelope"></i></span>

                                    <div class="info-box-content">
                                        <span class="info-box-number">Gateway de Pagamento</span>
                                        <p>Configurações de PagSeguro, PagHiper, Cielo.</p>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
                            <router-link to="/configuracao/recibo" class="nav-link" active-class="active">
                                <div class="info-box">
                                    <span class="info-box-icon bg-secondary"><i class="far fa-envelope"></i></span>

                                    <div class="info-box-content">
                                        <span class="info-box-number">Recibo</span>
                                        <p>Ativar recibo e definir configurações de envio.</p>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
                            <router-link to="/configuracao/privacidade" class="nav-link" active-class="active">
                                <div class="info-box">
                                    <span class="info-box-icon bg-secondary"><i class="far fa-envelope"></i></span>

                                    <div class="info-box-content">
                                        <span class="info-box-number">Privacidade</span>
                                        <p>Configurações de recebimento de notificações.</p>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
                            <router-link to="/configuracao/exclusao" class="nav-link" active-class="active">
                                <div class="info-box">
                                    <span class="info-box-icon bg-secondary"><i class="far fa-envelope"></i></span>

                                    <div class="info-box-content">
                                        <span class="info-box-number">Exclusão dados</span>
                                        <p>Selecione os dados que deseja exlcuir</p>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>
