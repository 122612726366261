<template>
    <div>
        <div class="row">
            <div class="col-3">
                <div class="form-group">
                    <label for="condicoes_pagamento">Condições de Pagamento</label>
                    <select
                        v-model="form.condicao_pagamento"
                        @change="selectCondicoes($event.target.value)"
                        id="condicoes_pagamento"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('condicoes_pagamento') }"
                        :disabled="status"
                    >
                        <option
                            v-for="condicoes in condicao_pagamento"
                            :key="condicoes.id"
                            :value="condicoes.id"
                        >
                            {{ condicoes.nome }}
                        </option>
                    </select>
                    <has-error :form="form" field="condicoes_pagamento"></has-error>
               </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <SelectFormaDePagamento
                        :class="{ 'is-invalid': form.errors.has('forma_pagamento_id') }"
                        :selectValue="form.forma_pagamento_id"
                        v-on:selected="eventoFormaDePagamento"
                    />

                </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <label for="parcelas">Parcelas</label>

                    <input
                        type="number"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('parcelas') }"
                        id="parcelas"
                        min="1"
                        @change="qtyParcelas($event.target.value)"
                        @keyup="qtyParcelas($event.target.value)"
                        v-model="form.parcelas.length"
                        :disabled="form.condicao_pagamento == 'A' || status"
                    >
               </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <label for="data_primeiro_vencimento">Primeiro Vencimento</label>
                    <input
                        v-model="form.primeiro_vencimento"
                        @change="updateParcelasData($event.target.value)"
                        type="date"
                        id="data_primeiro_vencimento"
                        class="form-control"
                        :class="{ 'is-invalid': form.errors.has('data_primeiro_vencimento') }"
                        :disabled="status"
                    />
                    <has-error :form="form" field="data_primeiro_vencimento"></has-error>
                </div>
            </div>
        </div>

        <table class="table table-striped payment_table">
            <thead>
            <tr>
                <th>Parcela </th>
                <th>Vencimento </th>
                <th>Total </th>
                <th>Observação </th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="(parcela,index) in form.parcelas" :key="index">
                    <td>{{index+1}}/{{ form.parcelas.length }}</td>
                    <td>
                        <div class="form-group">
                            <input v-model="parcela.vencimento" type="date" class="form-control" :disabled="status">
                        </div>
                    </td>
                    <td>
                        <div class="form-group">
                            <money
                                v-model="parcela.valor"
                                class="form-control"
                                disabled
                            ></money>
                        </div>
                    </td>
                    <td>
                        <div class="form-group">
                            <input type="text" :value="parcela.observacao" class="form-control" :disabled="status">
                        </div>
                    </td>
                    <td>
                        <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            @click="remove(index)"
                            v-show="form.parcelas.length > 1"
                            v-if="!status"
                        >
                            <i class="fas fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import moment from 'moment';
import VendaParcela from "../../../domain/financeiro/VendaParcela";
import SelectFormaDePagamento from "../../financeiro/common/SelectFormaDePagamento";

export default {
    name: "Pagamentos",
    components: {SelectFormaDePagamento},
    props: {
        selectValue: {
            type: [Object, Array],
            required: true
        }
    },
    data(){
        return{
            qty_parcelas: 1,
            form: new Form({
                parcelas: [],
                condicao_pagamento: 'A',
                forma_pagamento_id: 1,
                primeiro_vencimento: moment(new Date()).format("YYYY-MM-D"),
            }),
            editmode: false,
            a: false,
        }
    },
    methods:{
        add(){
            if (this.editmode) return;

            this.form.parcelas = [];
            let total = (this.selectValue.form.total / this.qty_parcelas).toFixed(2);

            for (let i = 1; i <= this.qty_parcelas; i++) {
                this.form.parcelas.push(
                    new VendaParcela(this.vencimento(i), total)
                );
            }
        },
        remove(index){
            this.form.parcelas.splice(index, 1);
            this.qtyParcelas(this.form.parcelas.length);
        },
        vencimento(qty){
            return moment(this.form.primeiro_vencimento).add(qty - 1,'M').format("YYYY-MM-DD")
        },
        selectCondicoes(value){
            let qty = value == 'P' ? this.qty_parcelas : 1
            this.form.parcelas = [];
            this.form.forma_pagamento_id = 1;
            this.qtyParcelas(qty);
        },
        updateParcelasData(){
            for (let i = 0; i < this.form.parcelas.length; i++) {
                this.form.parcelas[i].vencimento = moment(this.form.primeiro_vencimento).add(i,'M').format("YYYY-MM-DD");
            }
        },
        qtyParcelas(qty, tipo = 'envia') {
            this.qty_parcelas = qty;
            this.add();

            if(tipo == 'envia') {
                this.returnValue();
            }
        },
        returnValue(){
            this.form.totalInstallments = this.form.parcelas.length;
            this.$emit('selected', this.form);
        },
        eventoFormaDePagamento(event){
            this.form.forma_pagamento_id = event.id;
            this.returnValue();
        }
    },
    mounted() {
        this.form.parcelas.push(
            new VendaParcela(this.vencimento(0), this.selectValue.form.total)
        );
    },
    watch: {
        selectValue: function (newVal, oldVal) {
            this.a = (newVal.form.status > 0);

            let parcelas = newVal.form.parcelas.length <= 0 ? 1 : newVal.form.parcelas.length;
            if (newVal.editmode) {
                this.form.parcelas = [];
                for(let i = 0; i < parcelas; i++) {
                    this.form.parcelas.push(
                        new VendaParcela(
                            moment(newVal.parcelas[i].data_vencimento).format("YYYY-MM-DD"),
                            newVal.parcelas[i].valor
                        )
                    );
                }
                this.form.parcelas = newVal.parcelas;
                this.form.forma_pagamento_id = newVal.form.forma_pagamento_id;
                this.form.condicao_pagamento = parcelas == 1 ? 'A' : 'P';
            } else {
                this.qtyParcelas(parcelas, 'recebe');
            }
        }
    },
    computed: {
        condicao_pagamento: () => {
            return [
                {id: 'A', nome: 'A vista'},
                {id: 'P', nome: 'A prazo'},
            ];
        },
      status: {
        get: function () {
          return this.a;
        },
        set: function (v) {
          this.a = v;
        }
      }
    }
}
</script>
