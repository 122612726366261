<template>
    <div class="col-md-6 col-sm-6 col-12">
        <div class="card card-outline card-danger">
            <div class="card-header">
                <h3 class="card-title">Alunos por Modalidade</h3>

                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i>
                    </button>
                    <button type="button" class="btn btn-tool" data-card-widget="remove"><i class="fas fa-times"></i></button>
                </div>
            </div>
            <div class="card-body"><div class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand"><div class=""></div></div><div class="chartjs-size-monitor-shrink"><div class=""></div></div></div>
                <BarVerticalChart :chart-data="bar.data"></BarVerticalChart>
            </div>
        </div>
    </div>
</template>

<script>
import BarVerticalChart from "./BarVerticalChart";
    export default {
        components: {
            BarVerticalChart
        },
        name: "Modalidades",
        data(){
            return{
                bar: {
                    data: {
                    labels: ["Jiu-jitus", "Judo", "Muay Thai", "pilates", "Karatê"],
                    datasets: [
                        {
                            // label: "Data",
                            data: [5, 10, 6 , 4, 8],
                            backgroundColor: ["#ff5722", "#ffeb3b", "#f44336", "#6cb2eb", "#6c757d"]
                        }]
                    },
                }
            }
        },
        methods:{

        },
        mounted(){

        },
        created(){

        },
}
</script>

<style>

</style>
