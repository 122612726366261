<template>
    <div class="card-body table-responsive p-0">
        <table class="table table-hover">
            <thead>
                <tr>
                    <th>Pagador</th>
                    <th>Total</th>
                    <th>Total Pago</th>
                    <th>Status</th>
                    <th>Vencimento</th>
                    <th>Data Pagamento</th>
                    <th>Ação</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="conta in contas" :key="conta.id">
                    <td> {{conta.pagador}} </td>
                    <td> {{conta.total}} </td>
                    <td> {{conta.total_pago}} </td>
                    <td>
                        <span :value="conta.status" class="badge"
                            v-bind:class="conta.status != 0 ? 'badge-success' : 'badge-danger'">
                        {{ conta.status != 0 ? 'Pago' : 'Não pago' }}
                        </span>
                    </td>
                    <td> {{conta.vencimento | moment("D/MM/YYYY") }} </td>
                    <td> {{conta.data_pagamento | moment("D/MM/YYYY") }} </td>
                    <td>
                        <a href="#" @click="deleteConta(conta.id)" class="btn btn-sm btn-danger" title="excluir">
                            <i class="fa fa-trash"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="card-footer">
            <no-records v-if="contas.length <= 0"></no-records>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    name: "Contas",
    data(){
        return{
            contas: [],
        }
    },
    methods:{
        deleteConta(id){
            Swal.fire({
                title: 'Tem certeza?',
                text: "Você não podera restaurar a conta!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Sim, excluir!'
                }).then((result) => {
                    if (result.value) {
                        this.form.delete('api/conta/'+id).then(()=>{
                            Swal.fire(
                                'Excluída!',
                                'Notificação excluida!.',
                                'success'
                            );
                            this.loadContas();
                        }).catch((data)=> {
                            Swal.fire("Failed!", data.message, "warning");
                        });
                    }
                })
        },
        loadContas(){
            const id_aluno = this.$route.params.id;

            this.$Progress.start();
            axios.get(`/api/aluno/${id_aluno}/sales`).then(({ data }) => { this.contas = data.data });
            this.$Progress.finish();
        }
    },
    mounted(){
        console.log('Contas component mounted.');
    },
    created(){
        this.loadContas();
        console.log('created componenten');
    }
}
</script>

<style scoped>

</style>
