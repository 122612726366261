<template>
    <section class="content">
        <div class="container-fluid">

            <categorias-video v-on:categories="loadCategories($event)"></categorias-video>

            <div class="row">

                <div class="col-12">

                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Lista de Vídeos</h3>

                            <div class="card-tools">
                                <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                                    <i class="fa fa-plus-square"></i>
                                    NOVO VÍDEO
                                </button>
                            </div>
                        </div>
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Categoria</th>
                                    <th>Link</th>
                                    <th>Ação</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="video in videos.data" :key="video.id">
                                    <td>{{ video.assunto }}</td>
                                    <td>{{ video.categoria }}</td>
                                    <td>
                                        <a :href="video.link_video" target="_blank">
                                            Abrir Vídeo
                                        </a>
                                    </td>
                                    <td>
                                        <a href="#" @click="editModal(video)" class="btn btn-sm btn-primary" title="editar">
                                            <i class="fa fa-edit"></i>
                                        </a>
                                        <a href="#" @click="deleteVideo(video.id)" class="btn btn-sm btn-danger" title="excluir">
                                            <i class="fa fa-trash"></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <no-records v-if="videos.data.length <= 0"></no-records>
                            <pagination :data="videos" @pagination-change-page="getResults"></pagination>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" v-show="!editmode">Cadastrar Novo Vídeo</h5>
                            <h5 class="modal-title" v-show="editmode">Editar Vídeo</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <form @submit.prevent="editmode ? updateVideo() : createVideo()">
                            <div class="modal-body">
                                <div class="form-group">
                                    <label for="id_categoria">Categoria</label>
                                    <select class="form-control" v-model="form.id_categoria" id="id_categoria">
                                        <option
                                            :value="categoria.code"
                                            v-for="categoria in categorias"
                                            :selected="categoria.code == form.id_categoria"
                                        >
                                            {{ categoria.label }}
                                        </option>
                                    </select>
                                    <has-error :form="form" field="id_categoria"></has-error>
                                </div>
                                <div class="form-group">
                                    <label for="assunto">Nome</label>
                                    <input v-model="form.assunto" type="text" name="assunto" id="assunto"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('assunto') }">
                                    <has-error :form="form" field="assunto"></has-error>
                                </div>
                                <div class="form-group">
                                    <label for="descricao">Descrição</label>
                                    <vue-editor v-model="form.descricao" :class="{ 'is-invalid': form.errors.has('descricao') }" id="descricao"/>
                                    <has-error :form="form" field="descricao"></has-error>
                                </div>
                                <div class="form-group">
                                    <label for="link_video">Link</label>
                                    <input v-model="form.link_video" type="url" name="link_video" id="link_video"
                                           class="form-control"
                                           :class="{ 'is-invalid': form.errors.has('link_video') }">
                                    <has-error :form="form" field="link_video"></has-error>
                                </div>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                <button type="submit" class="btn btn-success">Salvar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import CategoriasVideo from "./CategoriasVideo";

export default {
    components: {
        CategoriasVideo,
        VueTagsInput,
    },
    data() {
        return {
            editmode: false,
            videos: {
                data: []
            },
            categorias: {},
            form: new Form({
                id: '',
                id_categoria: '',
                assunto: '',
                descricao: '',
                link_video: '',
            }),
            autocompleteItems: [],
        }
    },
    methods: {
        getResults(page = 1) {
            this.$Progress.start();
            axios.get(`/api/video?page=${page}`).then(({data}) => (this.videos = data.data));
            this.$Progress.finish();
        },
        loadVideos() {
            axios.get(`/api/video`).then(({data}) => (this.videos = data.data));
        },
        editModal(video) {
            this.editmode = true;
            this.form.reset();
            $('#addNew').modal('show');
            this.form.fill(video);
        },
        newModal() {
            this.editmode = false;
            this.form.reset();
            $('#addNew').modal('show');
        },
        createVideo() {
            this.$Progress.start();

            this.form.post(`/api/video`)
                .then((data) => {
                    if (data.data.success) {
                        $('#addNew').modal('hide');

                        Toast.fire({
                            icon: 'success',
                            title: data.data.message
                        });
                        this.$Progress.finish();
                        this.loadVideos();

                    } else {
                        Toast.fire({
                            icon: 'error',
                            title: 'Ocorreu um erro! Por favor, tente novamente.'
                        });

                        this.$Progress.failed();
                    }
                })
                .catch(() => {

                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
        updateVideo() {
            this.$Progress.start();
            this.form.put(`/api/video/${this.form.id}`)
                .then((response) => {
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();

                    this.loadVideos();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

        },
        deleteVideo(id) {
            Swal.fire({
                title: 'Tem certeza?',
                text: "Você não poderá restaurar depois!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Sim, excluir!'
            }).then((result) => {
                if (result.value) {
                    this.form.delete(`api/video/${id}`).then(() => {
                        Toast.fire(
                            'Excluído!',
                            'O vídeo foi excluído.',
                            'success'
                        );
                        this.loadVideos();
                    }).catch((data) => {
                        Swal.fire("Erro!", data.message, "warning");
                    });
                }
            })
        },
        loadCategories: function(event){
            this.categorias = event;
        }
    },
    mounted() {

    },
    created() {
        this.$Progress.start();
        this.loadVideos();
        this.$Progress.finish();
    },
    filters: {
        truncate: function (text, length, suffix) {
            return text.substring(0, length) + suffix;
        },
    },
    computed: {
        filteredItems() {
            return this.autocompleteItems.filter(i => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
        },
    },
}
</script>
