/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');
import moment from 'moment';

import { Form, HasError, AlertError } from 'vform';
window.Form = Form;

import Gate from "./Gate";
Vue.prototype.$gate = new Gate(window.user);

import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
window.Swal = Swal;
window.Toast = Toast;

import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
    color: 'rgb(143, 255, 199)',
    failedColor: 'red',
    height: '8px'
  });

Vue.component(HasError.name, HasError)
Vue.component(AlertError.name, AlertError)

// import vSelect from 'vue-select';
// Vue.component('v-select', vSelect)

import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);

import VueCharts from 'vue-chartjs'
Vue.use(VueCharts);

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask);

// register directive v-money and component <money>
import money from 'v-money'
Vue.use(money, {
    decimal: ',',
    thousands: '.',
    prefix: 'R$ ',
    precision: 2,
    masked: false
});

Vue.use(require('vue-moment'));

import VueCurrencyFilter from "vue-currency-filter";
Vue.use(VueCurrencyFilter,{
    symbol : 'R$',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true,
    avoidEmptyDecimals: undefined,
})

/**
 * Routes imports and assigning
 */
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import routes from './routes';

const router = new VueRouter({
    mode: 'history',
    routes
});
// Routes End

import { VuejsDatatableFactory } from 'vuejs-datatable';
Vue.use( VuejsDatatableFactory );

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


// Components
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('dashboard', require('./components/Dashboard.vue'));
Vue.component(
    'preloader-full-screen',
    require('./components/common/PreloaderFullScreen').default
);
Vue.component(
    'bread-crumbs',
    require('./components/common/BreadCrumbs').default
);
Vue.component(
    'notifications',
    require('./components/common/header_menu/Notifications').default
);
Vue.component(
    'messages',
    require('./components/common/header_menu/Messages').default
);

Vue.component(
    'passport-clients',
    require('./components/passport/Clients.vue').default
);

Vue.component(
    'passport-authorized-clients',
    require('./components/passport/AuthorizedClients.vue').default
);

Vue.component(
    'passport-personal-access-tokens',
    require('./components/passport/PersonalAccessTokens.vue').default
);

Vue.component(
    'not-found',
    require('./components/common/errors/NotFound.vue').default
);

Vue.component(
    'no-records',
    require('./components/common/errors/NoRecords.vue').default
);

Vue.component(
    'no-permission',
    require('./components/common/errors/NoPermissions.vue').default
);


// Filter Section
// Vue.filter('myDate',function(created){
//     return moment(created).format('MMMM Do YYYY');
// });
// Vue.filter('yesno', value => (value ? '<i class="fas fa-check green"></i>' : '<i class="fas fa-times red"></i>'));
// end Filter

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

const app = new Vue({
    el: '#app',
    router,
});
