<template>
<div>
    <div>
        <form @submit.prevent="createNotification()">
            <div class="form-group">
                <label> Texto </label>
                <textarea v-model="form.texto" class="form-control" id="texto" rows="3"></textarea>
            </div>
            <div class="row">
                <div class="col">
                    <span class="info-container characters">
                        <span class="info"> Sua texto pode ter até <span class="font-weight-bold"> 140 </span> caracteres </span>
                    </span>
                </div>
                <div class="col">
                    <button type="submit" class="btn btn-success float-right">
                         Salvar
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div class="card-body table-responsive p-0">
        <table class="table table-hover">
            <thead>
                <tr>
                    <th>Texto</th>
                    <th>Status</th>
                    <th>Ação</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="notificacao in notificacoes" :key="notificacao.id">
                    <td> {{notificacao.mensagem}} </td>
                    <td>
                        <span :value="notificacao.status" @click="mudaStatus(notificacao.id_notificacao)" class="badge "
                            v-bind:class="notificacao.status == 0 ? 'badge-success' : 'badge-danger'">
                        {{ notificacao.status == 0 ? 'Active' : 'Inactive' }}
                        </span>
                    </td>
                    <td>
                        <a href="#" @click="deleteNotificacao(notificacao.id_notificacao)" class="btn btn-sm btn-danger" title="excluir">
                            <i class="fa fa-trash"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
export default {
    name: "Notificacoes",
    data(){
        return {
             form : new Form({
                texto: '',
            }),
            notificacoes: [],
        }
    },
    methods:{
        mudaStatus(id){
            let form = new Form({
                id_aluno: this.$route.params.id,
                id_notification: id
            });
            form.post(`/api/aluno/notificationStatus`)
                .then((response) => {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.loadNotifications();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
        deleteNotificacao(id){
            let form = new Form({
                id_aluno: this.$route.params.id,
                id_notification: id
            });
            Swal.fire({
                    title: 'Tem certeza?',
                    text: "Você não podera restaurar a notificação!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Sim, excluir!'
                    }).then((result) => {
                        if (result.value) {
                            form.post(`/api/aluno/notification/delete`)
                                .then((response)=>{
                                    Toast.fire({
                                        icon: 'success',
                                        title: response.data.message
                                    });
                                    this.loadNotifications();
                                })
                                .catch(()=>{
                                    Toast.fire({
                                        icon: 'error',
                                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                                    });
                                })
                        }
                    })
        },
        createNotification(){
            this.form.post(`/api/aluno/${this.$route.params.id}/notification/create`)
              .then((response)=>{
                  Toast.fire({
                        icon: 'success',
                        title: response.data.message
                  });
                  this.$Progress.finish();
                  this.loadNotifications();
              })
              .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
              })
        },
        loadNotifications(){
            this.$Progress.start();
                axios.get(`/api/aluno/${this.$route.params.id}/notification`).then(({ data }) => {
                    this.notificacoes = data;
                });

            this.$Progress.finish();
        }
    },
    mounted() {
        this.loadNotifications();
        console.log('Noticiations component mounted.');
    },
    created() {
        console.log('created componenten method');
    },
}
</script>

<style scoped>

</style>
