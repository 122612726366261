export default class Professor {
    constructor(id= '',
                id_professor= '',
                id_academia= '',
                nome= '',
                sobrenome= '',
                cpf= '',
                rg= '',
                email= '',
                senha= '',
                data_nascimento= '',
                sexo= '',
                telefone= '',
                status= '',
                martial_arts= [],
                foto= '',
                endereco= {},
    ) {
        this.id = id;
        this.id_professor = id_professor;
        this.id_academia = id_academia;
        this.nome = nome;
        this.sobrenome = sobrenome;
        this.cpf = cpf;
        this.rg = rg;
        this.email = email;
        this.senha = senha;
        this.data_nascimento = data_nascimento;
        this.sexo = sexo;
        this.telefone = telefone;
        this.status = status;
        this.martial_arts = martial_arts;
        this.foto = foto;
        this.endereco = endereco;
        this.complemento = '';

    }
}
