<template>
    <div v-if="exibir" class="overlay">
        <h1><i class="fas fa-sync-alt fa-spin"></i></h1>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                exibir: true,
            }
        },
        props: {
            status:{
                // type: String,
                required: true,
            },
        },
        watch:{
            status: function(newVal, oldVal){
                console.log(newVal, 'spinner2')
                this.exibir = newVal;
            }
        },
    }
</script>
