<template>
    <div class="col-12">
        <div class="card card-primary card-outline card-tabs">
            <form @submit.prevent="save()">
                <div class="box box-primary box-solid p-3">
                    <div class="box-header with-border">
                        <h3 class="box-title">Configurações do recibo</h3>
                    </div>
                    <div class="box-body text-center">
                        <div class="form-group">
                            <label>Enviar recibo sempre?</label>
                            <select class="form-control" v-model="form.recibo_enviar_sempre" name="recibo_enviar_sempre">
                                <option value="0">Não</option>
                                <option value="1">Sim</option>
                            </select>
                        </div>

                        <h4 class="mb-3">Configurações do recibo</h4>
                        <div class="row">
                            <div class="col-md-7 col-xs-12">
                                <textarea v-model="form.recibo_texto" name="recibo_texto" class="textarea" placeholder=""
                                style="width: 100%; height: 400px; font-size: 14px; line-height: 18px; border: 1px solid #dddddd; padding: 10px;">
</textarea>
                                </div>
                                <div class="col-md-5 col-xs-12 text-left">
                                    <div class="direct-chat-text">Matrícula do cliente = [ID_CLIENTE]</div>
                                    <div class="direct-chat-text">Nome do cliente = [NOME]</div>
                                    <div class="direct-chat-text">Recebimento = [RECEBIMENTO]</div>
                                    <div class="direct-chat-text">Valor recibo = [VALOR_RECEBIDO]</div>
                                    <div class="direct-chat-text">Itens = [ITENS]</div>
                                    <div class="direct-chat-text">Data = [DATA]</div>
                                    <div class="direct-chat-text">Total = [TOTAL]</div>
                                    <div class="direct-chat-text">Cpf = [CPF]</div>
                                    <div class="direct-chat-text">Detalhes do cheque = [DETALHES]</div>
                                    <div class="direct-chat-text">Telefone do cliente = [TELEFONE_CLIENTE]</div>
                                    <div class="direct-chat-text">Número documento = [ID_RECIBO]</div>
                                    <div class="direct-chat-text">Endereço = [ENDERECO_ACADEMIA]</div>
                                    <div class="direct-chat-text">Data de impressão do recibo = [DATA_IMPRESSAO]</div>
                                    <div class="direct-chat-text">Total de impostos = [VALOR_ACRESCIMO]</div>
                                    <div class="direct-chat-text">Total de descontos: r$ = [VALOR_DESCONTO]</div>
                                    <div class="direct-chat-text">Valor total dos itens da venda = [VALOR_ITENS]</div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div class="card-footer">
                    <button type="submit" class="btn btn-success">
                        Atualizar
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            form: new Form({
                recibo_enviar_sempre: '',
                recibo_texto: "",
            }),
        }
    },
    methods:{
        loadRecibo(){
            this.$Progress.start();
                axios.get("/api/configuracao/recibo").then(({data}) => {
                    this.form.fill(data.data);
                });
            this.$Progress.finish();
        },
        save(){
            this.$Progress.start();
            this.form.post('/api/configuracao/save')
                .then((data) => {
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    this.$Progress.finish();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
    },
    created(){
        this.loadRecibo();
        console.log('Componente recibo created')
    },
    mounted(){
        console.log('Componente recibo mounte')
    },

}
/**

--------------------------------------------------------------
RECIBO DE PAGAMENTO
--------------------------------------------------------------
Número do recibo: [ID_RECIBO]
Data: [DATA]
Cliente: [NOME]
Valor da venda: [VALOR_RECEBIDO]
--------------------------------------------------------------
Itens:
[ITENS]
Recebimentos:
[RECEBIMENTO]
Valor Total Recebido:[TOTAL]
---------------------------------------------------------------
Agradecemos pelo pagamento. */
</script>

<style>

</style>
