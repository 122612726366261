<template>

<div class="row">

    <div class="col-md-4 col-sm-6 col-12">
        <div class="info-box">
            <span class="info-box-icon bg-success"><i class="fas fa-angle-double-up"></i></span>

            <div class="info-box-content">
                <span class="info-box-text">
                    Rendimentos do Mês
                </span>
                <span class="info-box-number">
                    <span class="text-success">
                        Pago: {{ receitas.total_pago | currency }}
                    </span>
                    <br>
                    <span>
                        Previsto: {{ receitas.total | currency }}
                    </span>
                </span>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-sm-6 col-12">
        <div class="info-box">
            <span class="info-box-icon bg-danger"><i class="fas fa-angle-double-up"></i></span>

            <div class="info-box-content">
                <span class="info-box-text">
                    Despesas do Mês
                </span>
                <span class="info-box-number">
                    <span class="text-danger">
                        Pago: {{ despesas.total_pago | currency }}
                    </span>
                    <br>
                    <span>
                        Previsto: {{ despesas.total | currency }}
                    </span>
                </span>
            </div>
        </div>
    </div>

    <div class="col-md-4 col-sm-6 col-12">
        <div class="info-box">
            <span class="info-box-icon bg-maroon"><i class="fas fa-balance-scale"></i></span>
            <div class="info-box-content">
                <span class="info-box-text">
                    Resumo de Receita
                </span>
                <span class="info-box-number">
                    <span class="text-maroon">
                      Saldo Final: <strong>{{receitas.total_pago - despesas.total_pago | currency}}</strong>
                    </span>
                  <br>
                  <span>&nbsp;</span>
                </span>
            </div>
        </div>
    </div>
</div>

</template>

<script>
export default {
    name: "Financeiro",
    data(){
        return{
          receitas: {
            total: 0,
            total_pago: 0
          },
          despesas: {
            total: 0,
            total_pago: 0
          },
        }
    },
    methods:{
      getReceitas(){
        axios.get(`/api/financeiro/receber_valor?periodo=este_mes`).then(({data}) => (this.receitas = data.data));
      },
      getDespesas(){
        axios.get(`/api/financeiro/pagar_valor?periodo=este_mes`).then(({data}) => (this.despesas = data.data));
      }
    },
    mounted(){
      this.$Progress.start();
      this.getReceitas();
      this.getDespesas();

      console.log(this.configuracao);
      this.$Progress.finish();
    },
    created(){

    },

}
</script>
