<template>
<div >
    <div class="form-group">

        <label> CEP </label>
        <input v-model="form.cep" type="text" name="cep" v-mask="'#####-###'" @keyup="preenche"
            class="form-control" :class="{ 'is-invalid': form.errors.has('cep') }">
        <has-error :form="form" field="cep"></has-error>

    </div>
    <div class="row">
        <div class="col">
            <div class="form-group">

                <label> Endereco </label>
                <input v-model="form.endereco" type="text" name="endereco" @keyup="preenche"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('endereco') }">
                <has-error :form="form" field="endereco"></has-error>

            </div>
        </div>
        <div class="col">
            <div class="form-group">

                <label> Numero </label>
                <input v-model="form.numero" type="number" name="numero" @keyup="preenche"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('numero') }">
                <has-error :form="form" field="numero"></has-error>

            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="form-group">

                <label>Bairro</label>
                <input v-model="form.bairro" type="text" name="bairro" @keyup="preenche"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('bairro') }">
                <has-error :form="form" field="bairro"></has-error>

            </div>
        </div>
        <div class="col">
            <div class="form-group">

                <label>Cidade</label>
                <input v-model="form.cidade" type="text" name="cidade" @keyup="preenche"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('cidade') }">
                <has-error :form="form" field="cidade"></has-error>

            </div>
        </div>
        <div class="col">
            <div class="form-group">

                <label>UF</label>
                <input v-model="form.uf" type="text" name="uf" @keyup="preenche" maxlength="2"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('uf') }">
                <has-error :form="form" field="uf"></has-error>

            </div>
        </div>
    </div>
    <div class="form-group">

        <label>Complemento</label>
        <input v-model="form.complemento" type="text" name="complemento" @keyup="preenche"
            class="form-control" :class="{ 'is-invalid': form.errors.has('complemento') }">
        <has-error :form="form" field="complemento"></has-error>

    </div>
</div>
</template>

<script>
import {mask} from 'vue-the-mask'
export default {
    name: "Endereco",
    directives: {mask},
    props: {
        selectValue: {
            type: Object,
        }
    },
    data() {
        return {
            form: new Form({
                cep: '',
                endereco: '',
                numero: '',
                bairro: '',
                cidade: '',
                uf: '',
                complemento: '',
            })
        }
    },
    methods:{
        preenche(){
            this.$emit('selected', this.form);
        }
    },
    watch: {
      selectValue: function (form) {
        this.form.fill(form);
      }
    }
}
</script>

<style scoped>

</style>
