<template>
    <div class="form-group" >
        <label>Academias</label>
        <select name="id_academia"
                :change="setResult(form.id_academia)"
                v-model="form.id_academia"
                id="id_academia"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('id_academia') }"
        >
            <option
                v-for="academia in academias"
                :key="academia.id_academia"
                :value="academia.id_academia"
            >
                {{ academia.nome }}
            </option>
        </select>
        <has-error :form="form" field="id_academia"></has-error>

    </div>
</template>

<script>
export default {
    name: "SelectAcademia",
    props: {
        selecionada: {
            required: false
        }
    },
    data () {
        return {
            form: new Form({
                id_academia: '',
            }),
            academias: [],
            loading: true,
        }
    },
    methods: {
        getResults() {
            axios.get('/api/academia').then(({ data }) => {
                this.academias = data.data.data;
                this.loading = false;
            });
        },
        setResult(event) {
            console.log(event)
            this.$emit('selected', event);
        }
    },
    mounted() {
        this.loading = true;
        this.getResults();
    },
    watch:{
        selecionada: function (form) {
            this.form.fill(form)
        }
    }
}
</script>

