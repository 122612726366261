import moment from "moment";

export default class Venda {
    constructor(id = '',
                usuario_id = '',
                categoria_id = '',
                data_venda = moment(new Date()).format("YYYY-MM-D"),
                banco_id = '',
                vendedor_id = '',
                desconto_tipo = '',
                desconto_valor = '',
                informacoes_adicionais = '',
                condicao_pagamento = 'A',
                forma_pagamento_id = 1,
                totalInstallments = 1,
                first_due_date = moment(new Date()).format("YYYY-MM-D"),
                produtos= [],
                parcelas= [],

                usuario_nome = '',
                vendedor_nome = '',
                table = 'aluno',
                status = 0,
    ) {
        this.id = id;
        this.usuario_id = usuario_id;
        this.categoria_id = categoria_id;
        this.data_venda = data_venda;
        this.banco_id = banco_id;
        this.vendedor_id = vendedor_id;
        this.desconto_tipo = desconto_tipo;
        this.desconto_valor = desconto_valor;
        this.informacoes_adicionais = informacoes_adicionais;
        this.condicao_pagamento = condicao_pagamento;
        this.forma_pagamento_id = forma_pagamento_id;
        this.totalInstallments = totalInstallments;
        this.first_due_date = first_due_date;
        this.produtos = produtos;
        this.parcelas = parcelas;

        this.usuario_nome = usuario_nome;
        this.vendedor_nome = vendedor_nome;
        this.table = table;
        this.status = status;
    }
}
