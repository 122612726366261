export default class Produto {
    constructor(id = '',
        codigo = '',
        nome = '',
        preco_venda = '',
        preco_custo = '',
        image = '',
        body = '',
        qty = '',
        controlar_estoque = ''
    ) {
        this.id = id;
        this.codigo = codigo;
        this.nome = nome;
        this.preco_venda = preco_venda;
        this.preco_custo = preco_custo;
        this.image = image;
        this.body = body;
        this.qty = qty;
        this.controlar_estoque = controlar_estoque;
    }
}
