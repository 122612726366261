<template>
  <div class="col-12">
        <div class="card card-primary card-outline card-tabs">
            <div class="box box-primary box-solid">
                <form @submit.prevent="confirmar()">
                <div class="box-header with-border m-3">
                    <h3 class="box-title">Dados para serem excluidos</h3>
                </div>
                <div class="box-body text-center">
                    <div class="col-md-12">
                        <div class="box-body">
                            <div class="row text-left">
                                <div class="col-md-2">
                                    <div class="checkbox">
                                        <label>
                                            <input v-model="form.alunos" name="alunos" value="alunos" type="checkbox" > Alunos
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="checkbox">
                                        <label>
                                            <input v-model="form.professores" name="professores" value="professores" type="checkbox" > Professores
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="checkbox">
                                        <label>
                                            <input v-model="form.blogs" name="blogs" value="blogs" type="checkbox" > Blogs
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="checkbox">
                                        <label>
                                            <input v-model="form.enquetes" name="enquetes" value="enquetes" type="checkbox" > Enquetes
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="checkbox">
                                        <label>
                                            <input v-model="form.usuarios" name="usuarios" value="usuarios" type="checkbox" > Usuarios
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="checkbox">
                                        <label>
                                            <input v-model="form.agendas" name="agendas" value="agendas" type="checkbox" > Agendas
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="checkbox">
                                        <label>
                                            <input v-model="form.vendas" name="vendas" value="vendas" type="checkbox" > Vendas
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="checkbox">
                                        <label>
                                            <input v-model="form.contas" name="contas" value="contas" type="checkbox" > Contas
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="checkbox">
                                        <label>
                                            <input v-model="form.modalidades" name="modalidades" value="modalidades" type="checkbox" > Artes Marciais
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="checkbox">
                                        <label>
                                            <input v-model="form.academias" name="academias" value="academias" type="checkbox" > Academias
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="checkbox">
                                        <label>
                                            <input v-model="form.presencas" name="presencas" value="presencas" type="checkbox" > Presencas
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="checkbox">
                                        <label>
                                            <input v-model="form.posts" name="posts" value="posts" type="checkbox" > Posts
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="checkbox">
                                        <label>
                                            <input v-model="form.comentarios" name="comentarios" value="comentarios" type="checkbox" > Comentarios
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="checkbox">
                                        <label>
                                            <input v-model="form.bancos" name="bancos" value="bancos" type="checkbox" > Bancos
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="checkbox">
                                        <label>
                                            <input v-model="form.planos" name="planos" value="planos" type="checkbox" > planos
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <button type="submit" class="btn btn-danger">
                        Excluir
                    </button>
                </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            form: new Form({
                alunos: '',
                professores: '',
                blogs: '',
                enquetes: '',
                usuarios: '',
                agendas: '',
                vendas: '',
                contas: '',
                modalidades: '',
                academias: '',
                presencas: '',
                posts: '',
                comentarios: '',
                bancos: '',
                planos: '',

            }),
        }
    },
    methods:{
        confirmar(){
            Swal.fire({
            title: 'Tem certeza?',
            text: "Você não poderá restaurar o dados mais tarde!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, excluir!',
            cancelButtonText: 'Não'
        }).then((result) => {

            Swal.fire({
                title: 'Confirmar exclução ?',
                text: "Todos os dados selecionado serão excluidos permanentemente, sendo impossivel recuperalos!",
                type: 'warning',
                timerProgressBar: true,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, confirmar!',
                cancelButtonText: 'Cancelar exclusão'
            }).then((result) => {
                this.excluir();
                console.log('excluindo tudo');
            })

        })

        },
        excluir(){
            console.log('excluindo');
            this.form.campos = this.inputs;
            this.$Progress.start();
            this.form.post(`/api/configuracao/exclusao`)
                .then((data) => {
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    this.loadModalidades();
                    this.$Progress.finish();
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
    },
    created(){
        console.log('Exclusoão componente create');
    },
    mounted(){
        console.log('Exclusoão componente mounted');
    }

}
</script>

<style>

</style>
