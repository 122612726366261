<template>
    <div>
        <label class="typo__label">{{title }}</label>
        <multiselect
            v-model="value"
            :options="options"
            group-values="dados"
            group-label="tipo"
            :group-select="true"
            placeholder="Procurar Pessoa, Professor ou Aluno"
            track-by="name"
            label="name"
            @select="setSelected"
            @remove="removeSelected"
            :disabled="status"
        >
            <template slot="singleLabel" slot-scope="{ option }">
                <strong>{{ option.name }}</strong>
            </template>
            <span slot="noResult">Oops! Não encontramos esse cadastro.</span>
        </multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    components: {
        Multiselect
    },
  props: {
    selectValue: {
      type: [Object, Array],
    },
    title: {
      type: String,
      required: true
    }
  },
    data() {
        return {
            options: [
                { nome:'Carregando...', id: null},
            ],
            value: [],
            a: false,
        }
    },
    methods: {
        getResults(search = '') {
            this.$Progress.start();
            axios.get(`/api/financeiro/pagadores?d=${search}`).then(({data}) => (this.options = data.data));
            this.$Progress.finish();
        },
        setSelected(selectedOption){
            this.$emit('selected', selectedOption)
        },
        removeSelected(selectedOption){
            this.$emit('selected', {})
        }
    },
    created() {
        this.getResults();
    },
    watch: {
      selectValue: function (newVal, oldVal) {
          this.a = (newVal.form.status > 0);

          this.value = newVal;
      }
    },
    computed: {
        status: {
            get: function () {
                return this.a;
            },
            set: function (v) {
                this.a = v;
            }
        }
    }
}

</script>

<style scoped>

</style>
