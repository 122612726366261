<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Posts</h3>
                            <div class="card-tools">
                                <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                                    <i class="fa fa-plus-square"></i>
                                    NOVO POST
                                </button>
                            </div>
                        </div>
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>Título</th>
                                    <th>Dados</th>
                                    <th>Capa</th>
                                    <th>Status</th>
                                    <th>Ação</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="post in posts.data" :key="post.id">
                                    <td>{{ post.titulo }}</td>
                                    <td>
                                        <span class="badge badge-dark">
                                            <i class="fas fa-comment"></i>
                                            {{ post.qtd_comentarios }}
                                        </span>
                                        <span class="badge badge-warning">
                                            <i class="fas fa-eye"></i>
                                            {{ post.visitas }}
                                        </span>
                                    </td>
                                    <td>
                                        <img :src="'img/posts/' + post.image" :alt="post.titulo" class="img-circle img-size-50 img-thumbnail">
                                    </td>
                                    <td>
                                        <span :value="post.status"
                                              @click="mudaStatus(post.id)"
                                              class="badge cursor-pointer"
                                              v-bind:class="post.status ? 'badge-success' : 'badge-danger'">
                                            {{ post.status ? 'Active' : 'Inactive' }}
                                        </span>
                                    </td>
                                    <td>
                                        <router-link :to="`/post/${post.id}/detalhes`"
                                                     class="btn btn-sm btn-secondary"
                                        >
                                            <i class="fas fa-eye"></i> Detalhes
                                        </router-link>
                                        <a class="btn btn-sm btn-primary" href="#" @click="editModal(post)">
                                            <i class="fa fa-edit"></i>
                                        </a>
                                        <a class="btn btn-sm btn-danger" href="#" @click="deletePost(post.id)">
                                            <i class="fa fa-trash"></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </div>
                        <div class="card-footer">

                            <no-records v-if="posts.data.length <= 0"></no-records>

                            <pagination :data="posts" @pagination-change-page="getResults"></pagination>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" v-show="!editmode">Cadastrar Novo Post</h5>
                            <h5 class="modal-title" v-show="editmode">Editar Post</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <form @submit.prevent="editmode ? updatePost() : createPost()">
                            <div class="modal-body">
                                <div class="form-group">
                                    <label for="titulo">Título</label>
                                    <input v-model="form.titulo"
                                           type="text"
                                           name="titulo"
                                           id="titulo"
                                           class="form-control"
                                           :class="{ 'is-invalid': form.errors.has('titulo') }">
                                    <has-error :form="form" field="titulo"></has-error>
                                </div>
                                <div class="form-group">
                                    <label for="texto">Texto</label>
                                    <vue-editor v-model="form.texto"
                                                id="texto"
                                                :class="{ 'is-invalid': form.errors.has('texto') }"/>

                                    <has-error :form="form" field="texto"></has-error>
                                </div>
                                <div class="form-group">
                                    <label for="autor">Autor</label>
                                    <input v-model="form.autor"
                                           type="text"
                                           name="autor"
                                           id="autor"
                                           class="form-control"
                                           :class="{ 'is-invalid': form.errors.has('autor') }">
                                    <has-error :form="form" field="autor"></has-error>
                                </div>
                                <div class="form-group">
                                    <label for="status">Status</label>
                                    <select v-model="form.status"
                                            name="status" id="status"
                                            class="form-control"
                                            :class="{ 'is-invalid': form.errors.has('status') }">
                                        <option value="">Select One</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inative</option>
                                    </select>
                                    <has-error :form="form" field="status"></has-error>
                                </div>
                                <div class="form-group">
                                    <label>Image</label>
                                    <input type="file" name="image"
                                           class="form-control"
                                           :class="{ 'is-invalid': form.errors.has('image') }"
                                           @change="updateProfile"
                                    >
                                    <has-error :form="form" field="image"></has-error>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                <button type="submit" class="btn btn-success">Salvar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            editmode: false,
            posts: {
                data: []
            },
            form: new Form({
                id: '',
                titulo: '',
                texto: '',
                autor: '',
                status: '',
                image: '',
            }),
            autocompleteItems: [],
        }
    },
    methods: {
        updateProfile(e){
            let file = e.target.files[0];
            let reader = new FileReader();
            let limit = 1024 * 1024 * 2;
            if(file['size'] > limit){
                swal({
                    type: 'error',
                    title: 'Oops...',
                    text: 'Sua foto é muito grande! Tente redimensionar ela.',
                })
                return false;
            }
            reader.onloadend = (file) => {
                this.form.image = reader.result;
            }
            reader.readAsDataURL(file);
        },
        getResults(page = 1) {
            this.$Progress.start();
            axios.get('api/post?page=' + page).then(({data}) => (this.posts = data.data));
            this.$Progress.finish();
        },
        loadPosts() {
            axios.get("api/post").then(({data}) => (this.posts = data.data));
        },
        editModal(post) {
            this.editmode = true;
            this.form.reset();
            $('#addNew').modal('show');
            this.form.fill(post);
        },
        newModal() {
            this.editmode = false;
            this.form.reset();
            $('#addNew').modal('show');
        },
        createPost() {
            this.$Progress.start();
            this.form.post('api/post')
                .then((data) => {
                    if (data.data.success) {
                        $('#addNew').modal('hide');
                        Toast.fire({
                            icon: 'success',
                            title: data.data.message
                        });
                        this.$Progress.finish();
                        this.loadPosts();
                    } else {
                        Toast.fire({
                            icon: 'error',
                            title: 'Ocorreu um erro! Por favor, tente novamente.'
                        });
                        this.$Progress.failed();
                    }
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
        updatePost() {
            this.$Progress.start();
            this.form.put('api/post/' + this.form.id)
                .then((response) => {
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();
                    this.loadPosts();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
        },
        deletePost(id) {
            Swal.fire({
                title: 'Tem certeza?',
                text: "Você não poderá restaurar depois!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Sim, excluir!'
            }).then((result) => {
                if (result.value) {
                    this.form.delete('api/post/' + id).then(() => {
                        $('#details').modal('hide');
                        Toast.fire(
                            'Excluído!',
                            'O post foi excluído.',
                            'success'
                        );
                        this.loadPosts();
                    }).catch((data) => {
                        Swal.fire("Erro!", data.message, "warning");
                    });
                }
            })
        },
        mudaStatus(id) {
            this.$Progress.start();
            this.form.put(`api/postStatus/${id}`)
                .then((data) => {
                    if (data.data.success) {
                        Toast.fire({
                            icon: 'success',
                            title: data.data.message
                        });
                        this.$Progress.finish();
                        this.loadPosts();
                    } else {
                        Toast.fire({
                            icon: 'error',
                            title: 'Ocorreu um erro! Por favor, tente novamente.'
                        });
                        this.$Progress.failed();
                    }
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocorreu um erro! Por favor, tente novamente.'
                    });
                })
        },
    },
    mounted() {
    },
    created() {
        this.$Progress.start();
        this.loadPosts();
        this.$Progress.finish();
    },
    filters: {
    },
    computed: {
    },
}
</script>
