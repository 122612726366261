<template>
    <div>
        <form @submit.prevent="updateAcademia()">

            <div class="modal-body">

                <div class="row">
                    <div class="col">
                        <div class="form-group">

                            <label>Name</label>
                            <input v-model="form.nome" type="text" name="nome"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('nome') }">
                            <has-error :form="form" field="nome"></has-error>

                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">

                            <label>CPF ou RG</label>
                            <input v-model="form.cnpj" type="text" name="cnpj" v-mask="'###.###.###-##'"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('cnpj') }">
                            <has-error :form="form" field="cnpj"></has-error>

                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">

                            <label>Responsavel</label>
                            <input v-model="form.responsavel" type="text" name="responsavel"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('responsavel') }">
                            <has-error :form="form" field="responsavel"></has-error>

                        </div>
                    </div>
                </div>

                <div class="form-group">

                    <label> Descrição </label>
                    <input v-model="form.descricao" type="text" name="descricao"
                        class="form-control" :class="{ 'is-invalid': form.errors.has('descricao') }">
                    <has-error :form="form" field="descricao"></has-error>

                </div>
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="basic-tab" data-toggle="tab" href="#basic" role="tab" aria-controls="basic" aria-selected="true">
                            Basico
                        </a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="address-tab" data-toggle="tab" href="#address" role="tab" aria-controls="address" aria-selected="false">
                            Endereço
                        </a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane fade  show active" id="basic" role="tabpanel" aria-labelledby="basic-tab">

                        <div class="form-group">

                                <label> Idioma </label>
                                <select v-model="form.language" name="language" id="language"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('language') }">
                                    <option value="pt">Portugues</option>
                                    <option value="en">Ingles</option>
                                </select>
                                <has-error :form="form" field="language"></has-error>

                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="form-group">

                                    <label> Email </label>
                                    <input v-model="form.email" type="email" name="email"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                                    <has-error :form="form" field="email"></has-error>

                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">

                                    <label> Senha </label>
                                    <input v-model="form.senha" type="password" name="senha"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('senha') }">
                                    <has-error :form="form" field="senha"></has-error>

                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <div class="form-group">

                                    <label> Website </label>
                                    <input v-model="form.site" type="text" name="site"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('site') }">
                                    <has-error :form="form" field="site"></has-error>

                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">

                                    <label> Telefone </label>
                                    <phone-mask-input
                                    v-model="form.telefone"
                                    name="telefone"
                                    autoDetectCountry
                                    wrapperClass="wrraper-example"
                                    inputClass="form-control"
                                    :class="{ 'is-invalid': form.errors.has('telefone') }"
                                    flagClass="flag-example"
                                />
                                <has-error :form="form" field="telefone"></has-error>

                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <div class="form-group">

                                    <label>Matriz</label>
                                    <select v-model="form.matriz" name="matriz" id="matriz"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('matriz') }">
                                        <option value="Y">Sim</option>
                                        <option value="N">Não</option>
                                    </select>
                                    <has-error :form="form" field="matriz"></has-error>

                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">

                                    <label>Status</label>
                                    <select v-model="form.status" name="status" id="status"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('status') }">
                                        <option value="Y">Ativo</option>
                                        <option value="N">Inativo</option>
                                    </select>
                                    <has-error :form="form" field="status"></has-error>

                                </div>
                            </div>
                            <div class="col">

                                <label> Image </label>
                                <input type="file" name="image"
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('image') }"
                                    @change="updateAcademia">
                                <has-error :form="form" field="image"></has-error>

                            </div>
                        </div>

                    </div>
                    <div class="tab-pane fade" id="address" role="tabpanel" aria-labelledby="address-tab">

                        <Endereco
                            :selectValue="form.endereco"
                            v-on:selected="form.endereco = $event;"
                        ></Endereco>

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button  type="submit" class="btn btn-primary">Salvar</button>
            </div>

        </form>
    </div>
</template>

<script>

import PhoneMaskInput from "vue-phone-mask-input";
import {mask} from 'vue-the-mask'
import Endereco from "../../common/form/Endereco.vue";
import Academia from "../../../domain/user/Academia";

export default {
    components:{
        Endereco
    },
    directives: {mask},
    name: "Dados",
    props:{
        dados: {
            type: Object
        }
    },
    data(){
        return{
            form: new Form(
                new Academia()
            )
        }
    },
    methods:{
        // loadAcademia(){
        //     this.$Progress.start;
        //     axios.get(`/api/academia/${this.$route.params.id}`).then((data) =>{
        //         this.form.fill(data.data.data);
        //     });
        //     this.$Progress.finish;
        // },
        updateAcademia(){
            this.$Progress.start();
            this.form.put('/api/academia/'+this.form.id_academia)
            .then((response) => {
                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                });
                this.$Progress.finish();
            })
            .catch(() => {
                this.$Progress.fail();
            });

        },
    },
    mounted(){

    },
    created(){
        // this.loadAcademia();
    },
    watch:{
        dados: function (dados){
            this.form.fill(dados);
        }
    }

}
</script>

<style scoped>

</style>
